import React from "react";
import Header from "./Header";
import { GridContainer } from "../../../Global";
import {
  DesktopOnly,
  LogoutIcon,
  MobileOnly,
  NavBarContainer,
} from "../User.elements";
import { logOut } from "../../../reduxSlices/authSlice";
import { useLazyLogoutQuery } from "../../../api/endpoints/authEndpoints";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomBtn from "../../../components/ui/CustomBtn";
import { BiLogOut } from "react-icons/bi";
function Navbar({ handleClickMenu }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [signout, signOutResults] = useLazyLogoutQuery();
  const handleLogOut = async () => {
    const res = await signout();

    if (res.isSuccess) {
      dispatch(logOut());
      navigate("/");
    }
  };
  return (
    <NavBarContainer>
      <Header handleClickMenu={handleClickMenu}></Header>
      <DesktopOnly>
        <CustomBtn onClick={handleLogOut} loading={signOutResults.isLoading}>
          Logout
          <LogoutIcon />
        </CustomBtn>
      </DesktopOnly>
      <MobileOnly>
        <BiLogOut
          onClick={handleLogOut}
          style={{ width: "24px", height: "24px", margin: "0 1rem" }}
        />
      </MobileOnly>
    </NavBarContainer>
  );
}

export default Navbar;
