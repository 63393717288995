import React from "react";
import ReactModal from "react-modal";
import {
  AnchorText,
  CloseIcon,
  ErrorText,
  GridContainer,
  Heading,
} from "../../../Global";
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import CustomBtn from "../../../components/ui/CustomBtn";

const customStyle = {
  content: {
    maxWidth: "600px",
    borderRadius: "8px",
    margin: "auto",
    padding: "1.5rem",
    height: "fit-content",
  },
  overlay: {
    background: "rgba(0,0,0,0.5)",
  },
};
//multi select
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function CampaignForm({
  formData,
  dispatch,
  ACTIONS,
  closeModal,
  submitFunction,
  modalState,
  creators,
  submitButtonTitle,
  selectedCreator,
  setSelectedCreator,
  error,
  submitFunctionLoading,
}) {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCreator(value);

    dispatch({
      type: ACTIONS.creatorsList,
      payload: value.map((c) => c.userId),
    });
  };
  function handleResetForm() {
    setSelectedCreator([]);
    dispatch({ type: ACTIONS.reset });
  }

  return (
    <ReactModal
      style={customStyle}
      isOpen={modalState}
      onRequestClose={closeModal}
      closeTimeoutMS={200}
    >
      <GridContainer columns="1fr auto" margin="0 0 2rem 0">
        <Heading talign="flex-start">Create Campaign</Heading>
        <CloseIcon onClick={closeModal} />
      </GridContainer>
      <GridContainer columns="1fr">
        <TextField
          label="Title"
          value={formData.title}
          onChange={(e) =>
            dispatch({ type: ACTIONS.title, payload: e.target.value })
          }
          fullWidth
        ></TextField>
        <TextField
          label="Description"
          value={formData.description}
          onChange={(e) =>
            dispatch({ type: ACTIONS.description, payload: e.target.value })
          }
          fullWidth
        ></TextField>
        <GridContainer columns="repeat(auto-fill,minmax(240px,1fr))">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From"
              slotProps={{
                textField: {
                  error: false,
                },
              }}
              disablePast
              format="DD/MM/YYYY"
              value={formData.startDate}
              onChange={(date) =>
                dispatch({ type: ACTIONS.startDate, payload: date })
              }
            />
            <DatePicker
              slotProps={{
                textField: {
                  error: false,
                },
              }}
              disablePast
              format="DD/MM/YYYY"
              label="To"
              value={formData.endDate}
              date
              onChange={(date) =>
                dispatch({ type: ACTIONS.endDate, payload: date })
              }
            />
          </LocalizationProvider>
        </GridContainer>
        <FormControl fullWidth>
          <InputLabel id="demo-multiple-checkbox-label">
            Select Creators
          </InputLabel>
          <Select
            labelId="demo-multiple-checkbox-label"
            id="demo-multiple-checkbox"
            multiple
            value={selectedCreator ?? []}
            onChange={handleChange}
            input={<OutlinedInput label="Select Creators" />}
            renderValue={(selected) =>
              selected
                .map((user) => user.firstName + " " + user.lastName)
                .join(", ")
            }
            MenuProps={MenuProps}
          >
            {creators?.map((creator) => (
              <MenuItem key={creator.id} value={creator}>
                <Checkbox
                  checked={
                    selectedCreator
                      ?.map((sc) => sc.firstName)
                      .indexOf(creator.firstName) > -1
                  }
                />
                <ListItemText
                  primary={creator.firstName + " " + creator.lastName}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </GridContainer>
      {error.field === "CampaignModal" && (
        <ErrorText>{error.message}</ErrorText>
      )}

      <GridContainer columns="auto auto" justify="flex-end" margin="1rem 0">
        <AnchorText onClick={handleResetForm}>Reset</AnchorText>
        <CustomBtn loading={submitFunctionLoading} onClick={submitFunction}>
          {submitButtonTitle}
        </CustomBtn>
      </GridContainer>
    </ReactModal>
  );
}

export default CampaignForm;
