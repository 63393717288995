import React from "react";
import { GridContainer, Heading, LightText } from "../../../Global";
import { BigHeading, ContentTable, PrivacyContainer } from "../Static.elements";

function PrivacyPolicy() {
  return (
    <GridContainer style={{ width: "100vw" }} columns="1fr" gap="0">
      <BigHeading>
        <h1>Privacy Policy</h1>
        <p>
          This Privacy Policy explains how we collect, use, and safeguard your
          personal information when you use our services.
        </p>
      </BigHeading>
      <PrivacyContainer columns="3fr 2fr" align="flex-start ">
        <GridContainer justify="flex-start" rgap="0">
          <LightText>Effective August 26,2023</LightText>

          <h1 style={{ fontWeight: "400" }}>Your Privacy Matters</h1>
          <LightText maxWidth="500px">
            Pacesape's mission is to enable all the content creators to be more
            resourceful to anyone who wants to promote thier Brand,Product or
            Company. Central to this mission is our commitment to be transparent
            about the data we collect about you, how it is used and with whorn
            it is shared.
          </LightText>
        </GridContainer>

        <GridContainer justify="flex-start">
          <ContentTable>
            <h3>Table Of Contents</h3>
            <ul>
              <li>
                <a href="#datawecollect"> Data We Collect</a>
              </li>
              <li>
                <a href="#usingdata">How We Use Your Data</a>
              </li>
              <li>
                <a href="#datasharing">Data Sharing</a>
              </li>
              <li>
                <a href="#youchoices">Your Choices</a>
              </li>
              <li>
                <a href="#datasecurity">Data Security</a>
              </li>
              <li>
                <a href="#childrenprivacy">Children's Privacy</a>
              </li>
              <li>
                <a href="#changes">Changes to this Privacy Policy</a>
              </li>
              <li>
                <a href="#contactus">Contact Us</a>
              </li>
            </ul>
          </ContentTable>
        </GridContainer>
      </PrivacyContainer>
      <hr style={{ width: "80%" }}></hr>
      <GridContainer style={{ width: "80%", margin: "auto" }} columns="1fr">
        <Heading talign="flex-start">Introduction</Heading>
        <LightText maxWidth="500px">
          Your privacy is important to us. This Privacy Policy explains how we
          collect, use, and safeguard your personal information when you use our
          services. By using our services, you agree to the terms outlined in
          this Privacy Policy.
        </LightText>

        <br></br>
        <Heading talign="flex-start" id="datawecollect">
          Data We Collect
        </Heading>
        <LightText>
          We may collect the following types of information:{" "}
          <ul>
            <li>
              <b>Personal Information:</b>Such as your name, email address, and
              contact information, which you provide voluntarily.
            </li>
            <li>
              <b> Usage Data:</b>
              Information about how you interact with our services, including
              your interactions, preferences, and activity on our platform.
            </li>
            <li>
              <b>Device Information: </b>
              Details about the devices you use to access our services, such as
              device type, operating system, and unique device identifiers.
            </li>
            <li>
              <b>Cookies and Similar Technologies:</b>
              We use cookies and similar technologies to collect data about your
              interactions with our platform and to enhance your experience.
            </li>
          </ul>
        </LightText>

        <Heading talign="flex-start" id="usingdata">
          How We Use Your Data
        </Heading>
        <LightText>
          We use your data for various purposes:
          <ul>
            <li>
              To provide and improve our services, personalize your experience,
              and offer tailored content.
            </li>
            <li>
              To communicate with you, including sending you important updates
              and notifications.
            </li>
            <li>
              To analyze usage patterns and improve the performance of our
              platform.
            </li>
            <li>
              To comply with legal obligations and protect our rights and
              interests.
            </li>
          </ul>
        </LightText>

        <Heading talign="flex-start" id="datasharing">
          {" "}
          Data Sharing
        </Heading>
        <LightText>
          We may share your data with:
          <ul>
            <li>
              <b>Third-Party Service Providers:</b> We work with trusted third
              parties to provide services like analytics and customer support.
            </li>
            <li>
              <b>Business Partners:</b> We may share data with business partners
              for joint promotions or other collaborative activities.
            </li>
            <li>
              <b>Legal and Regulatory Authorities:</b> We may share data to
              comply with legal obligations or respond to lawful requests.
            </li>
          </ul>
        </LightText>
        <Heading talign="flex-start" id="youchoices">
          Your Choices
        </Heading>
        <LightText>
          <ul>
            <li>
              Access and Control: You have the right to access, correct, or
              delete your personal information. You can do so by [describe the
              process].
            </li>
            <li>
              Cookies: You can manage cookies through your browser settings or
              device preferences.
            </li>
            <li>
              Communication Preferences: You can opt out of receiving
              non-essential communications from us.
            </li>
          </ul>
        </LightText>
        <Heading talign="flex-start" id="datasecurity">
          Data Security
        </Heading>
        <LightText>
          We take data security seriously and implement measures to protect your
          information from unauthorized access or disclosure
        </LightText>

        <Heading talign="flex-start" id="childrenprivacy">
          Children's Privacy
        </Heading>
        <LightText>
          Our services are not intended for users under the age of 18. We do not
          knowingly collect personal information from children under 18.
        </LightText>
        <Heading talign="flex-start" id="changes">
          Changes to this Privacy Policy
        </Heading>
        <LightText>
          We may update this Privacy Policy from time to time. Any changes will
          be posted on this page, and the "Last Updated" date will reflect the
          most recent revision.
        </LightText>
        <Heading talign="flex-start" id="contactus">
          Contact Us
        </Heading>
        <LightText>
          If you have any questions, concerns, or requests regarding your
          privacy, please contact us at [contact email or form link]. Thank you
          for trusting Pacescape.
          <b>
            If you want to delete your user data, write to&nbsp;
            <a href="mailto:vinayak.m@pacewisdom.com">
              vinayak.m@pacewisdom.com
            </a>
          </b>
        </LightText>
      </GridContainer>
    </GridContainer>
  );
}

export default PrivacyPolicy;
