import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CCategoryContainer } from "../Explore.elements";
import { GridContainer, Heading, LightText } from "../../../Global";
import {
  useAddFavCreatorMutation,
  useGetCreatorsByCategoryQuery,
  useGetFavoriteCreatorsQuery,
} from "../../../api/endpoints/exploreEndpoints";
import Shimmering from "../../../components/ui/ShimmerCard";
import NoData from "../../../components/ui/NoData";
import { useSelector } from "react-redux";
import CreatorCard from "../helpers/CreatorCard";
function CreatorCategoryDetails() {
  const { category } = useParams();
  const navigate = useNavigate();
  const newCat = category.replace(/-/g, " ");
  const role = useSelector((state) => state.auth.roles[0]).name;
  const userData = useSelector((state) => state.auth?.userData);

  const [favorites, setFavorites] = useState([]);

  const { data: creatorsByCategory, isLoading: isCreatorsLoading } =
    useGetCreatorsByCategoryQuery(newCat.toLowerCase(), false);

  const { data: favCreators, isSuccess: isFavCreatorSuccess } =
    useGetFavoriteCreatorsQuery(userData.userId);

  const backToExplore = () => {
    navigate(`/${role}/explore/creators`);
  };

  useEffect(() => {
    if (isFavCreatorSuccess) {
      setFavorites(favCreators?.data);
    }
  }, [isFavCreatorSuccess]);
  console.log(favorites);
  if (isCreatorsLoading) {
    return <Shimmering />;
  } else {
    return (
      <CCategoryContainer>
        <Heading>{category.replace(/-/g, " ")}</Heading>
        <GridContainer columns="1fr">
          <LightText>{`${
            creatorsByCategory ? creatorsByCategory.data.userDTO.length : "0"
          } creators available `}</LightText>
        </GridContainer>

        {creatorsByCategory?.data.userDTO.length === 0 ? (
          <NoData
            message={`There are No Content Creators under ${category.replace(
              /-/g,
              " "
            )}`}
            btnFunction={backToExplore}
            btnTitle="Back to Explore"
          />
        ) : (
          <GridContainer
            align="stretch"
            columns="repeat(auto-fill,minmax(300px,1fr))"
          >
            {creatorsByCategory?.data.userDTO.map((obj) => (
              <CreatorCard
                favorites={favorites}
                setFavorites={setFavorites}
                {...obj}
              />
            ))}
          </GridContainer>
        )}
      </CCategoryContainer>
    );
  }
}

export default CreatorCategoryDetails;
