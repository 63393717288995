import React from "react";
import { GridContainer } from "../../Global";
import { Label, Select } from "./components.elements";

const parseRoles = (role) => {
  switch (role) {
    case "brandowner":
      return "Brand Owner";
    case "creator":
      return "Creator";
    default:
      return role;
  }
};
function SelectRolesField(props) {
  return (
    <GridContainer justify="flex-start" rgap="4px">
      <Label>{props.title} </Label>
      <Select {...props}>
        {props.options?.map((opt) => (
          <option key={opt} value={opt}>
            {parseRoles(opt)}
          </option>
        ))}
      </Select>
    </GridContainer>
  );
}

export default SelectRolesField;
