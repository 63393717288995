import React, { useEffect, useState } from "react";
import {
  GridContainer,
  LinkButton,
  ScrollContainer,
  SmallLightText,
} from "../../../Global";
import { ActivityCard, ActivityContainer } from "../User.elements";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/constants";

function Activities({ data }) {
  const [imageURL, setImageURL] = useState([{ email: "", image: "" }]);
  const accessToken = useSelector((state) => state.auth.accessToken);

  useEffect(() => {
    async function getProfilePic(email) {
      try {
        const res = await fetch(
          `${BASE_URL}/user/private/profile/pic?email=${email}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImageURL((curState) => [
          ...curState,
          { email: email, image: imageObjectURL },
        ]);
      } catch (e) {
        console.log(e);
      }
    }
    if (data) {
      data.map((obj) => getProfilePic(obj.email));
    }
  }, [data]);
  return (
    <ActivityContainer>
      {data && (
        <ScrollContainer maxHeight="calc(100vh - 120px)">
          {data.map(({ name, desc, email, key }) => (
            <ActivityCard key={key}>
              <img
                loading="lazy"
                height="36px"
                src={imageURL?.find((obj) => obj.email === email)?.image}
                alt="Profile"
              />
              <GridContainer rgap="2px" justify="flex-start">
                <b>{name}</b>
                <SmallLightText style={{ lineHeight: "11px" }}>
                  {desc?.length > 25 ? desc?.substr(0, 55) + "..." : desc}
                </SmallLightText>
              </GridContainer>
              <a href={`mailto:${email}`}>
                <LinkButton>Contact</LinkButton>
              </a>
            </ActivityCard>
          ))}
        </ScrollContainer>
      )}
    </ActivityContainer>
  );
}

export default Activities;
