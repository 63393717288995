import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

export default function ExploreCard({ title, desc, image, path }) {
  const navigate = useNavigate();
  const role = useSelector((state) => state.auth.roles[0]).name;

  const handleNavigate = () => {
    navigate(`/${role}/explore/${path}`);
  };

  return (
    <Card onClick={handleNavigate}>
      <CardActionArea>
        <CardMedia
          component="img"
          style={{ objectFit: "contain" }}
          height="140"
          image={image}
          alt="profile"
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {desc}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
