import React, { useState } from "react";
import { CategoriesTopBar } from "../Explore.elements";
import { useNavigate, useParams } from "react-router-dom";

function CategoriesMenu({ categories }) {
  //possible states: left,right,both
  const [catShadow, setCatShadow] = useState("right");
  const navigate = useNavigate();
  const { category } = useParams();

  function handleCategoriesScroll(e) {
    const { scrollWidth, scrollLeft, clientWidth } = e.target;
    if (scrollLeft != 0 && scrollLeft != scrollWidth - clientWidth) {
      setCatShadow("both");
    } else if (scrollLeft === 0) {
      setCatShadow("right");
    } else if (scrollLeft != 0) {
      setCatShadow("left");
    }
  }

  return (
    <CategoriesTopBar shadow={catShadow} onScroll={handleCategoriesScroll}>
      {categories.map((cat) => (
        <li
          style={
            category === cat.path ? { fontWeight: 600, color: "#222" } : {}
          }
          onClick={() => navigate(cat.path)}
        >
          {cat.title}
        </li>
      ))}
    </CategoriesTopBar>
  );
}

export default CategoriesMenu;
