import { Avatar, Menu, MenuItem } from "@mui/material";
import { CampaignCardContainer, EllipsisIcon } from "../User.elements";
import UserStack from "../helpers/UserStack";
import CampaignForm from "./CampaignForm";
import { useEffect, useReducer, useState } from "react";
import {
  useDeleteCampaignMutation,
  useUpdateCampaignMutation,
} from "../../../api/endpoints/campaignEndpoint";
import {
  AnchorText,
  AvatarImage,
  CloseIcon,
  GridContainer,
  Heading,
  Heading2,
  LightText,
  LinkButton,
  SmallLightText,
} from "../../../Global";

import dayjs from "dayjs";

import { format, parseISO } from "date-fns";

import { toast } from "react-toastify";
import ReactModal from "react-modal";
import { campaignModalStyle } from "../../../utils/ModalStyles";

import LineHeading from "../../../components/ui/LineHeading";
import { BASE_URL } from "../../../utils/constants";
import { useSelector } from "react-redux";

import deleteVector from "../../../assets/images/delete-vector.svg";
import CustomBtn from "../../../components/ui/CustomBtn";
import { useNavigate } from "react-router-dom";

function CampaignCard({ creators, campaign }) {
  const accessToken = useSelector((state) => state.auth.accessToken);
  const role = useSelector((state) => state.auth.roles[0].name);
  const navigate = useNavigate();
  const [imageURL, setImageURL] = useState([]);

  const [deleteCampaign, deleteCampaignResults] = useDeleteCampaignMutation();
  const [updateCampaign, updateCampaignResults] = useUpdateCampaignMutation();

  const [error, setError] = useState({ field: "", message: "" });
  const [selectedCreator, setSelectedCreator] = useState([]);
  const [cDetailsModal, setCDetailsModal] = useState(false);
  const [deleteCamapignModal, setDeleteCampaignModal] = useState(false);

  //toolbar
  const [anchorEl, setAnchorEl] = useState(null);
  const [modalState, setModalState] = useState(false);

  function callSuccess(field, message) {
    toast.success(message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  function callError(field, message) {
    setError({
      field: field,
      message: message,
    });
    toast.error(message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function delCampaign() {
    try {
      const res = await deleteCampaign(campaign.campaignId);
      if (res.data) {
        setDeleteCampaignModal(false);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function handleCampaignDelete() {
    handleClose();
    setDeleteCampaignModal(true);
  }
  async function handleCampaignUpdate() {
    try {
      const res = await updateCampaign(formData);
      if (res.data) {
        callSuccess("Global", "Campaign Updated Successfully");
        setModalState(false);
        handleClose();
      }
      if (res.error) {
        callError("Global", "Something went wrong");
      }
    } catch (e) {
      console.log(e);
    }
  }

  const ACTIONS = {
    startDate: "handleStartDate",
    endDate: "handleEndDate",
    title: "handleTitle",
    description: "handleDescription",
    creatorsList: "handleCreatorsList",
    creators: "handlecreators",
    active: "handleActive",
    reset: "handleReset",
    initialData: "handleInitialData",
  };

  function reducer(state, action) {
    switch (action.type) {
      case ACTIONS.startDate:
        return { ...state, startDate: action.payload };
      case ACTIONS.endDate:
        return { ...state, endDate: action.payload };
      case ACTIONS.title:
        return { ...state, title: action.payload };
      case ACTIONS.description:
        return { ...state, description: action.payload };
      case ACTIONS.creators:
        return { ...state, creators: action.payload };
      case ACTIONS.creatorsList:
        return { ...state, creatorsList: action.payload };
      case ACTIONS.active:
        return { ...state, active: action.payload };
      case ACTIONS.reset:
        return { ...initialState };
      case ACTIONS.initialData:
        return {
          startDate: dayjs(action.payload.startDate),
          endDate: dayjs(action.payload.endDate),
          title: action.payload.title,
          description: action.payload.description,
          creatorsList: action.payload.creatorsList,
          campaignId: action.payload.campaignId,
          active: true,
        };
      default:
        return;
    }
  }

  const initialState = {
    startDate: "",
    endDate: "",
    title: "",
    description: "",
    campaignId: "",
    creatorsList: [],
    active: true,
  };

  const [formData, dispatch] = useReducer(reducer, initialState);

  function openCampaignUpdateModal() {
    handleClose();
    setModalState(true);

    dispatch({
      type: ACTIONS.initialData,
      payload: {
        campaignId: campaign.campaignId,
        startDate: campaign.startDate,
        endDate: campaign.endDate,
        title: campaign.title,
        description: campaign.description,
        creatorsList: campaign.creatorsList,
        active: campaign.active,
      },
    });

    //fill the seelcted creator drop down
    const sc = campaign.creators
      .map((c) => c.userId)
      ?.map((id) => creators.find((creator) => creator.userId === id));

    dispatch({
      type: ACTIONS.creatorsList,
      payload: sc.map((obj) => obj.userId),
    });
    setSelectedCreator(sc);
  }

  useEffect(() => {
    setError({ field: "", message: "" });
  }, [formData]);

  function openDetailsModal() {
    handleClose();
    setCDetailsModal(true);
  }

  function closeDetailsModal() {
    setCDetailsModal(false);
  }
  async function getProfilePic(email) {
    try {
      const res = await fetch(
        `${BASE_URL}/user/private/profile/pic?email=${email}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (res.ok) {
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImageURL((curState) => [
          ...curState,
          { email: email, image: imageObjectURL },
        ]);
      } else {
        setImageURL((curState) => [...curState, { email: email, image: "" }]);
      }
    } catch (e) {
      console.log(e);
    }
  }

  function handleActivateCampaign() {
    openCampaignUpdateModal();
    dispatch({ type: ACTIONS.active, payload: true });
  }

  useEffect(() => {
    if (campaign) {
      campaign.creators.map((obj) => getProfilePic(obj.email));
    }
  }, [campaign]);

  useEffect(() => {
    if (formData.endDate.$d <= new Date()) {
      callError("StartDate", "Please select future date");
    } else if (formData.startDate.$d <= new Date()) {
      callError("EndDate", "Please select future date");
    } else {
      setError({ field: "", message: "" });
    }
  }, [formData.startDate, formData.endDate]);

  console.log(campaign);
  return (
    <>
      {/* Camapigns Details Modal */}
      <ReactModal
        isOpen={cDetailsModal}
        onRequestClose={() => setCDetailsModal(false)}
        style={campaignModalStyle}
      >
        <GridContainer columns="1fr auto">
          <Heading talign="flex-start">{campaign?.title}</Heading>
          <CloseIcon onClick={closeDetailsModal} />
        </GridContainer>

        <GridContainer columns="1fr" margin="0.7rem 0 0 0">
          <LightText style={{ maxWidth: "300px" }}>
            {campaign?.description}
          </LightText>
        </GridContainer>

        <GridContainer columns="1fr 1fr">
          <div>
            <SmallLightText>From</SmallLightText>
            <LightText>
              {format(new Date(campaign?.startDate), "dd/MM/yyyy")}{" "}
            </LightText>
          </div>

          <div>
            <SmallLightText>To</SmallLightText>
            <LightText>
              {format(new Date(campaign?.endDate), "dd/MM/yyyy")}{" "}
            </LightText>
          </div>
        </GridContainer>

        <LineHeading title="Campaign Contributors" />
        <GridContainer margin="1rem 0" columns="1fr">
          {campaign?.creators.map((obj) => (
            <GridContainer key={obj.userId} columns="auto 1fr auto" gap="2rem">
              {imageURL?.find((imgObj) => imgObj.email === obj.email)?.image ? (
                <AvatarImage
                  loading="lazy"
                  height="56px"
                  width="56px"
                  src={
                    imageURL?.find((imgObj) => imgObj.email === obj.email)
                      ?.image
                  }
                  alt="Profile"
                />
              ) : (
                <Avatar
                  style={{
                    width: "48px",
                    height: "48px",
                    boxShadow: "0 0 10px 5px #00000022",
                  }}
                />
              )}

              <div>
                <Heading2 talign="flex-start">
                  {obj.firstName + " " + obj.lastName}
                </Heading2>
                <SmallLightText>{obj.email}</SmallLightText>
                <SmallLightText>{obj.phoneNumber}</SmallLightText>
              </div>

              <LinkButton
                onClick={() => navigate(`/${role}/chat/${obj.userId}`)}
              >
                Contact{" "}
              </LinkButton>
            </GridContainer>
          ))}
        </GridContainer>
      </ReactModal>
      {/* Delete Acknowledgement Message */}
      <ReactModal
        isOpen={deleteCamapignModal}
        onRequestClose={() => setDeleteCampaignModal(false)}
        style={campaignModalStyle}
      >
        <GridContainer>
          <img
            src={deleteVector}
            width="200px"
            alt="Delete"
            style={{ margin: "auto" }}
          ></img>
          <LightText>
            Are you certain about deactivating the campaign?
          </LightText>
          <GridContainer columns="1fr auto">
            <AnchorText onClick={() => setDeleteCampaignModal(false)}>
              Cancel
            </AnchorText>
            <CustomBtn
              loading={deleteCampaignResults.isLoading}
              onClick={delCampaign}
            >
              Yes, Deactivate
            </CustomBtn>
          </GridContainer>
        </GridContainer>
      </ReactModal>
      <CampaignCardContainer active={campaign?.active}>
        <CampaignForm
          formData={formData}
          creatorsList
          dispatch={dispatch}
          ACTIONS={ACTIONS}
          closeModal={() => setModalState(false)}
          submitFunctionLoading={updateCampaignResults.isLoading}
          submitFunction={handleCampaignUpdate}
          submitButtonTitle="Update Campaign"
          modalState={modalState}
          creators={creators}
          selectedCreator={selectedCreator}
          setSelectedCreator={setSelectedCreator}
          error={error}
        ></CampaignForm>
        <GridContainer
          onClick={openDetailsModal}
          justify="flex-start"
          rgap="4px"
        >
          <b>{campaign?.title}</b>
          <SmallLightText>
            {campaign?.description.length > 50
              ? campaign?.description.substring(0, 50) + "..."
              : campaign?.description}
          </SmallLightText>
          <small>
            {format(parseISO(campaign?.startDate), "dd/MM/yyyy")}-
            {format(parseISO(campaign?.endDate), "dd/MM/yyyy")}
          </small>
        </GridContainer>
        <div onClick={openDetailsModal}>
          <UserStack images={imageURL}></UserStack>
        </div>
        <span>
          <EllipsisIcon
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          />
        </span>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={openDetailsModal}>View Details</MenuItem>
          <MenuItem onClick={openCampaignUpdateModal}>Update Campaign</MenuItem>
          {campaign?.active ? (
            <MenuItem onClick={handleCampaignDelete}>Make it Inactive</MenuItem>
          ) : (
            <MenuItem onClick={handleActivateCampaign}>
              Make it Active{" "}
            </MenuItem>
          )}
        </Menu>
      </CampaignCardContainer>
    </>
  );
}

export default CampaignCard;
