import React, { useLayoutEffect } from "react";
import {
  AnalyticsCard,
  BrandAnalyticsContainer,
  ToolTipContainer,
} from "./Brand.elements";
import {
  BigHeading,
  GridContainer,
  Heading2,
  LightText,
  LinkButton,
} from "../../Global";
import Activities from "../Influencer/helpers/Activities";
import { useLazyGetTopCreatorsQuery } from "../../api/endpoints/brandOwnerEndpoints";
import ShimmerCard from "../../components/ui/ShimmerCard";
import PieGraph from "./helpers/PieChart";
import { useSelector } from "react-redux";
import {
  useGetCampaignByUserIdQuery,
  useLazyGetCreatorsQuery,
} from "../../api/endpoints/campaignEndpoint";
import {
  DesktopOnly,
  Table,
  YoutubeCardContainer,
} from "../Influencer/User.elements";
import CampaignTimeline from "./helpers/CampaignTimeline";
import { parseISO } from "date-fns";

function BrandAnalytics() {
  const myUserId = useSelector((state) => state.auth.userData.userId);
  const [getTopCreators, creatorData] = useLazyGetTopCreatorsQuery();
  const [getCreators, creatorsResult] = useLazyGetCreatorsQuery();
  const { data: campaigns, isLoading: isCampaignsLoading } =
    useGetCampaignByUserIdQuery(myUserId);

  let cdata = [];
  let bdata = [];

  //number of active and inactive campaigns
  if (campaigns && creatorsResult.data) {
    const inactiveCampaigns = campaigns.data.filter(
      (obj) => obj.active === false
    );
    const activeCampaigns = campaigns.data.filter((obj) => obj.active === true);
    cdata = [
      {
        name: "Active Campaign",
        value: activeCampaigns.length,
        color: "#0088FE",
      },
      {
        name: "Inactive Campaigns",
        value: inactiveCampaigns.length,
        color: "#00C49F",
      },
    ];
    // Get days of campaign
    //get num of creators

    bdata = campaigns.data.map((obj) => {
      const millisecondsPerDay = 24 * 60 * 60 * 1000;
      let numDays =
        (parseISO(obj.endDate) - parseISO(obj.startDate)) / millisecondsPerDay;

      let numCreators = obj.creators.length;
      return {
        name: obj.title,
        Days: numDays,
        Creators: numCreators,
      };
    });
  }
  useLayoutEffect(() => {
    getTopCreators();
  }, []);
  let activityData = [];
  let tableData = [];
  if (creatorData.data) {
    activityData = creatorData.data?.data?.map((cd, i) => ({
      key: cd.userId,
      name: [cd.user.firstName, cd.user.lastName].join(" "),
      image: cd.subscriberCount,
      desc: cd.channelDescription,
      email: cd.user.email,
    }));
    tableData = creatorData.data?.data?.map((cd) => ({
      key: cd.userId,
      name: cd.user.firstName + " " + cd.user.lastName,
      channelName: cd.channelTitle,
      Subscribers: cd.subscriberCount,
      Views: cd.views,
      videoCount: cd.videoCount,
      status: cd.user.isActive,
    }));
  }

  useLayoutEffect(() => {
    getCreators();
  }, []);

  if (creatorData.isLoading || creatorsResult.isLoading || isCampaignsLoading) {
    return <ShimmerCard />;
  } else {
    return (
      <BrandAnalyticsContainer>
        <GridContainer columns="1fr">
          <BigHeading>Analytics</BigHeading>
          <YoutubeCardContainer columns="1fr 1fr">
            <AnalyticsCard>
              <Heading2 talign="flex-star">Active/Inactive Campaigns</Heading2>
              <PieGraph data={cdata} />
              <ToolTip data={cdata} />
            </AnalyticsCard>
            <DesktopOnly>
              <AnalyticsCard>
                <Heading2 talign="flex-start">Campign Timeline </Heading2>
                <CampaignTimeline data={bdata} />
              </AnalyticsCard>
            </DesktopOnly>
          </YoutubeCardContainer>
          <DesktopOnly>
            <GridContainer columns="1fr">
              <Table>
                <thead>
                  <tr>
                    <th>Channel Name</th>
                    <th>Creator Name</th>
                    <th>Views</th>
                    <th>Subscribers</th>
                    <th> Total Videos</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData?.map((user) => (
                    <tr key={user.key}>
                      <td>{user.name}</td>
                      <td>{user.channelName}</td>
                      <td>{user.Views}</td>
                      <td>{user.Subscribers}</td>
                      <td>{user.videoCount}</td>
                      <td>
                        <LinkButton>
                          <a href={user.customURL}>Visit</a>
                        </LinkButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </GridContainer>
          </DesktopOnly>
        </GridContainer>
        <GridContainer>
          <Heading2 talign="flex-start">Recommended Creators For you</Heading2>
          <LightText style={{ maxWidth: "600px" }}>
            Our team has taken into account factors such as your brand values,
            industry, aesthetic, and messaging to curate a list of creators who
            can help amplify your message in a compelling and authentic way.
          </LightText>
          <Activities data={activityData} />
        </GridContainer>
      </BrandAnalyticsContainer>
    );
  }
}

export default BrandAnalytics;

function ToolTip({ data }) {
  return (
    <GridContainer columns="1fr">
      {data?.map((obj, i) => (
        <ToolTipContainer key={obj.name}>
          <span style={{ background: obj.color }}></span>
          <p>{obj.name}</p>
        </ToolTipContainer>
      ))}
    </GridContainer>
  );
}
