import React, { useReducer, useState } from "react";
import {
  CategorySelect,
  LoginButton,
  LoginForm,
  RedirectButton,
} from "./Auth.elements";
import {
  BigHeading,
  GridContainer,
  Heading,
  Heading2,
  LightText,
} from "../../Global";
import TextInput from "../../components/ui/TextInput";
import FileInput from "../../components/ui/FileInput";
import { useLocation, useNavigate } from "react-router-dom";
import { BASE_URL, brandsCategories } from "../../utils/constants";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ErrorText } from "../../components/ui/components.elements";
import ReactModal from "react-modal";
//Assets
import logo from "../../assets/images/logo.png";
import { FaArrowRight } from "react-icons/fa";
import CustomBtn from "../../components/ui/CustomBtn";
import CategoryListItem from "./helpers/CategoryListItem";
const customStyle = {
  content: {
    minWidth: "320px",
    maxWidth: "800px",
    padding: "2rem",
    height: "min-content",
    margin: "auto",
    borderRadius: "2rem",
  },
  overlay: {
    background: "#00000066",
  },
};

function BrandInfo() {
  const location = useLocation();
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [error, setError] = useState({ field: "", message: "" });

  function callError(field, message) {
    setError({
      field: field,
      message: message,
    });
    toast.error(message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  const ACTIONS = {
    user: "user",
    companyName: "companyName",
    description: "description",
    gstin: "gstin",
    tin: "tin",
    file: "file",
  };
  function reducer(state, action) {
    switch (action.type) {
      case ACTIONS.user:
        return { ...state, user: action.payload };
      case ACTIONS.companyName:
        return { ...state, companyName: action.payload };
      case ACTIONS.description:
        return { ...state, description: action.payload };
      case ACTIONS.gstin:
        return { ...state, gstin: action.payload };
      case ACTIONS.tin:
        return { ...state, tin: action.payload };
      case ACTIONS.file:
        return { ...state, file: action.payload };
      default:
        return;
    }
  }

  const [formData, dispatch] = useReducer(reducer, {
    user: location.state.email,
    companyName: "",
    description: "",
    gstin: "",
    tin: "",
    file: "",
  });

  const handleSubmit = async () => {
    if (formData.companyName === "") {
      callError("companyName", "Please provide the name of your Organization");
      return;
    } else if (formData.description === "") {
      callError(
        "description",
        "Please provide brief description about given Organization."
      );
      return;
    } else if (formData.gstin === "") {
      callError("gstin", "Please provide GST Identification Number");
      return;
    } else if (formData.tin === "") {
      callError("tin", "Please provide Tax Identification Number");
      return;
    } else if (formData.file === "") {
      callError("file", "Please provide your Brand's Logo");
      return;
    }
    const form_data = new FormData();

    for (let key in formData) {
      form_data.append(key, formData[key]);
    }
    try {
      const res = await fetch(`${BASE_URL}/user/public/signup/brand-owner`, {
        method: "POST",
        body: form_data,
      });

      const response = await res.json();
      if (res.status === 200) {
        setModal(true);
      } else if (
        res.status === 400 &&
        response.message === "brandowner Info already given"
      ) {
        setModal(true);
        return;
      } else if (res.status === 400) {
        callError("global", response.message);
        return;
      } else if (res.status === 500) {
        callError("global", response.data);
        setModal(true);
        return;
      }
    } catch (e) {
      console.log(e);
    }
  };

  function backToLogin() {
    navigate("/");
  }

  //logic to handle creator categories in Modal
  const [isSelectCategories, setSelecting] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const handleCategorySelection = (newCat) => {
    if (selectedCategories.includes(newCat)) {
      setSelectedCategories((curState) =>
        curState.filter((obj) => obj != newCat)
      );
    } else {
      setSelectedCategories((curState) => [...curState, newCat]);
    }
  };

  const handleCreatorCategory = async () => {
    try {
      const res = await fetch(`${BASE_URL}/user/public/brand-owner/category`, {
        method: "POST",
        body: JSON.stringify({
          categoryNames: selectedCategories,
          email: location.state.email,
        }),
      });

      if (res.status === 200) {
        setSelecting(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <LoginForm style={{ bottom: "auto" }}>
      <ReactModal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        style={customStyle}
      >
        <GridContainer>
          <img src={logo} alt="Logo" style={{ margin: "auto" }}></img>

          {isSelectCategories ? (
            <>
              <BigHeading>Choose your Category</BigHeading>
              <CategorySelect>
                {brandsCategories.map((cat) => (
                  <CategoryListItem
                    title={cat.title}
                    active={selectedCategories.includes(
                      cat.title.toLowerCase().replace(/ /, "-")
                    )}
                    handleClick={() =>
                      handleCategorySelection(
                        cat.title.toLowerCase().replace(/ /, "-")
                      )
                    }
                  >
                    {cat.title}
                  </CategoryListItem>
                ))}
              </CategorySelect>
              <CustomBtn size="large" onClick={handleCreatorCategory}>
                Save & Proceed
              </CustomBtn>
            </>
          ) : (
            <>
              <Heading2 talign="center">
                {" "}
                Verify Your email before logging in
              </Heading2>
              <LightText talign="center">
                Verification Email is sent on {formData.email}.<br></br>
              </LightText>
              <RedirectButton onClick={backToLogin}>
                I have verified <FaArrowRight />
              </RedirectButton>
            </>
          )}
        </GridContainer>
      </ReactModal>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
      <Heading talign="center">Let us know a bit more about your brand</Heading>
      <LightText talign="center">Company Details</LightText>
      <GridContainer
        justify="flex-start"
        align="flex-start"
        columns="repeat(auto-fill,minmax(180px,1fr))"
        gap="1rem"
        margin="1rem 0 0 0"
      >
        <TextInput
          name="user"
          error={error.field === "user"}
          errorMessage={error.message}
          value={formData.user}
          minwidth="180px"
          placeholder="JohnDoe@gmail.com"
          title="Your Email"
          disabled
          onChange={(e) => {
            dispatch({ type: ACTIONS.user, payload: e.target.value });
          }}
        ></TextInput>
        <TextInput
          name="companyName"
          error={error.field === "companyName"}
          errorMessage={error.message}
          value={formData.companyName}
          minwidth="150px"
          placeholder="Pacescape"
          title="Brand Name"
          onChange={(e) => {
            dispatch({ type: ACTIONS.companyName, payload: e.target.value });
          }}
        ></TextInput>
      </GridContainer>
      <GridContainer
        columns="repeat(auto-fill,minmax(280px,1fr))"
        justify="flex-start"
      >
        <TextInput
          name="description"
          error={error.field === "description"}
          errorMessage={error.message}
          value={formData.description}
          placeholder="Our enterprise focuses on..."
          title="Description"
          onChange={(e) => {
            dispatch({ type: ACTIONS.description, payload: e.target.value });
          }}
        ></TextInput>
      </GridContainer>
      <GridContainer
        columns="repeat(auto-fill,minmax(200px,1fr))"
        justify="flex-start"
      >
        <TextInput
          name="gstin"
          error={error.field === "gstin"}
          errorMessage={error.message}
          value={formData.gstin}
          minwidth="180px"
          placeholder="22AAAA0000A1Z5"
          title="GST Identification Number"
          onChange={(e) => {
            dispatch({ type: ACTIONS.gstin, payload: e.target.value });
          }}
        ></TextInput>
        <TextInput
          name="tin"
          error={error.field === "tin"}
          errorMessage={error.message}
          value={formData.tin}
          minwidth="180px"
          placeholder="AAAAA8888A"
          title="Tax Identification Number"
          onChange={(e) => {
            dispatch({ type: ACTIONS.tin, payload: e.target.value });
          }}
        ></TextInput>
      </GridContainer>
      <FileInput
        name="file"
        error={error.field === "file"}
        errorMessage={error.message}
        title="Logo"
        type="file"
        onChange={(e) => {
          dispatch({
            type: ACTIONS.file,
            payload: e.target.files[0],
          });
        }}
      />
      <GridContainer justify="flex-start">
        {error.field === "global" && <ErrorText>{error.message}</ErrorText>}
      </GridContainer>
      <LoginButton onClick={handleSubmit}>Save & Proceed</LoginButton>
    </LoginForm>
  );
}

export default BrandInfo;
