import React, { useEffect, useState } from "react";
import { Breadcrumps, ExploreContainer } from "../Explore.elements";
import Shimmering from "../../../components/ui/ShimmerCard";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { IoIosArrowForward } from "react-icons/io";
import {
  useGetAllBrandownerQuery,
  useGetAllBrandsQuery,
  useGetFavoriteBrandsQuery,
} from "../../../api/endpoints/exploreEndpoints";
import CategoriesMenu from "../helpers/CreatorsCategory";
import { brandsCategories } from "../../../utils/constants";
import { GridContainer } from "../../../Global";
import CCategoryCard from "../helpers/CCategoryCard";
import NoData from "../../../components/ui/NoData";
import BrandCard from "./BrandCard";

function ExploreBrands() {
  const location = useLocation();
  const locArray = location.pathname.split("/").slice(3);

  const role = useSelector((state) => state.auth.roles[0]).name;
  const userId = useSelector((state) => state.auth?.userData?.userId);

  const { data: brandOwners, isLoading: isBrandLoading } =
    useGetAllBrandownerQuery();
  const { category } = useParams();

  const categories = [
    {
      active: false,
      path: `/${role}/explore/brands/Luxury`,
      title: "Luxury",
      desc: "Luxury brands are known for their high-quality, exclusive products, often associated with prestige and premium pricing.",
    },
    {
      active: false,
      path: `/${role}/explore/brands/Fast-Fashion`,
      title: "Fast Fashion",
      desc: "Fast fashion brands focus on quickly producing affordable, trendy clothing and accessories.",
    },
    {
      active: false,
      path: `/${role}/explore/brands/Electronics`,
      title: "Electronics",
      desc: "Electronics brands manufacture a wide range of electronic devices, from smartphones to home appliances.",
    },
    {
      active: false,
      path: `/${role}/explore/brands/Automotive`,
      title: "Automotive",
      desc: "Automotive brands produce cars, trucks, and other vehicles, emphasizing performance, safety, and innovation.",
    },
    {
      active: false,
      path: `/${role}/explore/brands/Tech-and-Gadgets`,
      title: "Tech and Gadgets",
      desc: "Tech and gadgets brands specialize in cutting-edge technology products, including laptops, smartphones, and wearable devices.",
    },
    {
      active: false,
      path: `/${role}/explore/brands/Beauty-and-Cosmetics`,
      title: "Beauty and Cosmetics",
      desc: "Beauty and cosmetics brands offer skincare, makeup, and grooming products to enhance personal appearance.",
    },
    {
      active: false,
      path: `/${role}/explore/brands/Food-and-Beverage`,
      title: "Food and Beverage",
      desc: "Food and beverage brands produce a variety of consumables, from snacks to beverages and gourmet foods.",
    },
    {
      active: false,
      path: `/${role}/explore/brands/Sportswear`,
      title: "Sportswear",
      desc: "Sportswear brands design athletic clothing and footwear, suitable for sports and fitness activities.",
    },
    {
      active: false,
      path: `/${role}/explore/brands/Outdoor`,
      title: "Outdoor",
      desc: "Outdoor brands focus on gear and clothing for outdoor enthusiasts and adventurers.",
    },
    {
      active: false,
      path: `/${role}/explore/brands/Home-Appliance`,
      title: "Home Appliance",
      desc: "Home appliance brands create a range of products for household use, such as refrigerators, washing machines, and vacuum cleaners.",
    },
    {
      active: false,
      path: `/${role}/explore/brands/Furniture`,
      title: "Furniture",
      desc: "Furniture brands offer a wide selection of home furnishings, including sofas, tables, and chairs.",
    },
    {
      active: false,
      path: `/${role}/explore/brands/Health-and-Wellness`,
      title: "Health and Wellness",
      desc: "Health and wellness brands provide products related to fitness, nutrition, and personal well-being.",
    },
    {
      active: false,
      path: `/${role}/explore/brands/Entertainment-and-Media`,
      title: "Entertainment and Media",
      desc: "Entertainment and media brands encompass film studios, music labels, and streaming platforms.",
    },
    {
      active: false,
      path: `/${role}/explore/brands/Green-and-Sustainable`,
      title: "Green and Sustainable",
      desc: "Green and sustainable brands prioritize eco-friendly and ethical practices in their products and operations.",
    },
    {
      active: false,
      path: `/${role}/explore/brands/Children`,
      title: "Children",
      desc: "Children's brands focus on products for kids, including toys, clothing, and educational materials.",
    },
    {
      active: false,
      path: `/${role}/explore/brands/Pet-Care`,
      title: "Pet Care",
      desc: "Pet care brands offer pet food, accessories, and healthcare products for pets and pet owners.",
    },
    {
      active: false,
      path: `/${role}/explore/brands/Jewelry-and-Accessories`,
      title: "Jewelry and Accessories",
      desc: "Jewelry and accessories brands create a wide range of adornments and fashion accessories.",
    },
  ];

  const { data: allBrands, isLoading: isAllBrandsLoading } =
    useGetAllBrandsQuery();

  const {
    data: favBrands,
    isLoading: isFavBrandsLoading,
    isSuccess: isFavBrandSuccess,
  } = useGetFavoriteBrandsQuery(userId);

  console.log(favBrands);

  const [favBrandsList, setFavBrands] = useState([]);
  useEffect(() => {
    if (isFavBrandSuccess) {
      setFavBrands(favBrands.data.map((obj) => obj.id));
    }
  }, [isFavBrandSuccess]);

  console.log(favBrandsList);

  if (isAllBrandsLoading || isFavBrandsLoading) {
    return <Shimmering />;
  }

  return (
    <ExploreContainer>
      {/* <CategoriesMenu categories={brandsCategories} /> */}
      <Breadcrumps>
        <Link to={`/${role}/explore`}>Explore</Link>
        {locArray.map((bc) => (
          <>
            <IoIosArrowForward size={16} />

            <Link to={`/${role}/explore/${bc}`}>
              {bc.charAt(0).toUpperCase() + bc.slice(1)}
            </Link>
          </>
        ))}
      </Breadcrumps>

      {/* {category ? (
        <Outlet />
      ) : (
        <GridContainer
          align="stretch"
          columns="repeat(auto-fill,minmax(300px,1fr))"
          padding="1rem"
        >
          {categories.map((obj) => (
            <CCategoryCard {...obj} />
          ))}
        </GridContainer>
      )} */}

      {allBrands?.data.content.length === 0 ? (
        <NoData message={`No Brands Available}`} />
      ) : (
        <GridContainer
          align="stretch"
          columns="repeat(auto-fill,minmax(300px,1fr))"
          margin="0 0.7rem "
        >
          {allBrands?.data.content.map((obj) => (
            <BrandCard
              setFavBrands={setFavBrands}
              favorites={favBrandsList}
              {...obj}
            />
          ))}
        </GridContainer>
      )}
    </ExploreContainer>
  );
}

export default ExploreBrands;
