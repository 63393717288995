import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const RequireAuth = ({ allowedRoles }) => {
  const auth = useSelector((state) => state.auth);
  const location = useLocation();

  if (auth?.accessToken) {
    const hasAllowedRole = auth?.roles
      ?.map((role) => role.name)
      .find((role) => allowedRoles?.includes(role));

    if (hasAllowedRole) {
      return <Outlet />;
    } else {
     
      return <Navigate to="/unauthorized" state={{ from: location }} replace />;
    }
  } else {
    return <Navigate to="/" state={{ from: location }} replace />;
  }
};

export default RequireAuth;
