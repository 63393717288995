import React, { useRef, useState } from "react";
import { BiMenu } from "react-icons/bi";
import styled from "styled-components";
import logo from "../../../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { DesktopOnly } from "../User.elements";
import { FaSearch } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { useOutsideAlerter } from "../../../hooks/useOutsideAlerter";

function Header({ handleClickMenu }) {
  const dropdownRef = useRef(null);

  const role = useSelector((state) => state.auth.roles);
  const [searchValue, setSearchValue] = useState("");
  const [dropDownActive, setDropDownActive] = useState(false);
  const navigate = useNavigate();
  function navigateToHome() {
    navigate(`/${role[0].name}/dashboard`);
  }

  function navigateExplore(navLink) {
    setDropDownActive(false);
    navigate(navLink);
  }

  const handleSearch = () => {
    // Implement your search logic here using the searchValue state.
    console.log("Searching for:", searchValue);
  };

  const dropDownList = [
    {
      title: "Brands",
      desc: "Where Innovation Meets Identity",
      path: `/${role[0].name}/explore/brands`,
    },
    {
      title: "Creators",
      desc: "Inspiring Creators Worldwide",
      path: `/${role[0].name}/explore/creators`,
    },
    {
      title: "Campaigns",
      desc: "Fueling Success, One Click at a Time",
      path: `/${role[0].name}/explore/campaigns`,
    },
  ];

  useOutsideAlerter(dropdownRef, () => setDropDownActive(false));
  return (
    <HeaderWrapper>
      <MenuIconWrapper>
        <MenuIcon onClick={handleClickMenu} />
      </MenuIconWrapper>

      <Logo onClick={navigateToHome}>
        <img src={logo} alt="Logo"></img>Pacescape
      </Logo>
      {/* <DesktopOnly>
        <SearchField
          value={searchValue}
          onChange={setSearchValue}
          onSearch={handleSearch}
        />
      </DesktopOnly> */}
      <DesktopOnly>
        <NavBtn onClick={() => setDropDownActive(!dropDownActive)}>
          Explore <DropArrow selected={dropDownActive} />
          {dropDownActive && (
            <DropDownContent ref={dropdownRef}>
              {dropDownList.map((list) => (
                <li onClick={() => navigateExplore(list.path)}>
                  <b>{list.title}</b>
                  <small>{list.desc}</small>
                </li>
              ))}
            </DropDownContent>
          )}
        </NavBtn>
      </DesktopOnly>
    </HeaderWrapper>
  );
}

const SearchField = ({ value, onChange, onSearch }) => {
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSearch();
    }
  };

  return (
    <SearchContainer>
      <SearchInput
        type="text"
        placeholder="What are you looking for today?"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyPress={handleKeyPress}
      />
      <SearchButton onClick={onSearch}>
        <FaSearch />
      </SearchButton>
    </SearchContainer>
  );
};

const DropDownContent = styled.ul`
  position: fixed;
  top: 60px;
  right: 2rem;
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 4px;
  z-index: 10;
  list-style: none;
  margin: 0;
  padding: 0;
  display: grid;
  gap: 4px;
  box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.05);
  & > li {
    display: grid;
    gap: 4px;
    padding: 8px 16px;
    &:hover {
      background-color: #eee;
    }
  }

  & > li > b {
    font-size: 16px;
    color: #222;
  }
  & > li > small {
    font-size: 12px;
    color: #666;
  }
`;

const DropArrow = styled(IoIosArrowDown)`
  transition: all 0.2s ease-in-out;
  transform: ${(props) => (props.selected ? "rotate(180deg)" : "rotate(0)")};
`;

const NavBtn = styled.div`
  padding: 8px 12px;
  font-size: 16px;
  color: #444;
  transition: all 0.2s ease-in-out;
  display: flex;
  align-items: center;
  gap: 4px;
  width: min-content;
  &:hover {
    cursor: pointer;
    color: #222;
    background-color: #eee;
  }
`;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 10px;
  max-width: 500px;
`;

const SearchInput = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  flex: 1;
  min-width: 400px;
  height: 45px;
  &:focus {
    border-color: #222;
  }
`;

const SearchButton = styled.button`
  background-color: #222;
  color: white;
  border: none;
  border-radius: 0 5px 5px 0;
  height: 45px;
  padding: 10px 20px;
  cursor: pointer;
  margin-left: -8px;

  &:hover {
    background-color: #0056b3;
  }
`;

const MenuIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0.5rem;
  transition: all 0.2s ease-in-out;
  border-radius: 2rem;
  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
`;
const MenuIcon = styled(BiMenu)`
  color: #494949;
  height: 32px;
  width: 32px;
  &:hover {
    cursor: pointer;
    color: #222;
  }
`;
const HeaderWrapper = styled.nav`
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  margin: 0 8px;
  padding: 4px 0;
  gap: 1rem;
`;

const Logo = styled.a`
  color: inherit;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  color: #444;
  display: flex;
  align-items: center;
  gap: 8px;

  font-size: 24px;

  & > img {
    height: 32px;
  }
`;

export default Header;
