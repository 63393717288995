import React, { useEffect, useState } from "react";
import {
  AnchorText,
  GridContainer,
  Heading,
  Heading2,
  LightText,
  LinkButton,
} from "../../Global";
import {
  BorderedCard,
  DeleteButton,
  InstagramIcon,
  ProfileContainer,
  ProfileDashboard,
  UploadButton,
  YoutubeIcon,
} from "./User.elements";
import { FaPlus, FaTrash } from "react-icons/fa";
import { BASE_URL } from "../../utils/constants";
import { useDispatch, useSelector } from "react-redux";
import { useDeleteYoutubeMutation } from "../../api/endpoints/youtubeEndpoints";
import { useDeleteInstagramMutation } from "../../api/endpoints/instagramEndpoints";
import ReactModal from "react-modal";
import CustomBtn from "../../components/ui/CustomBtn";
import { ackModalStyle } from "../../utils/ModalStyles";
import { setCredentials } from "../../reduxSlices/authSlice";
import { Avatar } from "@mui/material";
import {
  useDeleteProfilePicMutation,
  useUpdateProfilePicMutation,
} from "../../api/endpoints/authEndpoints";

import NoData from "../../components/ui/NoData";
import { useNavigate } from "react-router-dom";
import { useGetFavoriteBrandsQuery } from "../../api/endpoints/exploreEndpoints";
import ShimmerCard from "../../components/ui/ShimmerCard";

import BrandTile from "../BrandOwner/helpers/BrandTile";

function CreatorProfile() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.auth?.userData);
  const accessToken = useSelector((state) => state.auth?.accessToken);

  const userId = useSelector((state) => state.auth?.userData?.userId);
  const youtubeLinked = useSelector((state) => state.auth?.youtubeLinked);
  const instaToken = useSelector((state) => state.auth?.instaToken);

  const [deleteProfilePic, { isLoading: isDeleteProfilePicLoading }] =
    useDeleteProfilePicMutation();

  const {
    data: favBrands,
    isLoading: isFavBrandsLoading,
    isSuccess: isFavBrandSuccess,
  } = useGetFavoriteBrandsQuery(userId);
  const existingData = useSelector((state) => state.auth);
  const [imageURL, setImageURL] = useState("");
  const [ackYtModal, setAckYTModal] = useState(false);
  const [ackInstaModal, setAckInstaModal] = useState(false);

  const [unlinkYoutube, unlinkYoutubeResults] = useDeleteYoutubeMutation();
  const [unlinkInstagram, unlinkInstagramResults] =
    useDeleteInstagramMutation();

  //Linking Accounts
  const handleYoutubeLink = async () => {
    try {
      const customUrl = `${BASE_URL}/user/public/authorize?userId=${userId}`;
      window.location.href = customUrl;
    } catch (e) {
      console.log(e);
    }
  };
  const handleInstagramAuthorize = async () => {
    try {
      const customUrl = `${BASE_URL}/user/public/instagram/authorization`;

      window.location.href = customUrl;
    } catch (e) {
      console.log(e);
    }
  };

  //Unlinking Account

  function handleInstaAck() {
    setAckInstaModal(true);
  }
  function handleYtAck() {
    setAckYTModal(true);
  }
  const handleYoutubeUnlink = async () => {
    try {
      const res = await unlinkYoutube(userId);
      if (res.data) {
        dispatch(
          setCredentials({
            ...existingData,
            youtubeLinked: false,
          })
        );
        setAckYTModal(false);
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleInstagramUnlink = async () => {
    try {
      const res = await unlinkInstagram(userId);
      if (res.data) {
        dispatch(
          setCredentials({
            ...existingData,
            instaToken: "",
          })
        );
        setAckInstaModal(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  async function getProfilePic() {
    try {
      const res = await fetch(
        `${BASE_URL}/user/private/profile/pic?email=${userData.email}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (res.status === 200) {
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImageURL(imageObjectURL);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function handleImageUpload() {
    try {
      const email = userData.email; // Replace with the user's email
      const url = `${BASE_URL}/user/private/update/profile/pic`;

      const formData = new FormData();
      formData.append("file", uploadFile);
      formData.append("email", email);
      const response = await fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        // Request was successful, handle the response here
        getProfilePic();
      } else {
        // Handle error responses here
        console.error("Image upload failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }

  async function handleProfilePicDelete() {
    try {
      const res = await deleteProfilePic(userData.email);
      if (res.data) {
        setImageURL("");
      }
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    getProfilePic();
  }, []);

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setUploadFile(e.target.files[0]);
      let reader = new FileReader();
      reader.onload = function (ev) {
        setSelectedFile(ev.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  //redirect to explore brands
  const navigate = useNavigate();
  const exploreBrands = () => navigate("/creator/explore/brands");

  console.log(favBrands);

  const [favBrandsList, setFavBrands] = useState([]);
  useEffect(() => {
    if (isFavBrandSuccess) {
      setFavBrands(favBrands.data);
    }
  }, [isFavBrandSuccess]);
  if (isFavBrandsLoading) {
    return <ShimmerCard />;
  }
  return (
    <ProfileDashboard padding="0.7rem">
      <ReactModal
        onRequestClose={() => setAckYTModal(false)}
        isOpen={ackYtModal}
        style={ackModalStyle}
      >
        <GridContainer columns="auto 1fr" margin="0 0 1rem 0">
          <YoutubeIcon style={{ margin: "auto" }} />
          <Heading>Unlink Youtube Account</Heading>
        </GridContainer>
        <LightText talign="center">
          Do you want unlink Youtube Account from Pacescape?
        </LightText>
        <GridContainer columns="1fr auto" margin="2rem 0 0 0">
          <AnchorText>Cancel</AnchorText>
          <CustomBtn
            onClick={handleYoutubeUnlink}
            loading={unlinkYoutubeResults.isLoading}
          >
            Unlink
          </CustomBtn>
        </GridContainer>
      </ReactModal>
      <ReactModal
        onRequestClose={() => setAckInstaModal(false)}
        style={ackModalStyle}
        isOpen={ackInstaModal}
      >
        <GridContainer columns="auto 1fr" margin="0 0 1rem 0">
          <InstagramIcon style={{ margin: "auto" }} />
          <Heading>Instagram Data Deletion Request</Heading>
        </GridContainer>
        <LightText talign="center">
          All of your Data related to your Instagram account will ve removed
          from Pacescape
        </LightText>
        <LightText>
          Please confirm if you want to remove your Instagram account from
          Pacescape?
        </LightText>
        <GridContainer columns="1fr auto" margin="2rem 0 0 0">
          <AnchorText onClick={() => setAckInstaModal(false)}>
            Cancel
          </AnchorText>
          <CustomBtn
            onClick={handleInstagramUnlink}
            loading={unlinkInstagramResults.isLoading}
          >
            Unlink & Delete Intagram Data
          </CustomBtn>
        </GridContainer>
      </ReactModal>

      <ProfileContainer columns="2fr 3fr">
        <BorderedCard>
          <Heading2>Basic Account Details</Heading2>
          {imageURL != "" ? (
            <>
              <img
                style={{
                  width: "150px",
                  height: "150px",
                  margin: "auto",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
                src={imageURL}
                alt="Profile"
              ></img>
              <DeleteButton onClick={handleProfilePicDelete}>
                Delete Photo
              </DeleteButton>
            </>
          ) : (
            <>
              {!selectedFile ? (
                <>
                  <Avatar
                    style={{ width: "150px", height: "150px", margin: "auto" }}
                  />
                  <label for="upload">
                    <UploadButton
                      class="glyphicon glyphicon-folder-open"
                      aria-hidden="true"
                    >
                      Choose file
                    </UploadButton>

                    <input
                      type="file"
                      id="upload"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </label>
                </>
              ) : (
                <>
                  <img
                    src={selectedFile}
                    style={{
                      width: "150px",
                      height: "150px",
                      margin: "auto",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                    alt="Preview Image"
                  ></img>
                  {selectedFile.name}
                  <UploadButton onClick={handleImageUpload}>
                    Upload
                  </UploadButton>
                </>
              )}
            </>
          )}

          <GridContainer columns="1fr" rgap="4px">
            <Heading talign="center">
              {userData.firstName + " " + userData.lastName}
            </Heading>
            <LightText talign="center">{userData.email}</LightText>
            <LightText talign="center">{userData.phoneNumber}</LightText>
          </GridContainer>
          <Heading2>Social Websites Linked</Heading2>
          <GridContainer columns="auto 2fr auto">
            <YoutubeIcon />
            <Heading2 talign="flex-start">Youtube</Heading2>
            {youtubeLinked ? (
              <LinkButton
                style={{ background: "#DF7761" }}
                onClick={handleYtAck}
              >
                <FaTrash /> Unlink
              </LinkButton>
            ) : (
              <LinkButton onClick={handleYoutubeLink}>
                <FaPlus /> Link
              </LinkButton>
            )}
          </GridContainer>

          <GridContainer columns="auto 1fr max-content">
            <InstagramIcon />
            <Heading2 talign="flex-start">Instagram</Heading2>
            {instaToken ? (
              <LinkButton
                style={{ background: "#DF7761" }}
                onClick={handleInstaAck}
              >
                <FaTrash /> Unlink & Delete Data
              </LinkButton>
            ) : (
              <LinkButton onClick={handleInstagramAuthorize}>
                <FaPlus /> Link
              </LinkButton>
            )}
          </GridContainer>
        </BorderedCard>
        <BorderedCard>
          <Heading2>Your Favorite Brands</Heading2>
          {favBrandsList.length === 0 ? (
            <NoData
              message="You don't have any Favorite Brands"
              btnTitle="Explore Brands"
              btnFunction={exploreBrands}
            />
          ) : (
            <GridContainer collumns="1fr">
              {favBrandsList.map((obj) => (
                <BrandTile {...obj} />
              ))}
            </GridContainer>
          )}
        </BorderedCard>
      </ProfileContainer>
    </ProfileDashboard>
  );
}

export default CreatorProfile;
