import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../hooks/useRefreshToken";
import { useSelector } from "react-redux";
import LogoLoading from "../pages/Static/LogoLoading";

const PersistData = () => {
  const [isLoading, setIsLoading] = useState(true);
  const accessToken = useSelector((state) => state.auth.accessToken);
  const refresh = useRefreshToken();
  const persist = true;

  useEffect(() => {
    let isMounted = true;
    const verifyRefreshToken = async () => {
      try {
        await refresh();
       
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    // Avoids unwanted call to verifyRefreshToken
    !accessToken && persist ? verifyRefreshToken() : setIsLoading(false);

    return () => (isMounted = false);
  }, []);

  if (!persist) {
    return <Outlet />;
  } else if (isLoading) {
    return <LogoLoading />;
  } else {
    return <Outlet />;
  }
};

export default PersistData;
