import React from "react";
import { GridContainer } from "../../../Global";
import {
  HelpNav,
  HelpNavWrapper,
  HelpToggle,
  HelpToggleList,
  HelpFooter,
  HelpFooterWrapper,
} from "../Static.elements";
import logo from "../../../assets/images/logo.png";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

function HelpLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <GridContainer
      style={{
        overflowY: "auto",
        overflowX: "hidden",
        width: "100vw",
        height: "100vh",
      }}
      place="flex-start"
      rgap="0"
    >
      <HelpNav>
        <HelpNavWrapper>
          <img src={logo} alt="Logo"></img>
          <h1>Pacescape</h1>
          <Link to="/">Login</Link>
        </HelpNavWrapper>
      </HelpNav>
      <HelpToggle>
        <HelpToggleList
          onClick={() => navigate("/help/about")}
          selected={location.pathname.includes("about")}
        >
          About
        </HelpToggleList>
        <HelpToggleList
          onClick={() => navigate("/help/privacypolicy")}
          selected={location.pathname.includes("privacypolicy")}
        >
          Privacy Policy
        </HelpToggleList>
        <HelpToggleList
          onClick={() => navigate("/help/cookiepolicy")}
          selected={location.pathname.includes("cookiepolicy")}
        >
          Cookie Policy
        </HelpToggleList>
        <HelpToggleList
          onClick={() => navigate("/help/termscondition")}
          selected={location.pathname.includes("termscondition")}
        >
          Terms & Condition
        </HelpToggleList>
        <HelpToggleList
          onClick={() => navigate("/help/deletionrequest")}
          selected={location.pathname.includes("deletionrequest")}
        >
          Data Deletion Request
        </HelpToggleList>
      </HelpToggle>
      <Outlet />
      <HelpFooter>
        <HelpFooterWrapper>© Copyright 2023</HelpFooterWrapper>
      </HelpFooter>
    </GridContainer>
  );
}

export default HelpLayout;
