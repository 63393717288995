import styled from "styled-components";
import { GridContainer } from "../../Global";

export const BigHeading = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1rem;
  background: rgb(29, 100, 221);
  background: linear-gradient(
    90deg,
    rgba(29, 100, 221, 1) 6%,
    rgba(88, 111, 245, 1) 25%,
    rgba(29, 100, 221, 1) 60%,
    rgba(0, 212, 255, 1) 100%
  );
  & > h1 {
    font-weight: 400;
    font-size: 56px;
    margin: 0;
    text-align: center;
    color: white;
  }
  & > p {
    color: white;
    text-align: center;
    margin: 0;
    max-width: 600px;
    font-size: 16px;
  }
`;

export const ContentTable = styled.div`
  border: 1px solid #bbb;
  padding: 1rem;
  min-width: 300px;
  & > h3 {
    margin: 0 0 1rem 0;
    font-weight: 400;
    font-size: 24px;
  }
  & > ul {
    padding: 0;
    display: grid;
    row-gap: 1rem;
    list-style: none;
  }
  & > ul > li > a {
    text-decoration: none;
    color: #049ae4;
  }
`;

export const PrivacyContainer = styled(GridContainer)`
  grid-template-columns: 3fr 2fr;
  max-width: 80%;
  margin: auto;
  align-items: flex-start;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

export const HelpNav = styled.div`
  background-color: #273e4a;
  padding: 1rem;
  grid-template-columns: 1fr;
`;

export const HelpNavWrapper = styled(GridContainer)`
  grid-template-columns: auto 1fr auto;
  width: 80%;
  margin: auto;
  & > h1 {
    font-weight: 500;
    color: #eee;
  }
  & > a {
    text-decoration: none;
    background-color: transparent;
    border: 2px solid #eee;
    font-weight: 500;
    border-radius: 8px;
    color: #eee;
    font-size: 18px;
    padding: 0.7rem 1rem;
  }
  & > a:hover {
    background-color: #eee;
    color: #222;
    cursor: pointer;
  }
`;
export const HelpToggle = styled.ul`
  display: flex;
  gap: 2rem;
  list-style: none;
  width: 80%;
  margin: auto;
`;

export const HelpToggleList = styled.li`
  padding: 1rem 0;
  color: ${(props) => (props.selected ? "blue" : "#222")};
  border-bottom: ${(props) =>
    props.selected ? "2px solid blue" : "2px solid transparent"};

  &:hover {
    cursor: pointer;
    color: blue;
    border-bottom: 2px solid blue;
  }
`;

export const HelpFooter = styled.div`
  background-color: #222;
  margin-top: 2rem;
`;

export const HelpFooterWrapper = styled(GridContainer)`
  max-width: 80%;
  margin: auto;
  padding: 2rem;
  color: #fff;
`;
