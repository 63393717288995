import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import SockJS from "sockjs-client";
import { over } from "stompjs";

import {
  BackArrow,
  GridContainer,
  Heading2,
  SmallLightText,
} from "../../Global";
import {
  AttachIcon,
  ChatBodyContainer,
  ChatBodyHeader,
  ChatContent,
  ChatField,
  EllipsisHIcon,
  LocationIcon,
  MyChatBlock,
  OtherChatBlock,
  PhoneIcon,
  PictureIcon,
  VideoIcon,
} from "../Influencer/User.elements";

import image from "../../assets/images/logo.png";
import Avatar from "../Influencer/helpers/Avatar";

import NoData from "../../components/ui/NoData";
import ShimmerCard from "../../components/ui/ShimmerCard";
let stompClient = null;

function RoomBody({ selectedRoom, setSelectedRoom }) {
  const email = useSelector((state) => state.auth.userData.email);
  const messagesEndRef = useRef(null);
  //websocket config

  const [privateChats, setPrivateChats] = useState(new Map());

  const [publicChats, setPublicChats] = useState([]);

  const [userData, setUserData] = useState({
    username: email,
    receivername: "",
    connected: false,
    message: "",
  });
  const onConnected = () => {
    setUserData({ ...userData, connected: true });
    stompClient.subscribe("/chatroom/public", onMessageReceived);
    userJoin();
  };
  //handle incoming chats
  const onMessageReceived = (payload) => {
    console.log(payload);
    let payloadData = JSON.parse(payload.body);
    switch (payloadData.status) {
      case "JOIN":
        if (!privateChats.get(payloadData.senderName)) {
          privateChats.set(payloadData.senderName, []);
          setPrivateChats(new Map(privateChats));
        }
        break;
      case "MESSAGE":
        publicChats.push(payloadData);
        setPublicChats([...publicChats]);
        break;
      default:
        break;
    }
  };

  const connect = () => {
    let Sock = new SockJS(
      "https://api-gateway-dev.pacewisdom.in/user/public/ws"
    );
    stompClient = over(Sock);
    stompClient.connect({}, onConnected, onError);
  };

  const onError = (err) => {
    console.log(err);
  };

  //handle user joined message
  const userJoin = () => {
    let chatMessage = {
      senderName: email,
      status: "JOIN",
    };
    stompClient.send("/app/message", {}, JSON.stringify(chatMessage));
  };

  const handleMessage = (event) => {
    const { value } = event.target;
    setUserData({ ...userData, message: value });
  };

  const sendValue = () => {
    if (stompClient) {
      let chatMessage = {
        senderName: userData.username,
        message: userData.message,
        status: "MESSAGE",
      };
      console.log(chatMessage);
      stompClient.send("/app/message", {}, JSON.stringify(chatMessage));
      setUserData({ ...userData, message: "" });
    }
  };

  useEffect(() => {
    if (selectedRoom) {
      connect();
    }
  }, [selectedRoom]);

  const handleBackClick = () => {
    setSelectedRoom(null);
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      sendValue();
    }
  };

  if (selectedRoom) {
    if (userData.connected) {
      return (
        <ChatBodyContainer isSelected={selectedRoom} columns="1fr">
          <ChatBodyHeader isSelected={selectedRoom}>
            <span>
              <BackArrow onClick={handleBackClick} />
            </span>

            <Avatar image={image}></Avatar>
            <GridContainer align="flex-start" justify="flex-start" rgap="4px">
              <Heading2>Campaign: {selectedRoom?.title}</Heading2>
            </GridContainer>
            {/* <GridContainer columns="auto auto auto">
              <VideoIcon />
              <PhoneIcon />
              <EllipsisHIcon />
            </GridContainer> */}
          </ChatBodyHeader>
          <ChatContent>
            {publicChats.map((obj, i) => {
              if (obj.senderName === userData.username) {
                return <MyChat {...obj} key={obj.id}></MyChat>;
              } else {
                return <OtherChat {...obj} key={obj.id}></OtherChat>;
              }
            })}
            <div ref={messagesEndRef}></div>
          </ChatContent>
          <ChatField>
            <input
              onKeyDown={handleKeypress}
              value={userData.message}
              onChange={handleMessage}
              placeholder="Type a message..."
            ></input>

            <button onClick={sendValue}>Send</button>
          </ChatField>
        </ChatBodyContainer>
      );
    } else {
      return <ShimmerCard />;
    }
  } else {
    return <NoData message="Please select any chats" />;
  }
}

export default RoomBody;

function MyChat({ image, message }) {
  return (
    <GridContainer columns="1fr">
      <GridContainer
        columns="auto auto auto"
        justify="flex-end"
        align="flex-start"
        gap="8px"
      >
        <MyChatBlock>{message}</MyChatBlock>
        <Avatar image={image} />
      </GridContainer>
    </GridContainer>
  );
}
function OtherChat({ image, message }) {
  return (
    <GridContainer columns="1fr">
      <GridContainer
        columns="auto max-content auto"
        justify="flex-start"
        align="flex-start"
        gap="8px"
      >
        <Avatar image={image} />
        <OtherChatBlock>{message}</OtherChatBlock>
        <SmallLightText
          style={{ height: "100%", alignItems: "end", display: "flex" }}
        ></SmallLightText>
      </GridContainer>
    </GridContainer>
  );
}
