import React from "react";
import {
  GridContainer,
  Heading2,
  LightText,
  LinkButton,
} from "../../../Global";
import {
  InstagramIcon,
  RedirectIcon,
  IgPostContainer,
  LinkContainer,
  AddIcon,
  InstagramAalytics,
} from "../User.elements";

import { formatDateToDaysAgo } from "../../../utils/parseDate";

function InstagramContent({
  instaDetails,
  instagramBasicInfo,
  igPostData,
  handleInstagramAuthorize,
}) {
  return (
    <GridContainer justify="flex-start" columns="1fr" rgap="2rem">
      {instaDetails ? (
        <InstagramAalytics>
          <GridContainer columns="auto 1fr ">
            <InstagramIcon />
            <div>
              <Heading2 talign="flex-start">
                {instagramBasicInfo?.data?.data?.username}
              </Heading2>
              <small>{instagramBasicInfo?.data?.data?.account_type}</small>
            </div>
          </GridContainer>
          <GridContainer columns="1fr">
            {igPostData.map((post, i) => (
              <IgPostContainer key={post.id} columns="auto 1fr">
                <img
                  src={post.media_url}
                  alt="Instagram Post"
                  height="80px"
                  width="90px"
                ></img>
                <div>
                  <b>Instagram Post</b>
                  <p>{post.username}</p>
                  <small>{formatDateToDaysAgo(post.timestamp)}</small>
                </div>
              </IgPostContainer>
            ))}
          </GridContainer>
        </InstagramAalytics>
      ) : (
        <LinkContainer onClick={handleInstagramAuthorize} columns="1fr">
          <InstagramIcon
            style={{ height: "50px", width: "50px", margin: "auto" }}
          />
          <LightText talign="center">
            Please link your Instagram Account to view your Instagram Analytics.
          </LightText>
          <LinkButton style={{ margin: "auto" }}>
            <AddIcon /> Link
          </LinkButton>
        </LinkContainer>
      )}
    </GridContainer>
  );
}

export default InstagramContent;
