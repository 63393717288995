import React, { useEffect, useState } from "react";

import success from "../../assets/images/success_icon.svg";
import errorVector from "../../assets/images/error.svg";
import { ErrorText, VerificationStatusCard } from "./components.elements";
import { Heading, Heading2 } from "../../Global";
import { useNavigate, useSearchParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../utils/constants";

function VerificationStatus() {
  const navigate = useNavigate();
  const [error, setError] = useState({ field: "", message: "" });
  const [verifSuccess, setVerifSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  let token = searchParams.get("token");

  

  function callError(field, message) {
    setError({
      field: field,
      message: message,
    });
  }

  async function requestVerification() {
    try {
      const response = await fetch(
        `${BASE_URL}/user/public/verify/email/token?token=${token}`
      );

      if (response.status === 400) {
        callError(
          "global",
          "Your authorization token is not valid.Please try again   "
        );
        return;
      }
      if (response.status === 200) {
        setVerifSuccess(true);
        return;
      }
      if (response.status === 404) {
        callError("global", "Something went wrong ");
        return;
      }

      //   if(response.error.status)
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    requestVerification();
  }, []);
  if (error.field === "global") {
    return (
      <VerificationStatusCard>
        <img src={errorVector} height="140px" alt="Error"></img>

        <Heading>Verifaction Failed</Heading>
        <ErrorText>{error.message}</ErrorText>
      </VerificationStatusCard>
    );
  } else if (verifSuccess === true) {
    return (
      <VerificationStatusCard>
        <img src={success} height="140px" alt="Success Check mark"></img>
        <Heading2>Your email has been verified!</Heading2>
        <button onClick={() => navigate("/")}>Redirect to Login</button>
      </VerificationStatusCard>
    );
  } else {
    return <p>Loading...</p>;
  }
}

export default VerificationStatus;
