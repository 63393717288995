import React from "react";
import styled, { keyframes } from "styled-components";

const shimmerAnimation = keyframes`
    0% {
        background-position: -200%;
    }
    100% {
        background-position: 200%;
    }
`;

const ShimmerCardWrapper = styled.div`
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  /* animation: ${shimmerAnimation} 1.5s infinite; */
  border-radius: 8px;
  margin: 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;

const ShimmerCardContent = styled.div`
  padding: 20px;
`;

const ShimmerLines = styled.div`
  width: ${(props) => props.width};
  height: 24px;
  margin: 1rem 0;
  border-radius: 2rem;
  /* background-color: #ccc; */
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    #ccc 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 200% 100%;
  animation: ${shimmerAnimation} 1.5s infinite;
`;

const ShimmerCard = () => {
  return (
    <ShimmerCardWrapper>
      <ShimmerCardContent>
        <ShimmerLines width="30%" />
        <br></br>
        <ShimmerLines width="90%" />
        <ShimmerLines width="90%" />
        <ShimmerLines width="60%" />
      </ShimmerCardContent>
    </ShimmerCardWrapper>
  );
};

export default ShimmerCard;
