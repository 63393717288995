import {
  AccountsIcon,
  AnalyticsIcon,
  CampaignIcon,
  DashboardIcon,
  MessagesIcon,
} from "../User.elements";

export function getIcon(name) {
  switch (name) {
    case "dashboard":
      return DashboardIcon;
    case "analytics":
      return AnalyticsIcon;
    case "chat":
      return MessagesIcon;
    case "campaign":
      return CampaignIcon;
    default:
      return CampaignIcon;
  }
}

export const navData = [
  {
    Icon: DashboardIcon,
    href: "/user/dashboard",
    label: "Dashboard",
  },
  {
    Icon: AccountsIcon,
    href: "/user/accounts",
    label: "My Accounts",
  },
  {
    Icon: CampaignIcon,
    href: "/user/campaign",
    label: "Campaign",
  },
  {
    Icon: AnalyticsIcon,
    href: "/user/analytics",
    label: "Analytics",
  },
  {
    Icon: MessagesIcon,
    href: "/user/messages",
    label: "Messages",
  },
];
