import React, { useEffect, useLayoutEffect, useState } from "react";
import { useLazyGetYoutubeDataQuery } from "../../api/endpoints/youtubeEndpoints";
import {
  useLazyGetInstagramBasicInfoQuery,
  useLazyGetInstagramMediaByIdQuery,
  useLazyGetInstagramMediaQuery,
} from "../../api/endpoints/instagramEndpoints";
import { useSelector } from "react-redux";
import { AnalyticsContainer } from "./User.elements";
import { GridContainer, Heading } from "../../Global";

import { BASE_URL } from "../../utils/constants";
import ShimmerCard from "../../components/ui/ShimmerCard";
import InstagramContent from "./helpers/InstagramContent";
import YoutubeContent from "./helpers/YoutubeContent";
function Analytics() {
  const [igPostData, setIGPostData] = useState([]);
  const userId = useSelector((state) => state.auth.userData?.userId);

  const instaDetails = useSelector((state) => state.auth.instaToken);
  const youtubeLinked = useSelector((state) => state.auth.youtubeLinked);
  const [getYoutubeData, youtubeData] = useLazyGetYoutubeDataQuery();
  const [getIGMedia, igMedia] = useLazyGetInstagramMediaQuery();
  const [getInstagramData, instagramBasicInfo] =
    useLazyGetInstagramBasicInfoQuery();

  const [getIGMediaDetails, igMediaDetails] =
    useLazyGetInstagramMediaByIdQuery();

  useLayoutEffect(() => {
    if (youtubeLinked) {
      getYoutubeData(userId);
    }
    if (instaDetails) {
      getInstagramData({
        userId: userId,
        access_token: instaDetails,
        fields: "id,username,account_type",
      });
      getIGMedia({
        // access_token: dummyInstaToken,
        userId: userId,
        access_token: instaDetails,
        fields: "id,username,account_type",
      });
    }
  }, []);

  const handleInstagramAuthorize = async () => {
    try {
      const customUrl = `${BASE_URL}/user/public/instagram/authorization`;

      window.location.href = customUrl;
    } catch (e) {
      console.log(e);
    }
  };

  async function getPostDetails() {
    const detailsPromises = igMedia?.data?.data.data
      .slice(0, 5)
      .map(async (data) => {
        const response = await getIGMediaDetails({
          // access_token: dummyInstaToken,
          access_token: instaDetails,
          mediaId: data.id,
          fields: "id,media_type,media_url,username,timestamp",
          userId: userId,
        });

        return response?.data.data;
      });
    try {
      const postDetails = await Promise.all(detailsPromises);
      setIGPostData(postDetails);
    } catch (e) {
      console.log(e);
    }
  }
  const handleYoutubeLink = async () => {
    try {
      const customUrl = `${BASE_URL}/user/public/authorize?userId=${userId}`;
      window.location.href = customUrl;
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (igMedia.isSuccess) {
      getPostDetails();
    }
  }, [igMedia]);
  if (
    youtubeData.isLoading ||
    igMedia.isLoading ||
    instagramBasicInfo.isLoading ||
    igMediaDetails.isLoading
  ) {
    return <ShimmerCard />;
  } else {
    return (
      <AnalyticsContainer>
        <GridContainer columns="1fr">
          {/* Youtube Content */}
          <Heading>Youtube Analytics</Heading>
          <YoutubeContent
            youtubeData={youtubeData}
            handleYoutubeLink={handleYoutubeLink}
            youtubeLinked={youtubeLinked}
          />
        </GridContainer>

        <GridContainer columns="1fr">
          {/* Instagram Content */}
          <Heading>Instagram Analytics</Heading>

          <InstagramContent
            instaDetails={instaDetails}
            instagramBasicInfo={instagramBasicInfo}
            igPostData={igPostData}
            handleInstagramAuthorize={handleInstagramAuthorize}
          />
        </GridContainer>
      </AnalyticsContainer>
    );
  }
}

export default Analytics;
