import React from "react";
import { GridContainer, BigHeading } from "../../Global";
import { Outlet, useLocation } from "react-router-dom";

import ExploreCard from "./helpers/ExploreCard";
import { ExploreContainer } from "./Explore.elements";
import { exploreCards } from "../../utils/constants";

function Explore() {
  const location = useLocation();
  var isCategorySelected = location.pathname.split("/").length >= 4;

  if (isCategorySelected) {
    return <Outlet />;
  } else {
    return (
      <ExploreContainer columns="1fr" padding="1rem">
        <BigHeading>
          Explore, <span style={{ color: "#556BEC" }}>Pacescape</span>
        </BigHeading>
        <GridContainer
          align="stretch"
          justify="center"
          columns="repeat(auto-fill,minmax(300px,1fr))"
        >
          {exploreCards.map((obj) => (
            <ExploreCard {...obj} />
          ))}
        </GridContainer>
      </ExploreContainer>
    );
  }
}

export default Explore;
