import React from "react";
import logo from "../../assets/images/logo.png";
import { LogoLoadingContainer } from "../../components/ui/components.elements";

function LogoLoading() {
  return (
    <LogoLoadingContainer>
      <img src={logo} height="60px" alt="Loading Logo Screen"></img>
    </LogoLoadingContainer>
  );
}

export default LogoLoading;
