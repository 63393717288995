import styled from "styled-components";

import { GridContainer } from "../../Global";

export const ExploreContainer = styled(GridContainer)`
  place-content: flex-start;
  align-items: flex-start;
  display: grid;
  grid-template-columns: 1fr;

  overflow: auto;
`;

export const ExploreCardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 0.7rem;
  width: 100%;
  & img {
    height: 100px;
  }
  & > div {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    min-width: 250px;
    max-width: 250px;
  }
  @media screen and (max-width: 968px) {
    & img {
      height: 150px;
    }
    & > div {
      max-width: none;
    }
  }
`;

export const CategoriesTopBar = styled.ul`
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  margin: 0;
  padding: 0;
  padding: 8px;
  gap: 1rem;
  position: sticky;
  top: 0;
  z-index: 5;
  background-color: #f9f9f9;
  list-style: none;

  border-bottom: 1px solid #ccc;
  box-shadow: ${(props) => {
    if (props.shadow === "right") {
      return "inset -12px 0 10px -10px rgb(0 0 0 / 0.4)";
    } else if (props.shadow === "left") {
      return "inset 12px 0 10px -10px rgb(0 0 0 / 0.4)";
    } else if (props.shadow === "both") {
      return "inset 12px 0 10px -10px rgb(0 0 0 / 0.4), inset -12px 0 10px -10px rgb(0 0 0 / 0.4)";
    }
  }};
  overflow-y: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
  &:first-child {
    padding-left: 1rem;
  }
  &:last-child {
    padding-right: 1rem;
  }
  & > li {
    color: #666;
    width: max-content;
    font-size: 16px;
    &:hover {
      color: #222;
      cursor: pointer;
    }
  }
`;
export const CCategoryContainer = styled(GridContainer)`
  place-content: flex-start;
  align-items: flex-start;
  display: grid;
  grid-template-columns: 1fr;
  overflow: auto;
  padding: 0 1rem;
`;

export const Breadcrumps = styled.span`
  display: flex;
  gap: 8px;
  margin: 0.7rem;
  align-items: center;
  & a {
    color: #666;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  & > a:last-child {
    color: #222;
    font-weight: 600;
  }
`;
