import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ChatIcon from "@mui/icons-material/Chat";
import image from "../../../assets/images/male_avatar.svg";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/constants";
import {
  useAddFavCreatorMutation,
  useRemoveFavCreatorMutation,
} from "../../../api/endpoints/exploreEndpoints";
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CreatorCard({
  firstName,
  lastName,
  userId: creatorId,
  email,

  favorites,
  setFavorites,
}) {
  const navigate = useNavigate();
  const role = useSelector((state) => state.auth.roles[0]).name;
  const userId = useSelector((state) => state.auth.userData.userId);
  const accessToken = useSelector((state) => state.auth?.accessToken);

  const [addCreatorFav, { isLoading: isAddFavCreatorLoading }] =
    useAddFavCreatorMutation();
  const [toggleCreatorFav, { isLoading: isRemoveFavCreatorLoading }] =
    useRemoveFavCreatorMutation();

  const randomColorRef = useRef(
    Math.floor(Math.random() * 16777215).toString(16)
  );

  //get profile pic on component mount
  const [imageURL, setImageURL] = useState();
  async function getProfilePic() {
    try {
      const res = await fetch(
        `${BASE_URL}/user/private/profile/pic?email=${email}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (res.status === 200) {
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImageURL(imageObjectURL);
      }
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    getProfilePic();
  }, []);

  const handleChatNavigate = () => {
    navigate(`/${role}/chat/${creatorId}`);
  };

  //handle adding/removing of favorite creator
  const handleFavClick = async () => {
    if (favorites.includes(email)) {
      try {
        const res = await toggleCreatorFav({
          userId: userId,
          creatorId: creatorId,
          flag: false,
        });
        if (res.data) {
          setFavorites((curFav) => curFav.filter((cf) => cf !== email));
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        const res = await addCreatorFav({
          creatorId: creatorId,
          userId: userId,
        });

        if (res.error.status === 400) {
          const res = await toggleCreatorFav({
            userId: userId,
            creatorId: creatorId,
            flag: true,
          });
          if (res.data) {
            setFavorites((curFav) => [...curFav, email]);
          }
        }
        if (res.data) {
          setFavorites((curFav) => [...curFav, email]);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardHeader
        avatar={
          <Avatar
            sx={{ bgcolor: `#${randomColorRef.current}` }}
            aria-label="recipe"
          >
            {firstName.substring(0, 1).toUpperCase()}
          </Avatar>
        }
        title={`${firstName} ${lastName}`}
        subheader={email}
      />
      <CardMedia
        component="img"
        height="194"
        image={imageURL}
        alt="Profile image"
      />

      <CardActions disableSpacing>
        <IconButton onClick={handleFavClick} aria-label="Add to favorites">
          <FavoriteIcon htmlColor={favorites?.includes(email) ? "red" : ""} />
        </IconButton>
        {/* <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
        <IconButton onClick={handleChatNavigate} aria-label="Chat">
          <ChatIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
