import React from "react";
import { AvatarImage, GridContainer } from "../../../Global";
import { Avatar } from "@mui/material";

export default function UserStack({ images }) {
  return (
    <GridContainer margin="0" columns="repeat(auto-fill,minmax(20px,1fr))">
      {images.map((img, i) => {
        if (img.image) {
          return (
            <AvatarImage
              key={img.email}
              loading="lazy"
              src={img?.image}
              alt="Profile"
            />
          );
        } else {
          return (
            <Avatar
              style={{
                width: "48px",
                height: "48px",
                boxShadow: "0 0 10px 5px #00000022",
              }}
            />
          );
        }
      })}
    </GridContainer>
  );
}
