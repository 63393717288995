import campaignVector from "../assets/images/campaign.svg";
import creatorVector from "../assets/images/creator.svg";
import brandVector from "../assets/images/brand.svg";

export const BASE_URL = "https://api-gateway-dev.pacewisdom.in";

export const creatorContentCategories = [
  {
    title: "YouTubers",
    active: false,
    path: "YouTubers",
  },
  {
    title: "Bloggers",
    active: false,
    path: "Bloggers",
  },
  {
    title: "Podcasters",
    active: false,
    path: "Podcasters",
  },
  {
    title: "Twitch Streamers",
    active: false,
    path: "Twitch-Streamers",
  },
  {
    title: "Social Media Influencers",
    active: false,
    path: "Social-Media-Influencers",
  },
  {
    title: "Photographers",
    active: false,
    path: "Photographers",
  },
  {
    title: "Graphic Designers",
    active: false,
    path: "Graphic-Designers",
  },
  {
    title: "Musicians and Singers",
    active: false,
    path: "Musicians-and-Singers",
  },
  {
    title: "Cooking and Food BloggersVloggers",
    active: false,
    path: "Cooking-and-Food-BloggersVloggers",
  },
  {
    title: "Travel Content Creators",
    active: false,
    path: "Travel-Content-Creators",
  },
  {
    title: "Educational Content Creators",
    active: false,
    path: "Educational-Content-Creators",
  },
  {
    title: "DIY and Craft Enthusiasts",
    active: false,
    path: "DIY-and-Craft-Enthusiasts",
  },
  {
    title: "Fitness and Wellness Influencers",
    active: false,
    path: "Fitness-and-Wellness-Influencers",
  },
  {
    title: "Gaming Content Creators",
    active: false,
    path: "Gaming-Content-Creators",
  },
  {
    title: "Book Reviewers and Literary Critics",
    active: false,
    path: "Book-Reviewers-and-Literary-Critics",
  },
  {
    title: "Tech Reviewers",
    active: false,
    path: "Tech-Reviewers",
  },
  {
    title: "Parenting BloggersVloggers",
    active: false,
    path: "Parenting-BloggersVloggers",
  },
  {
    title: "Political Commentators",
    active: false,
    path: "Political-Commentators",
  },
  {
    title: "Environmental Activists",
    active: false,
    path: "Environmental-Activists",
  },
  {
    title: "Artists and Illustrators",
    active: false,
    path: "Artists-and-Illustrators",
  },
];

export const exploreCards = [
  {
    id: 0,
    title: "Brand",
    desc: "Explore our Pacescape brand for innovative products and experiences.",
    image: brandVector,
    path: "brands",
  },
  {
    id: 1,
    title: "Creators",
    desc: "Talented creators behind Pacescape who bring your dreams to life.",
    image: creatorVector,
    path: "creators",
  },
  {
    id: 2,
    title: "Campaign",
    desc: "Discover our latest Pacescape campaigns and join the adventure.",
    image: campaignVector,
    path: "campaigns",
  },
];

export const productDetails = [
  {
    name: "Product Image",
    description:
      "Use high-quality images or visuals that clearly depict the product from various angles. Show it in use or in its intended environment whenever possible.",
  },
  {
    name: "Product Name and Brand",
    description:
      "Clearly display the product's name and the brand it belongs to.",
  },
  {
    name: "Key Features",
    description:
      "Highlight the most important features that set the product apart from others in the market. Use bullet points or a concise list to make these features easy to identify.",
  },
  {
    name: "Benefits",
    description:
      "Explain how the product addresses customer needs or solves a problem. Showcase the benefits of using the product, such as saving time, money, or effort.",
  },
  {
    name: "Specifications",
    description:
      "Provide technical details and specifications, including size, weight, materials, and any relevant measurements. Include any compatibility information if applicable.",
  },
  {
    name: "Price",
    description:
      "Clearly state the product's price. If there are any discounts, promotions, or bundles, highlight them.",
  },
  {
    name: "Availability",
    description:
      "Mention the product's availability status (e.g., 'In Stock,' 'Pre-order,' 'Limited Quantity'). Include estimated delivery times if applicable.",
  },
  {
    name: "Customer Reviews and Ratings",
    description:
      "Showcase positive reviews and ratings from satisfied customers. Consider featuring testimonials that highlight the product's quality and performance.",
  },
  {
    name: "Usage Instructions",
    description:
      "Provide clear and concise instructions on how to use the product effectively. Include any safety precautions if necessary.",
  },
  {
    name: "Warranty and Return Policy",
    description:
      "Communicate information about warranties, guarantees, and the return policy. Highlight any customer support or after-sales services offered.",
  },
  {
    name: "Packaging",
    description:
      "Display the product's packaging if it adds value or enhances the product's appeal. Mention if the packaging is eco-friendly or recyclable.",
  },
  {
    name: "Comparisons",
    description:
      "Compare the product to similar products in terms of features, price, and quality. Highlight what makes it a better choice.",
  },
  {
    name: "Demonstrations",
    description:
      "Use videos or animations to demonstrate how the product works or how it can be used effectively.",
  },
  {
    name: "Customer Testimonials",
    description:
      "Showcase real-life stories or quotes from satisfied customers who have benefited from the product.",
  },
  {
    name: "Accessories and Add-ons",
    description:
      "Mention any additional accessories or complementary products that can enhance the user experience.",
  },
  {
    name: "Unique Selling Proposition (USP)",
    description:
      "Clearly articulate what makes the product unique or better than alternatives in the market.",
  },
  {
    name: "Call to Action (CTA)",
    description:
      "Encourage customers to take action, such as 'Buy Now,' 'Learn More,' or 'Add to Cart.'",
  },
  {
    name: "Social Proof",
    description:
      "Include icons or badges indicating affiliations, certifications, or awards the product has received.",
  },
  {
    name: "Shipping and Delivery",
    description:
      "Provide information on shipping options, tracking, and delivery methods. Highlight free shipping or expedited shipping options if available.",
  },
  {
    name: "Customer Support",
    description:
      "Mention available customer support channels (e.g., phone, email, chat) and hours of operation.",
  },
];

export const brandsCategories = [
  {
    active: false,
    path: "Luxury",
    title: "Luxury",
    description:
      "Luxury brands are known for their high-quality, exclusive products, often associated with prestige and premium pricing.",
  },
  {
    active: false,
    path: "Fast-Fashion",
    title: "Fast Fashion",
    description:
      "Fast fashion brands focus on quickly producing affordable, trendy clothing and accessories.",
  },
  {
    active: false,
    path: "Electronics",
    title: "Electronics",
    description:
      "Electronics brands manufacture a wide range of electronic devices, from smartphones to home appliances.",
  },
  {
    active: false,
    path: "Automotive",
    title: "Automotive",
    description:
      "Automotive brands produce cars, trucks, and other vehicles, emphasizing performance, safety, and innovation.",
  },
  {
    active: false,
    path: "Tech-and-Gadgets",
    title: "Tech and Gadgets",
    description:
      "Tech and gadgets brands specialize in cutting-edge technology products, including laptops, smartphones, and wearable devices.",
  },
  {
    active: false,
    path: "Beauty-and-Cosmetics",
    title: "Beauty and Cosmetics",
    description:
      "Beauty and cosmetics brands offer skincare, makeup, and grooming products to enhance personal appearance.",
  },
  {
    active: false,
    path: "Food-and-Beverage",
    title: "Food and Beverage",
    description:
      "Food and beverage brands produce a variety of consumables, from snacks to beverages and gourmet foods.",
  },
  {
    active: false,
    path: "Sportswear",
    title: "Sportswear",
    description:
      "Sportswear brands design athletic clothing and footwear, suitable for sports and fitness activities.",
  },
  {
    active: false,
    path: "Outdoor",
    title: "Outdoor",
    description:
      "Outdoor brands focus on gear and clothing for outdoor enthusiasts and adventurers.",
  },
  {
    active: false,
    path: "Home-Appliance",
    title: "Home Appliance",
    description:
      "Home appliance brands create a range of products for household use, such as refrigerators, washing machines, and vacuum cleaners.",
  },
  {
    active: false,
    path: "Furniture",
    title: "Furniture",
    description:
      "Furniture brands offer a wide selection of home furnishings, including sofas, tables, and chairs.",
  },
  {
    active: false,
    path: "Health-and-Wellness",
    title: "Health and Wellness",
    description:
      "Health and wellness brands provide products related to fitness, nutrition, and personal well-being.",
  },
  {
    active: false,
    path: "Entertainment-and-Media",
    title: "Entertainment and Media",
    description:
      "Entertainment and media brands encompass film studios, music labels, and streaming platforms.",
  },
  {
    active: false,
    path: "Green-and-Sustainable",
    title: "Green and Sustainable",
    description:
      "Green and sustainable brands prioritize eco-friendly and ethical practices in their products and operations.",
  },
  {
    active: false,
    path: "Children-Items",
    title: "Children Items",
    description:
      "Children's brands focus on products for kids, including toys, clothing, and educational materials.",
  },
  {
    active: false,
    path: "Pet-Care",
    title: "Pet Care",
    description:
      "Pet care brands offer pet food, accessories, and healthcare products for pets and pet owners.",
  },
  {
    active: false,
    path: "Jewelry-and-Accessories",
    title: "Jewelry and Accessories",
    description:
      "Jewelry and accessories brands create a wide range of adornments and fashion accessories.",
  },
];

export const campaignCategories = [
  {
    name: "Social Media Campaigns",
    description:
      "Social media campaigns involve promoting products, services, or causes on platforms like Facebook, Instagram, Twitter, and LinkedIn.",
  },
  {
    name: "Email Marketing Campaigns",
    description:
      "Email marketing campaigns focus on reaching and engaging a target audience through personalized email messages.",
  },
  {
    name: "Search Engine Marketing (SEM) Campaigns",
    description:
      "SEM campaigns involve paid advertising on search engines like Google or Bing, typically through pay-per-click (PPC) ads.",
  },
  {
    name: "Content Marketing Campaigns",
    description:
      "Content marketing campaigns create and distribute valuable, informative, and engaging content to attract and retain customers.",
  },
  {
    name: "Influencer Marketing Campaigns",
    description:
      "Influencer marketing campaigns leverage social media influencers to promote products or services to their followers.",
  },
  {
    name: "Video Marketing Campaigns",
    description:
      "Video marketing campaigns use video content on platforms like YouTube, TikTok, and Vimeo to engage and inform audiences.",
  },
  {
    name: "Affiliate Marketing Campaigns",
    description:
      "Affiliate marketing campaigns involve partnerships with affiliates who promote products or services in exchange for a commission.",
  },
  {
    name: "E-commerce Promotions",
    description:
      "E-commerce promotions include discounts, deals, and special offers to boost online sales and attract customers.",
  },
  {
    name: "Product Launch Campaigns",
    description:
      "Product launch campaigns create buzz and anticipation around a new product or service, often with teasers and sneak peeks.",
  },
  {
    name: "Nonprofit Fundraising Campaigns",
    description:
      "Nonprofit fundraising campaigns aim to raise donations and support for charitable organizations and causes.",
  },
  {
    name: "Event Promotion Campaigns",
    description:
      "Event promotion campaigns advertise and market events, such as conferences, webinars, and live streams.",
  },
  {
    name: "Local SEO Campaigns",
    description:
      "Local SEO campaigns optimize online presence for local businesses, targeting local search engine users.",
  },
  {
    name: "Customer Retention Campaigns",
    description:
      "Customer retention campaigns focus on engaging and retaining existing customers through loyalty programs and personalized offers.",
  },
  {
    name: "Educational Campaigns",
    description:
      "Educational campaigns provide valuable information and knowledge on specific topics, often as a part of content marketing.",
  },
  {
    name: "Cause Marketing Campaigns",
    description:
      "Cause marketing campaigns align brands with social or environmental causes to promote social responsibility and drive sales.",
  },
  {
    name: "Holiday and Seasonal Campaigns",
    description:
      "Holiday and seasonal campaigns tailor marketing efforts to specific occasions, such as Christmas, Halloween, or summer sales.",
  },
  {
    name: "Mobile App Promotion Campaigns",
    description:
      "Mobile app promotion campaigns aim to increase downloads and user engagement for mobile applications.",
  },
  {
    name: "B2B Marketing Campaigns",
    description:
      "B2B marketing campaigns target other businesses and may include lead generation, account-based marketing, and industry-specific strategies.",
  },
];
