import React from "react";
import unauthorized from "../../assets/images/unauthorized.svg";
import { useNavigate } from "react-router-dom";
import { NotFoundContainer } from "../../components/ui/components.elements";

function Unauthorized() {
  const navigate = useNavigate();
  return (
    <NotFoundContainer>
      <div>
        <img src={unauthorized} height="160px" alt="Unauthorized Vector"></img>
        <p>You are not authorized to access this pages.</p>
        <button onClick={() => navigate("/")}>Go to Dashboard</button>
      </div>
    </NotFoundContainer>
  );
}

export default Unauthorized;
