import React, { useEffect, useState } from "react";

import { MessagesContainer, MessagesBody } from "../Influencer/User.elements";

import { dummyChatData2, dummyChatData3 } from "../../data/dummyData";

import { useSelector } from "react-redux";
import {
  useLazyGetCampaignByUserIdQuery,
  useLazyGetCreatorCampaignsQuery,
} from "../../api/endpoints/campaignEndpoint";

//Components
import ChatBody from "./ChatBody";
import RoomBody from "./RoomBody";
import MessageList from "./MessageList";

function Messages() {
  //currentuser info

  const userId = useSelector((state) => state.auth.userData.userId);
  const isBrandOwner =
    useSelector((state) =>
      state.auth.roles.find((obj) => obj.name === "brandowner")
    )?.name === "brandowner";

  const [getCreatorCampaigns, creatorCampaignResult] =
    useLazyGetCreatorCampaignsQuery();
  const [getBrandOwnerCampaigns, brandOwnerCampaignsResults] =
    useLazyGetCampaignByUserIdQuery();

  //Possible States: CHATROOM , DM
  const [tab, setTab] = useState("CHATROOM");

  const [selectedUser, setSelectedUser] = useState();
  const [selectedRoom, setSelectedRoom] = useState();

  const [curChat, setCurChat] = useState();

  //handle websocket connection
  useEffect(() => {
    if (isBrandOwner) {
      getBrandOwnerCampaigns(userId);
    }
    getCreatorCampaigns(userId);
  }, []);

  return (
    <MessagesContainer>
      <MessagesBody>
        <MessageList
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          selectedRoom={selectedRoom}
          setSelectedRoom={setSelectedRoom}
          setTab={setTab}
          campaignList={
            isBrandOwner
              ? brandOwnerCampaignsResults.data?.data
              : creatorCampaignResult?.data?.data.content
          }
        ></MessageList>

        {/* Based selected tabs toggle */}
        {tab === "DM"
          ? selectedUser && (
              <ChatBody
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              ></ChatBody>
            )
          : selectedRoom && (
              <RoomBody
                selectedRoom={selectedRoom}
                setSelectedRoom={setSelectedRoom}
                tab={tab}
                setTab={setTab}
              />
            )}
      </MessagesBody>
    </MessagesContainer>
  );
}

export default Messages;
