export const campaignModalStyle = {
  content: {
    maxWidth: "600px",
    borderRadius: "8px",
    margin: "auto",
    padding: "1.5rem",
    height: "fit-content",
  },
  overlay: {
    background: "rgba(0,0,0,0.5)",
  },
};
export const ackModalStyle = {
  content: {
    width: "fit-content",
    borderRadius: "8px",
    margin: "auto",
    padding: "1.5rem",
    height: "fit-content",
  },
  overlay: {
    background: "rgba(0,0,0,0.5)",
  },
};
