import React from "react";
import { AnchorText, GridContainer, LightText } from "../../../Global";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useSelector } from "react-redux";

export const ProfileTileContainer = styled(GridContainer)`
  align-items: center;
  grid-template-columns: auto 1fr auto;
  justify-content: flex-start;
  margin: 0 0.2rem;
  gap: 8px;
  &:hover {
    cursor: pointer;
  }
  & b {
    font-size: 16px;
  }

  & img {
    object-fit: fill;
    width: 56px;
    height: 56px;
    border-radius: 8px;
  }
`;
function ProfileTile({ fullName, email, imageURL, role }) {
  const navigate = useNavigate();

  return (
    <ProfileTileContainer onClick={() => navigate(`/${role}/profile`)}>
      <img loading="lazy" src={imageURL} alt="Profile"></img>
      <GridContainer align="space-between" justify="flex-start" rgap="4px">
        <b>{fullName}</b>

        <LightText style={{ fontSize: "11px" }}>{email}</LightText>

        <AnchorText style={{ fontSize: "12px" }}>View Profile</AnchorText>
      </GridContainer>
    </ProfileTileContainer>
  );
}

export default ProfileTile;
