import { apiSlice } from "../apiSlice";

const instagramEndpoints = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getInstagramAccessToken: builder.mutation({
      query: (authToken) => ({
        url: `user/private/instagram/access_token?code=${authToken}`,
        method: "POST",
      }),
    }),
    getInstagramAuthorization: builder.query({
      query: () => "/user/public/instagram/authorization",
    }),
    getInstagramRefreshToken: builder.query({
      query: (data) =>
        `/user/private/instagram/long_lived_token?accessToken=${data.accessToken}&email=${data.email}`,
    }),
    getInstagramAuthStatus: builder.query({
      query: (userId) =>
        `/user/private/instagram/get/user/authorization/status?userId=${userId}`,
    }),
    getInstagramMediaById: builder.query({
      query: (data) =>
        `/user/private/instagram/media/id?accessToken=${data.access_token}&fields=${data.fields}&mediaId=${data.mediaId}&userId=${data.userId}`,
    }),
    getInstagramMedia: builder.query({
      query: (data) =>
        `/user/private/instagram/media/details?access_token=${data.access_token}&fields=${data.fields}&userId=${data.userId}`,
    }),
    getInstagramMultiMediaInfo: builder.query({
      query: (data) =>
        `/user/private/instagram/children/media/id?accessToken=${data.accessToken}&mediaId=${data.mediaId}&userId=${data.userId}`,
    }),
    getInstagramChildMediaInfo: builder.query({
      query: (data) =>
        `/user/private/instagram/child/media/id?accessToken=${data.accessToken}&mediaId=${data.mediaId}&fields=${data.fields}`,
    }),
    getInstagramBasicInfo: builder.query({
      query: (data) =>
        `/user/private/instagram/basic/information?access_token=${data.access_token}&fields=${data.fields}&userId=${data.userId}`,
    }),
    deleteInstagram: builder.mutation({
      query: (id) => ({
        url: `/user/private/instagram/remove/account?userId=${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useLazyGetInstagramAuthorizationQuery,
  useGetInstagramAccessTokenMutation,
  useLazyGetInstagramRefreshTokenQuery,
} = instagramEndpoints;
export const {
  useLazyGetInstagramMediaByIdQuery,
  useLazyGetInstagramMediaQuery,
  useLazyGetInstagramMultiMediaInfoQuery,
  useLazyGetInstagramChildMediaInfoQuery,
  useLazyGetInstagramBasicInfoQuery,
  useLazyGetInstagramAuthStatusQuery,
  useDeleteInstagramMutation,
} = instagramEndpoints;
