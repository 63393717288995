import React from "react";
import CreatorsCategory from "../helpers/CreatorsCategory";
import { Breadcrumps, ExploreContainer } from "../Explore.elements";
import { Link, Outlet, useParams, useLocation } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { useSelector } from "react-redux";

import { IoIosArrowForward } from "react-icons/io";
import { GridContainer } from "../../../Global";
import CCategoryCard from "../helpers/CCategoryCard";
import { creatorContentCategories } from "../../../utils/constants";

function ExploreCreators() {
  const location = useLocation();
  const locArray = location.pathname.split("/").slice(3);
  const { category } = useParams();

  const role = useSelector((state) => state.auth.roles[0]).name;
  const contentCategoriesData = [
    {
      title: "YouTubers",
      desc: "Create and upload videos on YouTube, covering topics like vlogs, tutorials, reviews, entertainment, and more.",
      path: `/${role}/explore/creators/YouTubers`,
    },
    {
      title: "Bloggers",
      desc: "Write and maintain blogs on various topics, including travel, lifestyle, fashion, technology, and personal experiences.",
      path: `/${role}/explore/creators/Bloggers`,
    },
    {
      title: "Podcasters",
      desc: "Produce audio content in the form of podcasts, discussing a wide array of subjects, from storytelling to interviews and educational content.",
      path: `/${role}/explore/creators/Podcasters`,
    },
    {
      title: "Twitch Streamers",
      desc: "Broadcast live video game play, creative content, or other interactive content on platforms like Twitch.",
      path: `/${role}/explore/creators/Twitch-Streamers`,
    },
    {
      title: "Social Media Influencers",
      desc: "Build a following on platforms like Instagram, TikTok, Twitter, or Facebook, often focusing on lifestyle, beauty, fitness, or specific interests.",
      path: `/${role}/explore/creators/Social-Media-Influencers`,
    },
    {
      title: "Photographers",
      desc: "Share their photography skills and portfolios on platforms like Instagram, Flickr, or personal websites.",
      path: `/${role}/explore/creators/Photographers`,
    },
    {
      title: "Graphic Designers",
      desc: "Showcase their design work, tutorials, and resources for other designers on platforms like Behance or Dribbble.",
      path: `/${role}/explore/creators/Graphic-Designers`,
    },
    {
      title: "Musicians and Singers",
      desc: "Share their music through platforms like SoundCloud, YouTube, or Spotify, and engage with their audience through live streams and social media.",
      path: `/${role}/explore/creators/Musicians-and-Singers`,
    },
    {
      title: "Cooking and Food Bloggers/Vloggers",
      desc: "Share recipes, cooking tips, and food-related content on platforms like YouTube, Instagram, or personal blogs.",
      path: `/${role}/explore/creators/Cooking-and-Food-Bloggers-Vloggers`,
    },
    {
      title: "Travel Content Creators",
      desc: "Document their travel experiences, offer travel tips, and share videos and photos on platforms like YouTube, Instagram, or travel blogs.",
      path: `/${role}/explore/creators/Travel-Content-Creators`,
    },
    {
      title: "Educational Content Creators",
      desc: "Create educational content on subjects such as science, history, mathematics, and more, often on YouTube or educational websites.",
      path: `/${role}/explore/creators/Educational-Content-Creators`,
    },
    {
      title: "DIY and Craft Enthusiasts",
      desc: "Share tutorials and project ideas related to DIY crafts, home improvement, and creative projects.",
      path: `/${role}/explore/creators/DIY-and-Craft-Enthusiasts`,
    },
    {
      title: "Fitness and Wellness Influencers",
      desc: "Share workout routines, healthy living tips, and nutritional advice on platforms like YouTube, Instagram, and fitness blogs.",
      path: `/${role}/explore/creators/Fitness-and-Wellness-Influencers`,
    },
    {
      title: "Gaming Content Creators",
      desc: "Produce video game content, including Let's Plays, reviews, tutorials, and live streams, often on platforms like YouTube and Twitch.",
      path: `/${role}/explore/creators/Gaming-Content-Creators`,
    },
    {
      title: "Book Reviewers and Literary Critics",
      desc: "Share reviews and recommendations for books, often through blogs, YouTube channels, or dedicated book-related websites.",
      path: `/${role}/explore/creators/Book-Reviewers-and-Literary-Critics`,
    },
    {
      title: "Tech Reviewers",
      desc: "Evaluate and review tech products, gadgets, and software on YouTube, blogs, or tech-focused websites.",
      path: `/${role}/explore/creators/Tech-Reviewers`,
    },
    {
      title: "Parenting Bloggers/Vloggers",
      desc: "Share parenting advice, experiences, and family-related content on platforms like Instagram, YouTube, and parenting blogs.",
      path: `/${role}/explore/creators/Parenting-Bloggers-Vloggers`,
    },
    {
      title: "Political Commentators",
      desc: "Discuss political topics, provide analysis, and share opinions on current events through podcasts, YouTube channels, or blogs.",
      path: `/${role}/explore/creators/Political-Commentators`,
    },
    {
      title: "Environmental Activists",
      desc: "Raise awareness about environmental issues, conservation, and sustainable living through various online platforms.",
      path: `/${role}/explore/creators/Environmental-Activists`,
    },
    {
      title: "Artists and Illustrators",
      desc: "Showcase their artwork, create tutorials, and engage with fans on platforms like Instagram, DeviantArt, or personal websites.",
      path: `/${role}/explore/creators/Artists-and-Illustrators`,
    },
  ];

  return (
    <ExploreContainer>
      <CreatorsCategory categories={creatorContentCategories} />
      <Breadcrumps>
        <Link to={`/${role}/explore`}>Explore</Link>
        {locArray.map((bc) => (
          <>
            <IoIosArrowForward size={16} />

            <Link to={`/${role}/explore/${bc}`}>
              {bc.charAt(0).toUpperCase() + bc.slice(1)}
            </Link>
          </>
        ))}
      </Breadcrumps>

      {category ? (
        <Outlet />
      ) : (
        <GridContainer
          align="stretch"
          columns="repeat(auto-fill,minmax(300px,1fr))"
          padding="1rem"
        >
          {contentCategoriesData.map((obj) => (
            <CCategoryCard {...obj} />
          ))}
        </GridContainer>
      )}
    </ExploreContainer>
  );
}

export default ExploreCreators;
