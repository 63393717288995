import React, { useReducer, useState, useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumps, ExploreContainer } from "../Explore.elements";
import { FaHome } from "react-icons/fa";

import { IoIosArrowForward } from "react-icons/io";
import {
  GridContainer,
  Heading,
  ResponsiveGrid,
  SmallLightText,
} from "../../../Global";
import NoData from "../../../components/ui/NoData";
import CampaignForm from "../../Influencer/Campaign/CampaignForm";
import {
  useAddCampaignMutation,
  useLazyGetCreatorsQuery,
} from "../../../api/endpoints/campaignEndpoint";
import { useGetAllCampaignsQuery } from "../../../api/endpoints/exploreEndpoints";
import { toast } from "react-toastify";

import ShimmerCard from "../../../components/ui/ShimmerCard";
import ExploreCampaignCard from "./ExploreCampaignCard";
function ExploreCampaigns() {
  const [addCampaign, addCampignResults] = useAddCampaignMutation();
  const [getCreators, creatorsResult] = useLazyGetCreatorsQuery();
  const { data: allCampaigns, isLoading: isCampaignsLoading } =
    useGetAllCampaignsQuery();

  console.log(allCampaigns);
  const campaignCreatorId = useSelector((state) => state.auth.userData.userId);
  const location = useLocation();
  const locArray = location.pathname.split("/").slice(3);

  const role = useSelector((state) => state.auth.roles[0]).name;
  const [createCampaignModal, setCreateCampaignModal] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState([]);
  const [creators, setCreators] = useState();
  const [error, setError] = useState({ field: "", message: "" });
  const [showActive, setShowActive] = useState(false);

  function openModal() {
    setCreateCampaignModal(true);
  }

  function handleCloseModal() {
    setCreateCampaignModal(false);
  }

  function callSuccess(field, message) {
    toast.success(message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  function callError(field, message) {
    setError({
      field: field,
      message: message,
    });
    toast.error(message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  const ACTIONS = {
    startDate: "handleStartDate",
    endDate: "handleEndDate",
    title: "handleTitle",
    description: "handleDescription",
    creatorsList: "handleCreatorsList",
    creators: "handlecreators",
    active: "handleActive",
    reset: "handleReset",
  };

  function reducer(state, action) {
    switch (action.type) {
      case ACTIONS.startDate:
        return { ...state, startDate: action.payload };
      case ACTIONS.endDate:
        return { ...state, endDate: action.payload };
      case ACTIONS.title:
        return { ...state, title: action.payload };
      case ACTIONS.description:
        return { ...state, description: action.payload };
      case ACTIONS.creators:
        return { ...state, creators: action.payload };
      case ACTIONS.creatorsList:
        return { ...state, creatorsList: action.payload };

      case ACTIONS.reset:
        return { ...initialState };
      default:
        return;
    }
  }

  const initialState = {
    startDate: "",
    endDate: "",
    title: "",
    campaignCreator: campaignCreatorId,
    description: "",
    creatorsList: [],
    active: true,
  };

  const [formData, dispatch] = useReducer(reducer, initialState);
  useLayoutEffect(() => {
    getCreators();
  }, []);
  useEffect(() => {
    if (creatorsResult.data) {
      setCreators(creatorsResult.data.data.content);
    }
  }, [creatorsResult]);

  async function handleAddCampaign() {
    try {
      const res = await addCampaign(formData);
      if (res.data) {
        callSuccess("Success", "Campaign added successfully");
        setSelectedCreator([]);
        dispatch({ type: ACTIONS.reset });
        setCreateCampaignModal(false);
      }

      if (res.error.data.status === 400) {
        callError("CampaignModal", res.error.data.message);
      }
    } catch (e) {
      console.log(e);
    }
  }

  if (isCampaignsLoading) {
    return <ShimmerCard />;
  }

  return (
    <ExploreContainer>
      <CampaignForm
        formData={formData}
        dispatch={dispatch}
        ACTIONS={ACTIONS}
        closeModal={handleCloseModal}
        submitFunctionLoading={addCampignResults.isLoading}
        submitFunction={handleAddCampaign}
        submitButtonTitle="Add Campaign"
        modalState={createCampaignModal}
        creators={creators}
        selectedCreator={selectedCreator}
        setSelectedCreator={setSelectedCreator}
        error={error}
      />
      <Breadcrumps>
        <Link to={`/${role}/explore`}>Explore</Link>
        {locArray.map((bc) => (
          <>
            <IoIosArrowForward size={16} />

            <Link to={`/${role}/explore/${bc}`}>
              {bc.charAt(0).toUpperCase() + bc.slice(1)}
            </Link>
          </>
        ))}
      </Breadcrumps>

      <GridContainer columns="1fr" padding="1rem" place="flex-start">
        <Heading talign="flex-start"> All Campaigns</Heading>

        {allCampaigns?.data.content.length === 0 ? (
          <NoData
            message="No Campaigns available"
            btnTitle="Start Campaign"
            btnFunction={openModal}
          />
        ) : (
          <GridContainer justify="flex-start" columns="1fr">
            {allCampaigns?.data.content.map((c) => (
              <ExploreCampaignCard
                creators={creators}
                campaign={c}
                key={c.campaignId}
              />
            ))}
          </GridContainer>
        )}
      </GridContainer>
    </ExploreContainer>
  );
}

export default ExploreCampaigns;
