import React, { useEffect, useReducer, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

//Styled Components
import {
  GridContainer,
  Heading,
  LightText,
  AnchorText,
  Heading2,
  BigHeading,
} from "../../Global";
import {
  CategorySelect,
  CategorySelectOption,
  LoginForm,
  RedirectButton,
} from "./Auth.elements";
import { ErrorText, Label } from "../../components/ui/components.elements";

//Components
import TextInput from "../../components/ui/TextInput";
import PasswordField from "../../components/ui/PasswordField";

//Assets
import logo from "../../assets/images/logo.png";

//Form Validation

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import FileInput from "../../components/ui/FileInput";
import ReactModal from "react-modal";
import { BASE_URL, creatorContentCategories } from "../../utils/constants";
import { FaArrowRight } from "react-icons/fa";
import SelectRolesField from "../../components/ui/SelectRolesField";
import CustomBtn from "../../components/ui/CustomBtn";
import CategoryListItem from "./helpers/CategoryListItem";

const customStyle = {
  content: {
    minWidth: "320px",
    maxWidth: "800px",
    padding: "2rem",
    height: "min-content",
    margin: "auto",
    borderRadius: "2rem",
  },
  overlay: {
    background: "#00000066",
  },
};

function Register() {
  const navigate = useNavigate();
  const [userRoles, setuserRoles] = useState([]);

  async function getRoles() {
    try {
      const role = await fetch(`${BASE_URL}/user/public/role/all`);
      const roles = await role.json();
      setuserRoles(roles?.data.body.content);
    } catch (e) {
      console.log(e);
    }
  }

  const [modal, setModal] = useState(false);
  const [error, setError] = useState({ field: "", message: "" });

  const [confirmPwd, setConfirmPwd] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [agreed, setAgreed] = useState(false);

  const ACTIONS = {
    firstName: "firstName",
    lastName: "lastName",
    gender: "gender",
    dateOfBirth: "dateOfBirth",
    email: "email",
    phoneNumber: "phoneNumber",
    password: "password",
    role: "role",
    file: "file",
  };
  function reducer(state, action) {
    switch (action.type) {
      case ACTIONS.firstName:
        return { ...state, firstName: action.payload };
      case ACTIONS.lastName:
        return { ...state, lastName: action.payload };
      case ACTIONS.gender:
        return { ...state, gender: action.payload };
      case ACTIONS.dateOfBirth:
        return { ...state, dateOfBirth: action.payload };
      case ACTIONS.email:
        return { ...state, email: action.payload };
      case ACTIONS.phoneNumber:
        return { ...state, phoneNumber: action.payload };
      case ACTIONS.password:
        return { ...state, password: action.payload };
      case ACTIONS.role:
        return { ...state, role: action.payload };
      case ACTIONS.file:
        return { ...state, file: action.payload };
      default:
        return;
    }
  }

  const [formData, dispatch] = useReducer(reducer, {
    firstName: "",
    lastName: "",
    gender: "",
    dateOfBirth: "",
    email: "",
    phoneNumber: "",
    password: "",
    role: "creator",
  });
  useEffect(() => {
    setError({
      field: "",
      message: "",
    });
  }, [formData, agreed]);

  function callError(field, message) {
    setError({
      field: field,
      message: message,
    });
    toast.error(message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    return false;
  }

  function validateFormdata(formData) {
    let dobDate = new Date(formData.dateOfBirth);
    //checking email validity
    function isValidEmail(email) {
      // Regular expression pattern for basic email validation
      const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

      return pattern.test(email);
    }
    // Get the current date
    let currentDate = new Date();

    // Calculate the minimum age (e.g., 18 years)
    let minAge = 18;
    let minAgeDate = new Date(
      currentDate.getFullYear() - minAge,
      currentDate.getMonth(),
      currentDate.getDate()
    );

    if (formData.firstName === "") {
      return { field: "firstName", message: "First Name is required" };
    }
    if (formData.lastName === "") {
      return { field: "lastName", message: "Last Name is required" };
    }
    if (formData.password !== confirmPwd) {
      return { field: "password", message: "Passwords don't match" };
    }
    if (!isValidEmail(formData.email)) {
      return { field: "email", message: "Email is invalid" };
    }
    if (formData.email === "") {
      return { field: "email", message: "Email is required" };
    }
    if (formData.phoneNumber === "") {
      return { field: "phoneNumber", message: "Phone Number is required" };
    }
    if (formData.phoneNumber.length !== 10) {
      return { field: "phoneNumber", message: "Phone Number is invalid" };
    }
    if (formData.dateOfBirth === "") {
      return {
        field: "dateOfBirth",
        message: "Please enter your Date of Birth",
      };
    }
    if (formData.gender === "") {
      return { field: "gender", message: "Please specify your Gender" };
    }
    if (dobDate > minAgeDate) {
      return { field: "dobDate", message: "You must be at least 18 years old" };
    }
    if (formData.password === "") {
      return { field: "password", message: "Please provide password" };
    }
    if (!agreed) {
      return {
        field: "global",
        message: "Please Agree to our Terms & Conditions",
      };
    }
    return null;
  }

  const handleFormSubmit = async (e) => {
    const validationError = validateFormdata(formData);
    if (validationError) {
      callError(validationError.field, validationError.message);
      return;
    }

    const form_data = new FormData();

    for (let key in formData) {
      form_data.append(key, formData[key]);
    }

    try {
      setIsLoading(true);
      const res = await fetch(`${BASE_URL}/user/public/signup`, {
        method: "POST",
        body: form_data,
      });
      const response = await res.json();
      if (res.status === 200) {
        if (formData.role === "brandowner") {
          navigate("/brandinfo", { state: { email: formData.email } });
        } else {
          setModal(true);
        }
      } else if (res.status === 400) {
        callError("global", response.message);
      } else if (res.status === 500) {
        callError(
          "global",
          response.data ? response.data : "Server Error! Please try again."
        );
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  const preventCopyPaste = (e) => {
    alert("Copying from password field is not allowed");
  };

  useEffect(() => {
    getRoles();
  }, []);
  function backToLogin() {
    navigate("/");
  }
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleFormSubmit();
    }
  };

  //logic to handle creator categories in Modal
  const [isSelectCategories, setSelecting] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const handleCategorySelection = (newCat) => {
    if (selectedCategories.includes(newCat)) {
      setSelectedCategories((curState) =>
        curState.filter((obj) => obj != newCat)
      );
    } else {
      setSelectedCategories((curState) => [...curState, newCat]);
    }
    console.log(newCat);
  };
  const handleCreatorCategory = async () => {
    try {
      const res = await fetch(
        `${BASE_URL}/user/public/creator/category?email=${formData.email}`,
        {
          method: "POST",
          body: { categoryNames: selectedCategories },
        }
      );
      const response = await res.json();
      if (res.status === 200) {
        setSelecting(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <LoginForm
      onKeyDown={handleKeypress}
      columns="1fr"
      style={{ bottom: "auto" }}
    >
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
      <ReactModal
        isOpen={modal}
        onRequestClose={() => setModal(false)}
        style={customStyle}
      >
        <GridContainer>
          <img src={logo} alt="Logo" style={{ margin: "auto" }}></img>

          {isSelectCategories ? (
            <>
              <BigHeading>Choose your Category</BigHeading>
              <CategorySelect>
                {creatorContentCategories.map((cat) => (
                  <CategoryListItem
                    title={cat.title}
                    active={selectedCategories.includes(
                      cat.title.toLowerCase()
                    )}
                    handleClick={() =>
                      handleCategorySelection(cat.title.toLowerCase())
                    }
                  >
                    {cat.title}
                  </CategoryListItem>
                ))}
              </CategorySelect>
              <CustomBtn size="large" onClick={handleCreatorCategory}>
                Save & Proceed
              </CustomBtn>
            </>
          ) : (
            <>
              <Heading2 talign="center">
                Verify Your email before logging in
              </Heading2>
              <LightText talign="center">
                Verification Email is sent on {formData.email}.<br></br>
              </LightText>
              <RedirectButton onClick={backToLogin}>
                I have verified <FaArrowRight />
              </RedirectButton>
            </>
          )}
        </GridContainer>
      </ReactModal>
      <Heading talign="center">Create an account</Heading>
      <LightText talign="center">Start Your 30 day free trial!</LightText>

      <GridContainer justify="flex-start" columns="1fr 1fr" gap="1rem">
        <TextInput
          name="firstName"
          error={error.field === "firstName"}
          errorMessage={error.message}
          value={formData.firstName}
          placeholder="John"
          title="First Name"
          onChange={(e) => {
            dispatch({ type: ACTIONS.firstName, payload: e.target.value });
          }}
        ></TextInput>
        <TextInput
          name="lastName"
          error={error.field === "lastName"}
          errorMessage={error.message}
          value={formData.lastName}
          placeholder=" Doe"
          title="Last Name"
          onChange={(e) => {
            dispatch({ type: ACTIONS.lastName, payload: e.target.value });
          }}
        ></TextInput>
      </GridContainer>
      <GridContainer
        columns="repeat(auto-fill,minmax(280px,1fr))"
        justify="flex-start"
      >
        <TextInput
          name="email"
          error={error.field === "email"}
          errorMessage={error.message}
          value={formData.email}
          placeholder="johndoe@gmail.com"
          title="Email"
          onChange={(e) => {
            dispatch({ type: ACTIONS.email, payload: e.target.value });
          }}
        ></TextInput>
        <TextInput
          error={error.field === "phoneNumber"}
          errorMessage={error.message}
          name="phoneNumber"
          value={formData.phoneNumber}
          minwidth="200px"
          placeholder="91 12345 67890"
          title="Phone"
          onChange={(e) => {
            dispatch({ type: ACTIONS.phoneNumber, payload: e.target.value });
          }}
        ></TextInput>
      </GridContainer>
      <GridContainer
        columns="repeat(auto-fill,minmax(200px,1fr))"
        align="flex-start"
      >
        <TextInput
          name="dateOfBirth"
          error={error.field === "dateOfBirth"}
          errorMessage={error.message}
          value={formData.dateOfBirth}
          minwidth="150px"
          placeholder="johndoe@gmail.com"
          title="Date of Birth"
          type="date"
          onChange={(e) => {
            dispatch({
              type: ACTIONS.dateOfBirth,
              payload: e.target.value,
            });
          }}
        ></TextInput>
        <SelectRolesField
          value={formData.role}
          onChange={(e) => {
            dispatch({
              type: ACTIONS.role,
              payload: e.target.value,
            });
          }}
          options={userRoles.map((obj) => obj.name)}
          title="Join as"
        ></SelectRolesField>
        <div>
          <Label>Gender</Label>

          <GridContainer
            onChange={(e) => {
              dispatch({
                type: ACTIONS.gender,
                payload: e.target.value,
              });
            }}
            justify="flex-start"
            rgap="8px"
            columns="auto auto"
          >
            <span>
              <input type="radio" name="gender" value="Male" />
              Male
            </span>
            <span>
              <input type="radio" name="gender" value="Female" />
              Female
            </span>
            {error.field === "gender" && <ErrorText>{error.message}</ErrorText>}
          </GridContainer>
        </div>
      </GridContainer>
      <FileInput
        title="Profile Picture"
        type="file"
        name="file"
        onChange={(e) => {
          dispatch({
            type: ACTIONS.file,
            payload: e.target.files[0],
          });
        }}
      />
      <GridContainer
        columns="repeat(auto-fill,minmax(280px,1fr) )"
        justify="flex-start"
      >
        <PasswordField
          name="password"
          onCut={(e) => preventCopyPaste(e)}
          onCopy={(e) => preventCopyPaste(e)}
          onPaste={(e) => preventCopyPaste(e)}
          value={formData.password}
          error={error.field === "password"}
          errorMessage={error.message}
          onChange={(e) => {
            dispatch({
              type: ACTIONS.password,
              payload: e.target.value,
            });
          }}
          placeholder="*******"
          title="Password"
        ></PasswordField>
        <PasswordField
          name="Confirmpassword"
          onCut={(e) => preventCopyPaste(e)}
          onCopy={(e) => preventCopyPaste(e)}
          onPaste={(e) => preventCopyPaste(e)}
          error={error.field === "password"}
          errorMessage={error.message}
          value={confirmPwd}
          onChange={(e) => setConfirmPwd(e.target.value)}
          placeholder="*******"
          title="Confirm Password"
        ></PasswordField>
      </GridContainer>
      {error.field === "global" && (
        <GridContainer>
          <ErrorText>{error.message}</ErrorText>
        </GridContainer>
      )}

      <GridContainer gap="4px" columns="auto 1fr">
        <input
          type="checkbox"
          value={agreed}
          onChange={(e) => setAgreed(e.target.checked)}
        ></input>
        <LightText>
          I agree to Pacescape's{" "}
          <Link to="/help/termscondition">Terms & Condition</Link>Terms &
          Condition
        </LightText>
      </GridContainer>

      <CustomBtn size="large" onClick={handleFormSubmit} loading={isLoading}>
        Sign Up{" "}
      </CustomBtn>

      <LightText talign="center">
        Been here? <AnchorText onClick={() => navigate("/")}>Login</AnchorText>
      </LightText>
    </LoginForm>
  );
}

export default Register;
