import React from "react";
import {
  ChannelCard,
  ContentCard,
  DisLikeIcon,
  EngagementMetrics,
  LikeIcon,
  SubscriberCard,
  TRow,
  VideoIcon,
  YoutubeCardContainer,
  YoutubeIcon,
  LinkContainer,
  AddIcon,
  YoutubeAnalytics,
} from "../User.elements";
import { GridContainer, LightText, LinkButton } from "../../../Global";
import { FaArrowRight } from "react-icons/fa";
import engmetrics from "../../../assets/images/social-media.png";

function YoutubeContent({ youtubeData, handleYoutubeLink, youtubeLinked }) {
  return (
    <GridContainer justify="flex-start" columns="1fr" rgap="0.7rem">
      {youtubeLinked ? (
        <YoutubeAnalytics>
          <YoutubeCardContainer>
            <EngagementMetrics>
              <img
                src={engmetrics}
                height="80px"
                style={{ margin: "auto" }}
                alt="Metrics"
              ></img>
              <h1>Engagement Metrics</h1>

              <TRow>
                <h4>View Count</h4>
                <h4>{youtubeData?.data?.viewCount}</h4>
              </TRow>
              <TRow>
                <h4>Red Views</h4>
                <h4>{youtubeData?.data?.redViews}</h4>
              </TRow>
              <hr></hr>
              <TRow>
                <h4>Comments</h4>
                <h4>{youtubeData?.data?.comments}</h4>
              </TRow>
              <TRow>
                <h4>Likes</h4>
                <h4>{youtubeData?.data?.likes}</h4>
              </TRow>
              <TRow>
                <h4>Dislikes</h4>
                <h4>{youtubeData?.data?.dislikes}</h4>
              </TRow>
              <TRow>
                <h4>Shares</h4>
                <h4>{youtubeData?.data?.shares}</h4>
              </TRow>
              <hr></hr>
              <TRow>
                <h4>Estimated Minutes Watched</h4>
                <h4>{youtubeData?.data?.estimatedMinutesWatched}</h4>
              </TRow>
              <TRow>
                <h4>Estimated Red Minutes Watched</h4>
                <h4>{youtubeData?.data?.estimatedRedMinutesWatched}</h4>
              </TRow>
              <TRow>
                <h4>Average View Duration</h4>
                <h4>{youtubeData?.data?.averageViewDuration}</h4>
              </TRow>
              <TRow>
                <h4>Average View Percentage</h4>
                <h4>{youtubeData?.data?.averageViewPercentage}</h4>
              </TRow>
            </EngagementMetrics>

            <div
              style={{
                display: "flex",
                flex: "1",
                gap: "0.7rem",
                flexWrap: "wrap",
              }}
            >
              {/* Channel Basics*/}
              <ChannelCard>
                <h2>Your Channel</h2>
                <p>{youtubeData?.data?.channelTitle}</p>

                <hr></hr>
                <p>Description</p>
                <small style={{ marginBottom: "1rem" }}>
                  {youtubeData?.data?.channelDescription}
                </small>

                <TRow>
                  <h4>Subscribers</h4>
                  <h4>{youtubeData?.data?.subscriberCount}</h4>
                </TRow>
                <TRow>
                  <h4>Total Videos</h4>
                  <h4>{youtubeData?.data?.videoCount}</h4>
                </TRow>
                <TRow>
                  <h4>Watch Time (mins)</h4>
                  <h4>{youtubeData?.data?.estimatedMinutesWatched}</h4>
                </TRow>

                <hr></hr>

                <a href={youtubeData?.data?.customURL}>
                  View Channel <FaArrowRight />
                </a>
              </ChannelCard>
              {/* Channel Analytics*/}
              <ChannelCard style={{ flex: "1", minWidth: "250px" }}>
                <h2>Channel Analytics</h2>
                <p>Current Subscribers</p>
                <h1>{youtubeData?.data?.subscriberCount}</h1>
                <p>Summary</p>
                <small style={{ marginBottom: "1rem" }}>Last 28 days</small>

                <TRow>
                  <h4>Views</h4>
                  <h4>{youtubeData?.data?.views}</h4>
                </TRow>
                <TRow>
                  <h4>Watch Time (mins)</h4>
                  <h4>{youtubeData?.data?.estimatedMinutesWatched}</h4>
                </TRow>
              </ChannelCard>
              <SubscriberCard>
                <GridContainer columns="1fr">
                  <YoutubeIcon
                    style={{
                      margin: "auto",
                      height: "56px",
                      width: "56px",
                    }}
                  />
                  <h2>Subscriber Activity</h2>
                </GridContainer>
                <TRow>
                  <h4>Subscriber Gained</h4>
                  <h4>{youtubeData?.data?.subscribersGained}</h4>
                </TRow>
                <TRow>
                  <h4>Subscriber Lost</h4>
                  <h4>{youtubeData?.data?.subscribersLost}</h4>
                </TRow>
                <TRow>
                  <h4>Current Subscriber Count</h4>
                  <h4>{youtubeData?.data?.subscriberCount}</h4>
                </TRow>
              </SubscriberCard>
              <ContentCard>
                <GridContainer columns="auto 1fr">
                  <VideoIcon />
                  <h1>Channel Statistics </h1>
                </GridContainer>
                <TRow>
                  <h4>View Count</h4>
                  <h4>{youtubeData?.data?.viewCount}</h4>
                </TRow>
                <TRow>
                  <h4>Subscriber Count</h4>
                  <h4>{youtubeData?.data?.subscriberCount}</h4>
                </TRow>
                <TRow>
                  <h4>Video Count</h4>
                  <h4>{youtubeData?.data?.videoCount}</h4>
                </TRow>

                <GridContainer columns="auto auto">
                  <span>
                    <LikeIcon />
                    {youtubeData?.data?.likes}
                  </span>
                  <span>
                    <DisLikeIcon />
                    {youtubeData?.data?.dislikes}
                  </span>
                </GridContainer>
              </ContentCard>
            </div>
          </YoutubeCardContainer>
        </YoutubeAnalytics>
      ) : (
        <LinkContainer columns="1fr">
          <YoutubeIcon
            style={{ height: "50px", width: "50px", margin: "auto" }}
          />
          <LightText talign="center">
            Please Link your Youtube Account to see your analytics.
          </LightText>
          <LinkButton onClick={handleYoutubeLink} style={{ margin: "auto" }}>
            <AddIcon /> Link
          </LinkButton>
        </LinkContainer>
      )}
    </GridContainer>
  );
}

export default YoutubeContent;
