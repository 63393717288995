import React from "react";
import {
  GridContainer,
  Heading,
  Heading2,
  LightText,
  LinkButton,
} from "../../Global";

//Styled Components
import { AccountsContainer, InstagramIcon, YoutubeIcon } from "./User.elements";

//Icons
import { FaCheck, FaPlus } from "react-icons/fa";

//Assets
import account from "../../assets/images/link-account-vector.png";

//RTK-Query
import { useSelector } from "react-redux";
import { BASE_URL } from "../../utils/constants";

function Accounts() {
  const userId = useSelector((state) => state.auth?.userData?.userId);
  const youtubeLinked = useSelector((state) => state.auth?.youtubeLinked);
  const instaToken = useSelector((state) => state.auth?.instaToken);
  
  const handleYoutubeLink = async () => {
    try {
      const customUrl = `${BASE_URL}/user/public/authorize?userId=${userId}`;
      window.location.href = customUrl;
    } catch (e) {
      console.log(e);
    }
  };
  const handleInstagramAuthorize = async () => {
    try {
      const customUrl = `${BASE_URL}/user/public/instagram/authorization`;

      window.location.href = customUrl;
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <AccountsContainer>
      <img height="200px" src={account} alt="Add Account" loading="lazy"></img>
      <div>
        <Heading>Link your first account</Heading>
        <LightText align="center">Fortunately, it's easy to link.</LightText>
      </div>

      <GridContainer columns="auto 2fr auto">
        <YoutubeIcon />
        <Heading2 talign="flex-start">Youtube</Heading2>
        {youtubeLinked ? (
          <FaCheck
            style={{
              margin: "auto",
              color: "green",
              height: "24px",
              width: "72px",
            }}
          />
        ) : (
          <LinkButton onClick={handleYoutubeLink}>
            <FaPlus /> Link
          </LinkButton>
        )}
        {/* <LinkButton onClick={handleYoutubeLink}>
          <FaPlus /> Link
        </LinkButton> */}
      </GridContainer>
      <GridContainer columns="auto 1fr min-content">
        <InstagramIcon />
        <Heading2 talign="flex-start">Instagram</Heading2>
        {instaToken ? (
          <FaCheck
            style={{
              margin: "auto",
              color: "green",
              height: "24px",
              width: "72px",
            }}
          />
        ) : (
          <LinkButton onClick={handleInstagramAuthorize}>
            <FaPlus /> Link
          </LinkButton>
        )}
      </GridContainer>
    </AccountsContainer>
  );
}

export default Accounts;
