import React from "react";
import { GridContainer, Heading, LightText } from "../../../Global";
import { BigHeading, ContentTable, PrivacyContainer } from "../Static.elements";

function CookiePolicy() {
  return (
    <GridContainer columns="1fr" style={{ width: "100vw" }}>
      <BigHeading>
        <h1>Cookie Policy</h1>
        <p>
          This Cookie Policy explains how we use cookies and similar tracking
          technologies on our website (www.pacescape.com) and related web
          applications. By using our website, you agree to the use of cookies as
          described in this policy.
        </p>
      </BigHeading>
      <PrivacyContainer>
        <GridContainer justify="flex-start" rgap="0">
          <LightText>Effective August 26,2023</LightText>

          <h1 style={{ fontWeight: "400" }}> What Are Cookies?</h1>
          <LightText maxWidth="500px">
            Cookies are small text files that are stored on your device when you
            visit a website. They help improve your browsing experience by
            remembering your preferences, analyzing site usage, and offering
            personalized content.
          </LightText>
        </GridContainer>

        <GridContainer justify="flex-end" align="flex-start">
          <ContentTable>
            <h3>Table Of Contents</h3>
            <ul>
              <li>
                <a href="#types"> Types of Cookies We Use</a>
              </li>
              <li>
                <a href="#thirdparty">Third-Party Cookies</a>
              </li>
              <li>
                <a href="#managingcookies">Managing Cookies</a>
              </li>
              <li>
                <a href="#yourconsent">Your Consent</a>
              </li>
              <li>
                <a href="#updates">Updates to this Cookie Policy</a>
              </li>
              <li>
                <a href="#contactus">Contact Us</a>
              </li>
            </ul>
          </ContentTable>
        </GridContainer>
      </PrivacyContainer>
      <hr style={{ width: "80%" }}></hr>
      <GridContainer style={{ width: "80%", margin: "auto" }} columns="1fr">
        <Heading id="types">Types of Cookies We Use</Heading>
        <LightText>
          <ul>
            <li>
              {" "}
              <b>Essential Cookies:</b> These cookies are necessary for the
              proper functioning of our website. They enable you to navigate the
              site and use its features.
            </li>
            <li>
              <b>Performance Cookies:</b>These cookies collect anonymous data
              about how visitors use our website. They help us understand which
              pages are most popular, how users interact with our site, and if
              there are any errors.
            </li>
            <li>
              <b>Functionality Cookies:</b>These cookies allow our website to
              remember choices you make (such as your username, language, or
              region) and provide enhanced features.
            </li>
            <li>
              <b>Targeting/Advertising Cookies:</b>
              We may use these cookies to deliver advertisements relevant to
              your interests. They also limit the number of times you see an ad
              and help us measure the effectiveness of our advertising
              campaigns.
            </li>
          </ul>
        </LightText>

        <Heading id="thirdparty">Third-Party Cookies</Heading>
        <LightText>
          We may use third-party services that also use cookies. These services
          help us with analytics, advertising, and other functionalities.
          Third-party cookies are subject to the respective privacy policies of
          those providers.
        </LightText>
        <Heading id="managingcookies">Managing Cookies</Heading>
        <LightText>
          Most web browsers allow you to manage your cookie preferences. You can
          typically set your browser to accept, reject, or delete cookies. Keep
          in mind that blocking or deleting certain cookies may impact your
          ability to use certain features of our website.
        </LightText>
        <Heading id="yourconsent">Your Consent</Heading>
        <LightText>
          By continuing to use our website, you consent to the use of cookies as
          outlined in this Cookie Policy.
        </LightText>
        <Heading id="updates"> Updates to this Cookie Policy</Heading>
        <LightText>
          We may update this Cookie Policy to reflect changes in our practices
          or for legal reasons. Any changes will be posted on this page with a
          revised "Last Updated" date.
        </LightText>
        <Heading id="contactus">Contact Us</Heading>
        <LightText>
          If you have any questions or concerns regarding our use of cookies,
          please contact us at{" "}
          <a href="mailto:vinayak.m@pacewisdom.com">vinayak.m@pacewisdom.com</a>
          . Thank you for using Pacescape. Your privacy and browsing experience
          matter to us.
        </LightText>
      </GridContainer>
    </GridContainer>
  );
}

export default CookiePolicy;
