export function parseCampaignTime(time) {
  let hours = time.substring(0, 2);
  const meridian = parseInt(hours) > 12 ? "PM" : "AM";
  hours = parseInt(hours) > 12 ? parseInt(hours) - 12 : hours;

  return hours + time.substring(2, 5) + " " + meridian;
}
export function parseChatTime(timestamp) {
  let [date, time] = timestamp.split("T");
  let hours = time.substring(0, 2);
  const meridian = parseInt(hours) > 12 ? "PM" : "AM";
  hours = parseInt(hours) > 12 ? parseInt(hours) - 12 : hours;
  let mins = time.substring(3, 5);
  mins = parseInt(mins) === 0 ? mins + "0" : mins;
  let parsedTime = hours + ":" + mins + " " + meridian;

  return { date, parsedTime };
}
export const convertToISO8601 = (timeString) => {
  const [time, period] = timeString.split(" ");
  const [hours, minutes] = time.split(":");
  const isPM = period?.toLowerCase() === "pm";
  const hours24 = (parseInt(hours, 10) + (isPM && hours !== "12" ? 12 : 0))
    .toString()
    .padStart(2, "0");
  return `${hours24}:${minutes}:00.000Z`;
};

export function formatDateToDaysAgo(dateString) {
  const currentDate = new Date();
  const inputDate = new Date(dateString);

  const timeDifference = currentDate - inputDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  if (daysDifference === 0) {
    return "Today";
  } else if (daysDifference === 1) {
    return "Yesterday";
  } else {
    return `${daysDifference} days ago`;
  }
}
