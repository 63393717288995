import styled from "styled-components";
import { GridContainer } from "../../Global";

export const LoginContainer = styled.div`
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    background: #efb697;
  }
`;
export const FormContainer = styled.div`
  margin: auto;
  display: grid;
  grid-template-areas: ". ." ". ." ". . .";
`;
export const LoginImage = styled.img`
  margin-left: -200px;
  margin-top: -30px;
  @media screen and (max-width: 768px) {
    background: #efb697;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    margin-top: 0;
    height: 170px;
    position: absolute;
    bottom: 80vh;
  }
`;

export const ImgWrapper = styled(GridContainer)`
  height: 100vh;
  overflow: hidden;
  justify-content: flex-end;
`;

export const LoginForm = styled(GridContainer)`
  row-gap: 1rem;
  max-width: 800px;
  margin: auto;
  @media screen and (max-width: 768px) {
    position: absolute;
    padding: 2rem;
    top: 20vh;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    border-top-right-radius: 1rem;
    border-top-left-radius: 1rem;
    box-shadow: 0px -13px 15px -7px rgba(0, 0, 0, 0.1);
  }
`;

export const LoginButton = styled.button`
  padding: 1.2rem 1.8rem;

  font-size: 18px;
  color: white;
  background-color: #3564dd;
  border: none;
  border-radius: 2rem;
  font-weight: 600;
  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: #3564ddbb;
  }
`;

export const RedirectButton = styled.a`
  padding: 1rem 1.5rem;
  border-radius: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background-color: #5d8ce6;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  border: none;
  margin: auto;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    background-color: #5d8ce699;
  }
`;

export const IRContainer = styled.div`
  margin: auto;
  max-width: 1240px;
`;

export const IRWrapper = styled(GridContainer)`
  box-shadow: 2px 2px 10px 3px #49494922;
  border-radius: 8px;
  padding: 2rem;
  width: max-content;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & img {
    margin: auto;
  }
  & h2 {
    color: #222;
    font-weight: 400;
    margin: 0 0 8px 0;
  }
`;

export const CategorySelect = styled.ul`
  padding: 0;
  list-style: none;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 600px;
  overflow: auto;
`;

export const CategorySelectOption = styled.li`
  border-radius: 2rem;
  border: 2px solid #ccc;
  padding: 0.7rem 1rem;
  transition: all 0.2s ease-in-out;
  color: ${(props) => (props.active ? "#fff" : "")};
  border-color: ${(props) => (props.active ? "#586ff5" : "")};
  background-color: ${(props) => (props.active ? "#586ff5" : "")};

  &:hover {
    cursor: pointer;

    border-color: #586ff522;

    background-color: ${(props) => (props.active ? "#586ff5dd" : "#586ff522")};
  }

  @media screen and (max-width: 768px) {
    border: 1px solid #ccc;
    padding: 0.5rem;
    font-size: 16px;
  }
`;
