import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import ProfileTile from "./ProfileTile";
import { useSelector } from "react-redux";
import { GridContainer } from "../../../Global";

function NarrowSidebar({ data, isExpanded, imageURL }) {
  const navigate = useNavigate();
  const location = useLocation();
  const handleNavigate = (item) => {
    navigate(item);
  };
  console.log("Logged");
  const userData = useSelector((state) => state.auth.userData);
  const roles = useSelector((state) => state.auth.roles);
  return (
    <nav style={{ height: "calc(100% - 61px - 0.4rem)" }}>
      <NavList>
        {data.map(({ Icon, href, label }) => (
          <NavLink
            isExpanded={isExpanded}
            active={location.pathname.includes(href)}
            key={href}
            onClick={() => handleNavigate(href)}
          >
            <Icon
              color={location.pathname.includes(href) ? "#fff" : "#494949"}
            />
            {label}
          </NavLink>
        ))}
      </NavList>
      {isExpanded && (
        <ProfileTile
          fullName={`${userData.firstName} ${userData.lastName}`}
          email={userData.email}
          imageURL={imageURL}
          role={roles[0].name}
        />
      )}
      {!isExpanded && (
        <GridContainer>
          <img
            src={imageURL}
            style={{
              borderRadius: "8px",
              margin: "0.2rem auto",
              objectFit: "cover",
            }}
            width="85px"
            height="56px"
            alt="Profile"
          ></img>
        </GridContainer>
      )}
    </nav>
  );
}

const NavList = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  margin: 0.2rem;
  padding: 0;
  height: 100%;
`;

const NavLink = styled.li`
  display: grid;
  grid-template-columns: ${(props) => (props.isExpanded ? "auto 1fr" : "1fr")};
  align-items: center;
  justify-content: center;
  gap: ${(props) => (props.isExpanded ? "1rem" : "4px")};
  color: inherit;
  text-decoration: none;
  font-size: ${(props) => (props.isExpanded ? "16px" : "11px")};
  font-weight: 500;
  border-radius: 4px;
  min-width: ${(props) => (props.isExpanded ? "200px" : "")};
  padding: 0.5rem;
  text-transform: capitalize;
  text-align: ${(props) => (props.isExpanded ? "flex-start" : "center")};
  background-color: ${(props) => (props.active ? "#5A71F7" : "")};

  color: ${(props) => (props.active ? "#fff" : "")};
  margin: 4px;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
    background-color: ${(props) => (props.active ? "" : "#eee")};
  }
`;

export default NarrowSidebar;
