import { unstable_useId } from "@mui/material";
import { apiSlice } from "../apiSlice";

const brandOwnerEndpoints = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getTopCreators: builder.query({
      query: () => `/user/private/brand-owner/dashboard`,
    }),
    getMyBrands: builder.query({
      query: (userId) =>
        `/user/private/brands/of/brand-owner/by/id?userId=${userId}`,
      providesTags: ["Brands"],
    }),

    addBrand: builder.mutation({
      query: (data) => ({
        url: `/user/private/add/brand?userId=${data.userId}`,
        method: "POST",
        body: [
          {
            name: data.name,
            description: data.description,
            active: true,
          },
        ],
      }),
      invalidatesTags: ["Brands"],
    }),
  }),
});

export const {
  useLazyGetTopCreatorsQuery,
  useAddBrandMutation,
  useGetMyBrandsQuery,
} = brandOwnerEndpoints;
