import React from "react";
import { BigHeading, PrivacyContainer } from "../Static.elements";
import {
  BoldText,
  GridContainer,
  Heading,
  LightText,
  SmallLightText,
} from "../../../Global";

function DataDeletion() {
  return (
    <GridContainer columns="1fr" style={{ width: "100vw" }}>
      <BigHeading>
        <h1>Data Deletion Instructions</h1>
      </BigHeading>
      <PrivacyContainer>
        <GridContainer justify="flex-start" rgap="1rem">
          <LightText>Effective August 26,2023</LightText>
          <h1 style={{ fontWeight: "900", margin: "0" }}>
            {" "}
            Instagram Data Deletion Request
          </h1>
          <LightText maxWidth="500px">
            Instagram Data Deletion Request is a process through which Instagram
            users can request the permanent removal of their account and
            associated data from the Instagram platform.
          </LightText>
          <Heading>Accessing Your Profile: </Heading>
          <LightText>
            {" "}
            To initiate the data deletion request, users need to log in to their
            Instagram account and access their profile page. This is typically
            done by clicking on their profile picture or username in the
            top-right corner of the Instagram app or website.
          </LightText>

          <Heading>Locating the Request Button: </Heading>
          <LightText>
            On the profile page, users need to locate and click on the "Request
            Data Deletion" or similar button. This button is usually found in
            the account settings or privacy settings section of the Instagram
            profile.
          </LightText>
          <Heading>Submitting the Request: </Heading>
          <LightText>
            After clicking the "Request Data Deletion" button, users may be
            required to provide some additional information or confirm their
            decision to delete their account and associated data. This step is
            crucial to ensure that the request is legitimate.
          </LightText>
          <Heading>Waiting for a Response: </Heading>
          <LightText>
            {" "}
            Once the request is submitted, users must wait for a response from
            Instagram's support team. The response time can vary, but Instagram
            typically processes these requests within a reasonable timeframe.
          </LightText>
          <Heading>Permanent Unlinking:</Heading>
          <LightText>
            If the request is approved, Instagram will permanently unlink the
            user's Instagram account from their Pacescape Product (or other
            associated platforms or services). This means that the user will no
            longer have access to their Instagram account.
          </LightText>
          <Heading>Data Deletion:</Heading>
          <LightText>
            In addition to unlinking the account, Instagram will also initiate
            the process of deleting all of the user's data from their servers.
            This includes photos, videos, comments, likes, and any other
            personal information associated with the account. The time it takes
            to completely remove the data may vary, but Instagram is committed
            to ensuring data privacy and security.
          </LightText>
          <Heading>Data Privacy:</Heading>
          <LightText>
            Instagram takes data privacy seriously and emphasizes the importance
            of protecting users' personal information. They have procedures in
            place to handle data deletion requests securely and in compliance
            with relevant data protection regulations.
          </LightText>
          <LightText>
            It's essential for users to carefully consider the permanent nature
            of this process, as once data is deleted, it cannot be recovered.
            Users should also ensure they have backed up any content they want
            to retain before proceeding with the data deletion request.
          </LightText>
        </GridContainer>
      </PrivacyContainer>
    </GridContainer>
  );
}

export default DataDeletion;
