import React, { useLayoutEffect, useState } from "react";
import {
  BigHeading,
  GridContainer,
  Heading,
  Heading2,
  ResponsiveGrid,
} from "../../Global";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import {
  DashboardContainer,
  YoutubeIcon,
  DashboardWrapper,
  InstagramIcon,
} from "./User.elements";

import { useLazyGetYoutubeDataQuery } from "../../api/endpoints/youtubeEndpoints";

import {
  useLazyGetInstagramBasicInfoQuery,
  useLazyGetInstagramMediaByIdQuery,
  useLazyGetInstagramMediaQuery,
} from "../../api/endpoints/instagramEndpoints";
import { BASE_URL } from "../../utils/constants";

import { useSelector } from "react-redux";
import ShimmerCard from "../../components/ui/ShimmerCard";
import InstagramContent from "./helpers/InstagramContent";
import YoutubeContent from "./helpers/YoutubeContent";
import ExploreCard from "../Explore/helpers/ExploreCard";
import { exploreCards } from "../../utils/constants";
import { ExploreCardsContainer } from "../Explore/Explore.elements";
function Dashboard() {
  const [igPostData, setIGPostData] = useState([]);

  const [getYoutubeData, youtubeData] = useLazyGetYoutubeDataQuery();

  const [getInstagramData, instagramBasicInfo] =
    useLazyGetInstagramBasicInfoQuery();
  const [getIGMedia, igMedia] = useLazyGetInstagramMediaQuery();
  const [getIGMediaDetails, igMediaDetails] =
    useLazyGetInstagramMediaByIdQuery();

  const userId = useSelector((state) => state.auth.userData?.userId);
  const name = useSelector((state) => state.auth.userData?.lastName);

  const instaDetails = useSelector((state) => state.auth.instaToken);
  const youtubeLinked = useSelector((state) => state.auth.youtubeLinked);

  const [alignment, setAlignment] = React.useState("Instagram");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  async function getPostDetails() {
    const detailsPromises = igMedia?.data?.data.data
      .slice(0, 5)
      .map(async (data) => {
        const response = await getIGMediaDetails({
          // access_token: dummyInstaToken,
          access_token: instaDetails,
          mediaId: data.id,
          fields: "id,media_type,media_url,username,timestamp",
          userId: userId,
        });
        return response?.data.data;
      });
    try {
      const postDetails = await Promise.all(detailsPromises);
      setIGPostData(postDetails);
    } catch (e) {
      console.log(e);
    }
  }

  useLayoutEffect(() => {
    if (youtubeLinked) {
      getYoutubeData(userId);
    }

    if (instaDetails) {
      getInstagramData({
        userId: userId,
        access_token: instaDetails,
        fields: "id,username,account_type",
      });
      getIGMedia({
        userId: userId,
        access_token: instaDetails,
        fields: "id,username,account_type",
      });
    }
  }, []);

  useLayoutEffect(() => {
    if (igMedia.isSuccess) {
      getPostDetails();
    }
  }, [igMedia]);

  const handleYoutubeLink = async () => {
    try {
      const customUrl = `${BASE_URL}/user/public/authorize?userId=${userId}`;
      window.location.href = customUrl;
    } catch (e) {
      console.log(e);
    }
  };
  const handleInstagramAuthorize = async () => {
    try {
      const customUrl = `${BASE_URL}/user/public/instagram/authorization`;
      window.location.href = customUrl;
    } catch (e) {
      console.log(e);
    }
  };

  if (
    youtubeData.isLoading ||
    igMedia.isLoading ||
    instagramBasicInfo.isLoading ||
    igMediaDetails.isLoading
  ) {
    return <ShimmerCard />;
  }

  return (
    <DashboardContainer>
      <GridContainer columns="1fr">
        <ResponsiveGrid columns=" 1fr auto">
          {/* {alignment === "Youtube" ? <YoutubeIcon /> : <InstagramIcon />} */}
          <BigHeading>
            Hello, <span style={{ color: "#556BEC" }}>{name}</span>
          </BigHeading>
          <ToggleButtonGroup
            color="primary"
            value={alignment}
            exclusive
            onChange={handleChange}
            aria-label="Platform"
            fullWidth
          >
            <ToggleButton value="Instagram">Instagram</ToggleButton>
            <ToggleButton value="Youtube">Youtube</ToggleButton>
          </ToggleButtonGroup>
        </ResponsiveGrid>

        <DashboardWrapper>
          {alignment === "Youtube" && (
            <YoutubeContent
              youtubeData={youtubeData}
              handleYoutubeLink={handleYoutubeLink}
              youtubeLinked={youtubeLinked}
            />
          )}

          {alignment === "Instagram" && (
            <InstagramContent
              instaDetails={instaDetails}
              instagramBasicInfo={instagramBasicInfo}
              igPostData={igPostData}
              handleInstagramAuthorize={handleInstagramAuthorize}
            />
          )}
        </DashboardWrapper>
      </GridContainer>
      <GridContainer columns="1fr" rgap="0.7rem">
        <Heading2>Explore, Pacescape</Heading2>

        <ExploreCardsContainer>
          {exploreCards.map((obj) => (
            <ExploreCard {...obj} />
          ))}
        </ExploreCardsContainer>
      </GridContainer>
    </DashboardContainer>
  );
}

export default Dashboard;
