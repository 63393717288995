import React from "react";
import { GridContainer, Heading, LightText } from "../../../Global";
import { BigHeading, ContentTable, PrivacyContainer } from "../Static.elements";
import { Link } from "react-router-dom";

function TermsCondition() {
  return (
    <GridContainer style={{ width: "100vw" }} columns="1fr">
      <BigHeading>
        <h1>Terms & Conditions</h1>
      </BigHeading>
      <PrivacyContainer>
        <GridContainer align="flex-start" justify="flex-start" columns="1fr">
          <LightText>Last Updated: August 26,2023</LightText>
          <Heading id="introduction">Introduction</Heading>
          <LightText>
            Welcome to Pacescape! These Terms & Conditions ("Agreement") govern
            your use of our platform that connects Content Creators and
            Brands/Company Owners for product promotions and sponsorships. By
            using our platform, you agree to abide by these terms.
          </LightText>

          <Heading id="definitions">Definitions</Heading>
          <LightText>
            "Platform" refers to Pacescape. "Content Creator" refers to
            individuals or entities who create and publish content.
            "Brand/Company Owner" refers to entities seeking to promote products
            and provide sponsorships.
          </LightText>
          <Heading id="registration">Registration and Accounts</Heading>
          <LightText>
            To access certain features, you may need to register for an account.
            You agree to provide accurate, current, and complete information
            during registration and to update such information to keep it
            accurate, current, and complete.
          </LightText>
          <Heading id="obligations">
            Content Creator and Brand/Company Owner Obligations
          </Heading>
          <LightText>
            <ul>
              <li>
                <b>Content Creators:</b>You agree to create and publish content
                in alignment with the guidelines provided by Brands/Company
                Owners.
              </li>
              <li>
                <b>Brand/Company Owners:</b> You agree to provide accurate
                information about your products, campaigns, and sponsorship
                opportunities.
              </li>
            </ul>
          </LightText>

          <Heading id="guidelines">Content Guidelines</Heading>
          <LightText>
            All content created and shared on the platform must adhere to the
            following guidelines:
            <ul>
              <li>
                No content that violates intellectual property rights or
                copyrights of others.
              </li>
              <li>
                No content that is offensive, abusive, defamatory, or illegal.
              </li>
              <li>
                No content that promotes hate speech, discrimination, or
                violence.
              </li>
              <li>
                No content that violates any applicable laws or regulations.
              </li>
            </ul>
          </LightText>
          <Heading id="payments">Sponsorships and Payments</Heading>
          <LightText>
            <ul>
              <li>
                {" "}
                Payment terms, amounts, and methods will be agreed upon between
                Content Creators and Brand/Company Owners.
              </li>
              <li>
                Pacescape is not responsible for any disputes regarding payments
                or sponsorships between parties.
              </li>
            </ul>
          </LightText>
          <Heading id="intellectualproperty"> Intellectual Property</Heading>
          <LightText>
            <ul>
              <li>
                Content Creators retain ownership of their original content.
              </li>
              <li>
                Brands/Company Owners retain ownership of their products and
                promotional materials.
              </li>
            </ul>
          </LightText>
          <Heading id="privacy">Privacy</Heading>
          <LightText>
            Please review our{" "}
            <Link to="/help/privacypolicy">Privacy Policy</Link>
            &nbsp; for information on how we collect, use, and protect personal
            information.
          </LightText>
          <Heading id="termination">Termination</Heading>
          <LightText>
            We reserve the right to terminate or suspend accounts that violate
            our guidelines or this Agreement.
          </LightText>
          <Heading id="liability">Limitation of Liability</Heading>
          <LightText>
            <ul>
              <li>
                {" "}
                Pacescape is not responsible for the accuracy or quality of
                content created by Content Creators.
              </li>
              <li>
                We do not guarantee the availability or success of sponsorships
                or promotions.
              </li>
            </ul>
          </LightText>
          <Heading id="indemnification">Indemnification</Heading>
          <LightText>
            Both Content Creators and Brands/Company Owners agree to indemnify
            and hold Pacescape harmless from any claims, losses, or damages
            arising out of their use of the platform.
          </LightText>
          <Heading id="modifications">Modifications to Terms</Heading>
          <LightText id="">
            We may modify these Terms & Conditions at any time. Changes will be
            posted on our platform, and your continued use constitutes
            acceptance of the modified terms.
          </LightText>
          <Heading id="law">Governing Law</Heading>
          <LightText>
            This Agreement shall be governed by and construed in accordance with
            the laws of you Jurisdiction.
          </LightText>
          <Heading id="contactus"> Contact Us</Heading>
          <LightText>
            If you have any questions or concerns regarding these Terms &
            Conditions, please contact us at{" "}
            <a href="mailto:vinayak.m@pacewisdom.com">
              vinayak.m@pacewisdom.com
            </a>
            .<br></br>
            Thank you for using Pacescape. We look forward to facilitating
            successful partnerships between Content Creators and Brands/Company
            Owners.
          </LightText>
        </GridContainer>

        <GridContainer justify="flex-end" align="flex-start">
          <ContentTable>
            <h3>Table Of Contents</h3>
            <ul>
              <li>
                <a href="#introduction"> Introduction</a>
              </li>
              <li>
                <a href="#definitions">Definitions</a>
              </li>
              <li>
                <a href="#registration">Registration and Accounts</a>
              </li>
              <li>
                <a href="#obligations">
                  Content Creator and Brand/Company Owner Obligations
                </a>
              </li>
              <li>
                <a href="#guidelines">Content Guidelines</a>
              </li>
              <li>
                <a href="#payments">Sponsorships and Payments</a>
              </li>
              <li>
                <a href="#intellectualproperty">Intellectual Property</a>
              </li>
              <li>
                <a href="#privacy">Privacy</a>
              </li>
              <li>
                <a href="#termination">Termination</a>
              </li>
              <li>
                <a href="#liability">Limitation of Liability</a>
              </li>
              <li>
                <a href="#indemnification">Indemnification</a>
              </li>
              <li>
                <a href="#modifications">Modifications to Terms</a>
              </li>
              <li>
                <a href="#law">Governing Law</a>
              </li>
              <li>
                <a href="#contactus">Contact Us</a>
              </li>
            </ul>
          </ContentTable>
        </GridContainer>
      </PrivacyContainer>
    </GridContainer>
  );
}

export default TermsCondition;
