import React from "react";
import { AvatarContainer, OnlineIcon } from "../User.elements";
import { GridContainer } from "../../../Global";
function Avatar({ image, online }) {
  return (
    <GridContainer style={{ position: "relative" }}>
      <AvatarContainer>
        {image && <img src={image} alt="Profile Avatar"></img>}
      </AvatarContainer>
      {online && <OnlineIcon />}
    </GridContainer>
  );
}

export default Avatar;
