import React, { useEffect, useRef, useState } from "react";
import {
  DesktopSidebarWrapper,
  MobileSidebarWrapper,
  Wrapper,
  MainContent,
  Overlay,
} from "./User.elements";
import { Outlet } from "react-router-dom";

import { useSelector } from "react-redux";

//Sidebar
import WideSidebar from "./helpers/WideSidebar";
import NarrowSidebar from "./helpers/NarrowSidebar";

import { getIcon } from "./helpers/navData";
import Navbar from "./helpers/Navbar";

function UserLayout({ userRole }) {
  const [isExpanded, setIsExpanded] = useState(true);

  const imageURL = useSelector((state) => state.auth.profileImage);

  const permissionData = useSelector((state) => state.auth.permissionData);

  //Inject Icon into sidebarItem

  //dynamic Sidebar
  const sidebarItems = [];

  permissionData.forEach((permission) => {
    if (permission.active) {
      const moduleDTO = permission.moduleDTO;
      const modulePermissions = {
        add: permission.add,
        delete: permission.delete,
        update: permission.update,
        view: permission.view,
      };

      // Determine if the module should be displayed in the sidebar
      if (modulePermissions.view) {
        // Create a sidebar item object and push it to the array
        const sidebarItem = {
          label: moduleDTO.name,
          Icon: getIcon(moduleDTO.name),
          href: `/${userRole}/${moduleDTO.name}`,
        };

        sidebarItems.push(sidebarItem);
      }
    }
  });

  const overlayRef = useRef();
  useEffect(() => {
    if (!isExpanded) {
      setTimeout(() => {
        overlayRef.current.style.display = "block";
      }, 0);
    } else {
      setTimeout(() => {
        overlayRef.current.style.display = "none";
      }, 500);
    }
  }, [isExpanded]);

  return (
    <Wrapper>
      {/* <Header handleClickMenu={() => setIsExpanded(!isExpanded)} /> */}
      <Navbar handleClickMenu={() => setIsExpanded(!isExpanded)} />
      <MainContent>
        <MobileSidebarWrapper>
          <Overlay
            ref={overlayRef}
            visible={!isExpanded}
            onClick={() => setIsExpanded(true)}
          ></Overlay>
          <WideSidebar
            data={sidebarItems}
            transform={!isExpanded ? "translatex(0)" : "translatex(-300px)"}
            setIsExpanded={setIsExpanded}
            isExpanded={isExpanded}
            imageURL={imageURL}
          />
        </MobileSidebarWrapper>

        <DesktopSidebarWrapper>
          <NarrowSidebar
            isExpanded={isExpanded}
            data={sidebarItems}
            imageURL={imageURL}
          />
        </DesktopSidebarWrapper>

        <Outlet />
      </MainContent>

      {/* <BottomNavbar>
        <ul>
          {sidebarItems.map((si, i) => (
            <MenuItem
              key={si.label}
              onClick={() => navigate(si.href)}
              selected={location.pathname.includes(si.label)}
            >
              <si.Icon></si.Icon>
            </MenuItem>
          ))}
        </ul>
      </BottomNavbar> */}
    </Wrapper>
  );
}

export default UserLayout;
