import React from "react";
import { BoldText, GridContainer } from "../../Global";

function LineHeading({ title }) {
  return (
    <GridContainer margin="1rem 0" columns="1fr auto 1fr">
      <hr style={{ width: "100%" }}></hr>
      <BoldText>{title}</BoldText>
      <hr style={{ width: "100%" }}></hr>
    </GridContainer>
  );
}

export default LineHeading;
