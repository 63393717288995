import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { AnchorText } from "../../../Global";
import { EllipsisIcon } from "../../Influencer/User.elements";

function BrandTile({ name, tagline, description }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <Card variant="outlined" style={{ margin: "16px auto" }}>
      <CardContent>
        <Typography variant="h5">
          {name?.substring(0, 1).toUpperCase() + name?.substring(1)}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {tagline}
        </Typography>
        {description?.length > 100 && (
          <Typography variant="body1" color="text.secondary">
            {expanded ? description : description?.substring(0, 99) + "..."}
            {!expanded && (
              <AnchorText onClick={() => setExpanded(true)}>
                show more
              </AnchorText>
            )}
            {expanded && (
              <>
                <br></br>
                <AnchorText onClick={() => setExpanded(false)}>
                  show less
                </AnchorText>
              </>
            )}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}

export default BrandTile;
