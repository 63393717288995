import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ContactIcon from "@mui/icons-material/ChatBubble";
import { GridContainer } from "../../../Global";
import {
  useAddFavBrandMutation,
  useRemoveFavBrandMutation,
} from "../../../api/endpoints/exploreEndpoints";
import { useSelector } from "react-redux";

const BrandCard = ({ id, name, description, favorites, setFavBrands }) => {
  const userId = useSelector((state) => state.auth.userData.userId);

  const [addBrandFav, { isLoading: isAddFavCreatorLoading }] =
    useAddFavBrandMutation();
  const [toggleBrandFav, { isLoading: isRemoveFavBrandLoading }] =
    useRemoveFavBrandMutation();

  //handle adding/removing of favorite creator
  const handleFavClick = async () => {
    if (favorites.includes(id)) {
      try {
        const res = await toggleBrandFav({
          brandId: id,
          userId: userId,
          flag: false,
        });
        if (res.data) {
          setFavBrands((curFav) => curFav.filter((cf) => cf !== id));
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        const res = await addBrandFav({
          brandId: id,
          userId: userId,
        });

        if (res.error.status === 400) {
          const res = await toggleBrandFav({
            brandId: id,
            userId: userId,
            flag: true,
          });
          if (res.data) {
            setFavBrands((curFav) => [...curFav, id]);
          }
        }
        if (res.data) {
          setFavBrands((curFav) => [...curFav, id]);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };
  return (
    <Card variant="outlined" style={{ maxWidth: 400, margin: "16px auto" }}>
      <CardContent>
        <GridContainer columns="1fr auto">
          <Typography variant="h5" component="div">
            {name?.substring(0, 1).toUpperCase() + name?.substring(1)}
          </Typography>
          <IconButton onClick={handleFavClick} aria-label="Add to favorites">
            <FavoriteIcon
              htmlColor={favorites.includes(id) ? "#FF0000" : "primary"}
            />
          </IconButton>
        </GridContainer>

        <Typography color="text.secondary" gutterBottom>
          {description}
        </Typography>
        {/* <Typography variant="body2" color="text.secondary">
          <strong>Brand Categories:</strong> {categories.join(", ")}
        </Typography> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "16px",
          }}
        >
          {/* <IconButton aria-label="Contact brand owner">
            <ContactIcon color="primary" />
          </IconButton> */}
        </div>
      </CardContent>
    </Card>
  );
};

export default BrandCard;
