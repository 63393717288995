import { apiSlice } from "../apiSlice";

const exploreEndpoint = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCreatorsByCategory: builder.query({
      query: (category) =>
        `/user/private/creators/bycategory?categoryName=${category}`,
    }),
    getAllBrandowner: builder.query({
      query: () =>
        `/user/private/brandowner?roleId=5ac49983-a559-4b8b-8e13-ffabede8b26c`,
      providesTags: ["AllBrandOwners"],
    }),
    getAllBrands: builder.query({
      query: () =>
        `/user/private/brand/all?page=0&size=2147483647&sort=id&order=DESCENDING'`,
      providesTags: ["Brands"],
    }),
    getAllCampaigns: builder.query({
      query: () => `/user/private/getallcampaign`,
      providesTags: ["AllCampaigns"],
    }),

    getFavoriteCreators: builder.query({
      query: (userId) =>
        `/user/private/fetch/favorite/creator/by/user/id?userId=${userId}`,
      providesTags: ["FavCreators"],
    }),
    getFavoriteBrands: builder.query({
      query: (userId) =>
        `/user/private/fetch/favorite/brand/by/user/id?userId=${userId}`,
      providesTags: ["FavBrands"],
    }),
    addFavBrand: builder.mutation({
      query: (data) => ({
        url: `/user/private/add/favorite/brand`,
        method: "POST",
        body: {
          brandId: data.brandId,
          userId: data.userId,
          active: data.active,
        },
      }),
      invalidatesTags: ["FavBrands"],
    }),
    addFavCreator: builder.mutation({
      query: (data) => ({
        url: `/user/private/add/favorite/creator`,
        method: "POST",
        body: {
          creatorId: data.creatorId,
          userId: data.userId,
          active: true,
        },
      }),
      invalidatesTags: ["FavCreators"],
    }),
    removeFavCreator: builder.mutation({
      query: (data) => ({
        url: `/user/private/activate/or/deactivate/favorite-creator?userId=${data.userId}&creatorId=${data.creatorId}&flag=${data.flag}`,
        method: "PUT",
      }),
      invalidatesTags: ["FavCreators"],
    }),
    removeFavBrand: builder.mutation({
      query: (data) => ({
        url: `/user/private/activate/or/deactivate/favorite-brand?brandId=${data.brandId}&userId=${data.userId}&flag=${data.flag}`,
        method: "PUT",
      }),
      invalidatesTags: ["FavBrands"],
    }),
  }),
});

export const {
  useGetCreatorsByCategoryQuery,
  useGetAllBrandownerQuery,
  useGetAllBrandsQuery,
  useGetAllCampaignsQuery,
  useGetFavoriteBrandsQuery,
  useGetFavoriteCreatorsQuery,
  useAddFavBrandMutation,
  useAddFavCreatorMutation,
  useRemoveFavCreatorMutation,
  useRemoveFavBrandMutation,
} = exploreEndpoint;
