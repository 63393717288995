import { Card, CardContent, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { AvatarImage, GridContainer } from "../../../Global";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import { useSelector } from "react-redux";
import { BASE_URL } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import {
  useAddFavCreatorMutation,
  useRemoveFavCreatorMutation,
} from "../../../api/endpoints/exploreEndpoints";
function CreatorTile({
  firstName,
  lastName,
  userId: creatorId,
  email,
  favorites,
  setFavorites,
}) {
  const accessToken = useSelector((state) => state.auth?.accessToken);
  const navigate = useNavigate();
  const role = useSelector((state) => state.auth.roles[0]).name;
  const userId = useSelector((state) => state.auth.userData.userId);

  const [addCreatorFav, { isLoading: isAddFavCreatorLoading }] =
    useAddFavCreatorMutation();
  const [toggleCreatorFav, { isLoading: isRemoveFavCreatorLoading }] =
    useRemoveFavCreatorMutation();

  const handleFavClick = async () => {
    if (favorites.includes(email)) {
      try {
        const res = await toggleCreatorFav({
          userId: userId,
          creatorId: creatorId,
          flag: false,
        });
        if (res.data) {
          setFavorites((curFav) => curFav.filter((cf) => cf !== email));
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        const res = await addCreatorFav({
          creatorId: creatorId,
          userId: userId,
        });
        if (res.error.status === 400) {
          const res = await toggleCreatorFav({
            userId: userId,
            creatorId: creatorId,
            flag: true,
          });
          if (res.data) {
            setFavorites((curFav) => [...curFav, email]);
          }
        }
        if (res.data) {
          setFavorites((curFav) => [...curFav, email]);
        }
      } catch (e) {
        console.log(e);
      }
    }
  };

  //get profile pic on component mount
  const [imageURL, setImageURL] = useState();
  async function getProfilePic() {
    try {
      const res = await fetch(
        `${BASE_URL}/user/private/profile/pic?email=${email}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (res.status === 200) {
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImageURL(imageObjectURL);
      }
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    getProfilePic();
  }, []);

  const handleChatNavigate = () => {
    navigate(`/${role}/chat/${creatorId}`);
  };
  return (
    <Card variant="outlined" style={{ margin: "0 auto" }}>
      <GridContainer columns="auto 1fr auto" padding="0.7rem">
        <AvatarImage loading="lazy" src={imageURL} />
        <Grid item>
          <Typography variant="h6">{firstName + " " + lastName}</Typography>
          <Typography variant="body2" color="text.secondary">
            {email}
          </Typography>
        </Grid>

        <GridContainer columns="1fr 1fr">
          <IconButton onClick={handleFavClick} aria-label="Add to favorites">
            <FavoriteIcon
              htmlColor={favorites.includes(email) ? "#FF0000" : "primary"}
            />
          </IconButton>
          <IconButton onClick={handleChatNavigate} aria-label="Contact Creator">
            <ChatBubbleIcon />
          </IconButton>
        </GridContainer>
      </GridContainer>
    </Card>
  );
}

export default CreatorTile;
