import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Header from "./Header";
import ProfileTile from "./ProfileTile";
import { useSelector } from "react-redux";

function Sidebar({ data, transform, setIsExpanded, isExpanded, imageURL }) {
  const navigate = useNavigate();
  const location = useLocation();
  const handleNavigate = (item) => {
    navigate(item);
    setIsExpanded(true);
  };
  const userData = useSelector((state) => state.auth.userData);
  const roles = useSelector((state) => state.auth.roles);

  return (
    <WideSidebar transform={transform}>
      <Header handleClickMenu={() => setIsExpanded(!isExpanded)} />
      <NavList>
        {data.map(({ Icon, href, label }) => (
          <NavLink
            key={href}
            active={location.pathname.includes(href)}
            onClick={() => handleNavigate(href)}
          >
            <Icon
              color={location.pathname.includes(href) ? "#fff" : "#494949"}
              size={28}
            />
            {label}
          </NavLink>
        ))}
      </NavList>
      <ProfileTile
        fullName={`${userData.firstName} ${userData.lastName}`}
        email={userData.email}
        imageURL={imageURL}
        role={roles[0].name}
      />
    </WideSidebar>
  );
}

const WideSidebar = styled.nav`
  position: fixed;
  top: 0;
  background-color: #fff;
  transform: ${(props) => props.transform};
  transition: all 0.2s ease-in-out;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 10;

  & > div {
    /* position: fixed; */
    margin-top: auto;
    bottom: 0;
  }
`;

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
  min-width: 220px;

  & > li {
    transition: all 0.2s ease-in-out;
    padding: 1rem;
    border-radius: 8px;
  }
`;

const NavLink = styled.li`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 16px;
  color: inherit;
  text-decoration: none;
  font-size: 1rem;
  border-radius: 4px;
  font-weight: 500;
  background-color: ${(props) => (props.active ? "#666" : "")};
  color: ${(props) => (props.active ? "#fff" : "")};
  margin: 4px;
  text-transform: capitalize;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

export default Sidebar;
