import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import { GlobalStyle } from "./Global";
//Authentication
import Register from "./pages/Authentication/Register";
import Login from "./pages/Authentication/Login";
import AuthLayout from "./pages/Authentication/AuthLayout";
import VerificationStatus from "./components/ui/VerificationStatus";
import InstagramRedirect from "./pages/Authentication/InstagramRedirect";
import PasswordResetRedirect from "./components/ui/PasswordResetRedirect";
import YoutubeRedirect from "./pages/Authentication/YoutubeRedirect";

//Base Layouts
import UserLayout from "./pages/Influencer/UserLayout";

//Influencer Page
import Dashboard from "./pages/Influencer/Dashboard";
import Accounts from "./pages/Influencer/Accounts";
import Analytics from "./pages/Influencer/Analytics";
import Campaign from "./pages/Influencer/Campaign/Campaign";

import Messages from "./pages/Messages/Messages";

//Brand Owner Pages
import BrandInfo from "./pages/Authentication/BrandInfo";
import BrandDashboard from "./pages/BrandOwner/BrandDashboard";
import BrandAnalytics from "./pages/BrandOwner/BrandAnalytics";

//Middleware Components
import PersistData from "./utils/PersistData";

import RequireAuth from "./utils/RequireAuth";

//Static Pages
import NotFound from "./pages/Static/NotFound";
import Unauthorized from "./pages/Static/Unauthorized";
import PrivacyPolicy from "./pages/Static/Help/PrivacyPolicy";
import HelpLayout from "./pages/Static/Help/HelpLayout";
import CookiePolicy from "./pages/Static/Help/CookiePolicy";
import TermsCondition from "./pages/Static/Help/TermsCondition";
import CreatorProfile from "./pages/Influencer/CreatorProfile";

import ExploreBrands from "./pages/Explore/Brands/ExploreBrands";
import ExploreCampaigns from "./pages/Explore/Campaign/ExploreCampaigns";
import ExploreCreators from "./pages/Explore/Creators/ExploreCreators";
import CreatorCategoryDetails from "./pages/Explore/Creators/CreatorCategoryDetails";
import Explore from "./pages/Explore/Explore";
import DataDeletion from "./pages/Static/Help/DataDeletion";
import AboutPage from "./pages/Static/Help/AboutPage";
import BrandownerProfile from "./pages/BrandOwner/BrandownerProfile";
import BrandCategoryDetails from "./pages/Explore/Brands/BrandCategoryDetails";
import { ToastContainer } from "react-toastify";

//Brand Owner Routes

function App() {
  return (
    <div className="App">
      <GlobalStyle />
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
      <Router>
        <Routes>
          {/* Auth routes */}
          <Route path="*" element={<NotFound />}></Route>
          <Route path="/unauthorized" element={<Unauthorized />}></Route>
          <Route path="/verified" element={<VerificationStatus />}></Route>
          <Route
            path="/password/reset"
            element={<PasswordResetRedirect />}
          ></Route>
          <Route path="/" element={<AuthLayout />}>
            <Route path="/" element={<Login />}></Route>
            <Route path="/register" element={<Register />}></Route>
            <Route path="/brandinfo" element={<BrandInfo />}></Route>
          </Route>

          {/* User Routes */}
          <Route element={<PersistData />}>
            {/* Creator Routes */}
            <Route element={<RequireAuth allowedRoles={["creator"]} />}>
              <Route
                path="/creator"
                element={<UserLayout userRole="creator" />}
              >
                <Route
                  path="/creator/dashboard"
                  element={<Dashboard />}
                ></Route>
                <Route path="/creator/accounts" element={<Accounts />}></Route>

                <Route path="/creator/chat" element={<Messages />}>
                  <Route
                    path="/creator/chat/:id"
                    element={<Messages />}
                  ></Route>
                  <Route
                    path="/creator/chat/room/:id"
                    element={<Messages />}
                  ></Route>
                </Route>
                <Route
                  path="/creator/analytics"
                  element={<Analytics />}
                ></Route>
                <Route path="/creator/campaign" element={<Campaign />}></Route>
                <Route
                  path="/creator/profile"
                  element={<CreatorProfile />}
                ></Route>

                {/* Creator Explore */}
                <Route path="/creator/explore" element={<Explore />}>
                  {/* Exploring Brands */}
                  <Route
                    path="/creator/explore/brands"
                    element={<ExploreBrands />}
                  >
                    <Route
                      path="/creator/explore/brands/:category"
                      element={<BrandCategoryDetails />}
                    ></Route>
                  </Route>
                  {/* Explore Campaigns */}
                  <Route
                    path="/creator/explore/campaigns"
                    element={<ExploreCampaigns />}
                  ></Route>

                  {/* Exploring Creators */}
                  <Route
                    path="/creator/explore/creators"
                    element={<ExploreCreators />}
                  >
                    <Route
                      path="/creator/explore/creators/:category"
                      element={<CreatorCategoryDetails />}
                    ></Route>
                  </Route>
                </Route>
              </Route>
            </Route>

            {/* Brand Owner Routes */}
            <Route element={<RequireAuth allowedRoles={["brandowner"]} />}>
              <Route
                path="/brandowner"
                element={<UserLayout userRole="brandowner" />}
              >
                <Route
                  path="/brandowner/dashboard"
                  element={<BrandDashboard />}
                ></Route>
                <Route
                  path="/brandowner/accounts"
                  element={<Accounts />}
                ></Route>

                <Route path="/brandowner/chat" element={<Messages />}>
                  <Route
                    path="/brandowner/chat/:id"
                    element={<Messages />}
                  ></Route>
                  <Route
                    path="/brandowner/chat/room/:id"
                    element={<Messages />}
                  ></Route>
                </Route>
                <Route
                  path="/brandowner/analytics"
                  element={<BrandAnalytics />}
                ></Route>
                <Route
                  path="/brandowner/campaign"
                  element={<Campaign />}
                ></Route>
                <Route
                  path="/brandowner/profile"
                  element={<BrandownerProfile padding="0.7rem" />}
                ></Route>
                {/* Creator Explore */}
                <Route path="/brandowner/explore" element={<Explore />}>
                  {/* Exploring Brands */}
                  <Route
                    path="/brandowner/explore/brands"
                    element={<ExploreBrands />}
                  ></Route>
                  {/* Explore Campaigns */}
                  <Route
                    path="/brandowner/explore/campaigns"
                    element={<ExploreCampaigns />}
                  ></Route>

                  {/* Exploring Creators */}
                  <Route
                    path="/brandowner/explore/creators"
                    element={<ExploreCreators />}
                  >
                    <Route
                      path="/brandowner/explore/creators/:category"
                      element={<CreatorCategoryDetails />}
                    ></Route>
                  </Route>
                </Route>
                <Route
                  path="/brandowner/explore/brands"
                  element={<ExploreBrands />}
                >
                  <Route
                    path="/brandowner/explore/brands/:category"
                    element={<BrandCategoryDetails />}
                  ></Route>
                </Route>
                <Route
                  path="/brandowner/explore/campaigns"
                  element={<ExploreCampaigns />}
                ></Route>
                <Route
                  path="/brandowner/explore/creators"
                  element={<ExploreCreators />}
                >
                  <Route
                    path="/brandowner/explore/creators/:category"
                    element={<CreatorCategoryDetails />}
                  ></Route>
                </Route>
              </Route>
            </Route>
            <Route
              path="/instagram/verified"
              element={<InstagramRedirect />}
            ></Route>
            <Route
              path="/youtube/verified"
              element={<YoutubeRedirect />}
            ></Route>
          </Route>

          <Route path="/help" element={<HelpLayout />}>
            <Route
              path="/help/privacypolicy"
              element={<PrivacyPolicy />}
            ></Route>
            <Route path="/help/cookiepolicy" element={<CookiePolicy />}></Route>
            <Route
              path="/help/termscondition"
              element={<TermsCondition />}
            ></Route>
            <Route
              path="/help/deletionrequest"
              element={<DataDeletion />}
            ></Route>
            <Route path="/help/about" element={<AboutPage />}></Route>
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
