import React from "react";
import { GridContainer, Heading, Heading2, LightText } from "../../../Global";
import { BigHeading, PrivacyContainer } from "../Static.elements";

function AboutPage() {
  return (
    <GridContainer columns="1fr" style={{ width: "100vw" }}>
      <BigHeading>
        <h1>About, Pacescape</h1>
        <p>
          Pacescape is a comprehensive web application designed to bridge the
          gap between content creators and brand owners in the dynamic world of
          digital marketing. It offers a wide range of features and
          functionalities to facilitate collaboration, streamline operations,
          and ensure authenticity within the influencer marketing ecosystem.
        </p>
      </BigHeading>
      <PrivacyContainer>
        <GridContainer columns="1fr" rgap="8px">
          <Heading margin="1rem 0">Key Features and Functionalities:</Heading>

          <Heading2>Content Creator and Brand Owner Connection: </Heading2>
          <LightText>
            Pacescape serves as a virtual meeting place, connecting content
            creators and brand owners, fostering partnerships, and enabling
            mutually beneficial collaborations.
          </LightText>

          <Heading2>Authenticity Verification: </Heading2>
          <LightText>
            Pacescape integrates with social media platforms such as Instagram
            and YouTube to validate the authenticity of content creators. This
            ensures that only genuine and credible influencers are part of the
            network.
          </LightText>

          <Heading2>Messaging Platform: </Heading2>
          <LightText>
            {" "}
            The application includes a robust messaging system that allows brand
            owners and content creators to communicate seamlessly. This
            facilitates discussions, negotiations, and planning for campaigns
            and partnerships.
          </LightText>

          <Heading2>Campaign Management: </Heading2>
          <LightText>
            Pacescape provides a powerful campaign management system. Brand
            owners can create, schedule, and manage campaigns, defining their
            goals, target audiences, and content requirements.
          </LightText>

          <Heading2>Participation in Campaigns:</Heading2>
          <LightText>
            Content creators can browse and apply to participate in campaigns
            that align with their niche and interests. They can submit proposals
            and showcase their portfolios to brand owners.
          </LightText>

          <Heading2>Profile Management:</Heading2>
          <LightText>
            Both content creators and brand owners have the ability to manage
            their profiles, including updating information, showcasing past
            collaborations, and highlighting their unique offerings.
          </LightText>

          <Heading2>Authentication: </Heading2>
          <LightText>
            Pacescape employs robust authentication mechanisms to ensure the
            security and privacy of user data. This includes secure login
            methods and encryption of sensitive information.
          </LightText>

          <Heading2>Brand Registration:</Heading2>
          <LightText>
            Brand owners can easily register their brands on the platform,
            providing essential details for identity verification and campaign
            management.
          </LightText>

          <Heading2>Role-Based Access: </Heading2>
          <LightText>
            Pacescape supports two distinct roles: "Brand Owners" and "Content
            Creators." Each role is tailored to the specific needs and
            responsibilities of the users.
          </LightText>

          <Heading margin="1rem 0"> Benefits:</Heading>

          <Heading2>Efficient Collaboration: </Heading2>
          <LightText>
            Pacescape simplifies the process of finding the right influencer for
            a brand's campaign, making collaborations efficient and productive.
          </LightText>

          <Heading2>Verified Authenticity: </Heading2>
          <LightText>
            With built-in social media verification, Pacescape ensures that
            content creators are genuine, reducing the risk of fraudulent
            engagements.
          </LightText>

          <Heading2>Streamlined Communication: </Heading2>
          <LightText>
            The messaging system promotes effective communication and
            negotiation between brands and influencers, eliminating
            communication barriers.
          </LightText>

          <Heading2>Comprehensive Campaign Management: </Heading2>
          <LightText>
            Brands can effortlessly create and manage campaigns, optimizing
            their influencer marketing strategies.
          </LightText>

          <Heading2>Increased Visibility:</Heading2>
          <LightText>
            Content creators gain access to a wider network of brand
            opportunities, helping them expand their reach and monetize their
            content effectively.
          </LightText>

          <LightText>
            Pacescape is the ultimate solution for anyone looking to excel in
            the world of influencer marketing, offering a secure, efficient, and
            authentic platform that facilitates fruitful collaborations between
            content creators and brand owners.
          </LightText>
        </GridContainer>
      </PrivacyContainer>
    </GridContainer>
  );
}

export default AboutPage;
