import React from "react";
import styled from "styled-components";
import { SmallLoader } from "./components.elements";

const Button = styled.button`
  position: relative;
  padding: 0.7rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 14px;
  color: white;
  background-color: #586ff5;
  border: none;
  border-radius: 4px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
  gap: 4px;
  &:hover {
    cursor: ${(props) => (props.disabled ? "" : "pointer")};
    opacity: ${(props) => (props.disabled ? "" : "0.9")};
  }

  &:disabled {
    opacity: 0.7;
  }
`;

const LargeButton = styled(Button)`
  padding: 1.2rem 1.8rem;
  font-size: 18px;
  border-radius: 2rem;
  font-weight: 600;
  min-width: 200px;
`;

function CustomBtn({ size, onClick, children, loading }) {
  if (size === "large") {
    return (
      <LargeButton onClick={onClick} disabled={loading}>
        {children}
      </LargeButton>
    );
  } else {
    return (
      <Button onClick={onClick} disabled={loading}>
        {children}
      </Button>
    );
  }
}

export default CustomBtn;
