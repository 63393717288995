import React, { useState } from "react";
import { CategorySelectOption } from "../Auth.elements";

function CategoryListItem({ active, handleClick, title }) {
  return (
    <CategorySelectOption onClick={handleClick} active={active}>
      {title}
    </CategorySelectOption>
  );
}

export default CategoryListItem;
