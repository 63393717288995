import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  AddIcon,
  CampaignIcon,
  ChatAvatar,
  ChatCard,
  MessageListContainer,
  MessageSearchField,
  NewChatContainer,
  ShadowDiv,
} from "../Influencer/User.elements";
import {
  BackArrow,
  BoldText,
  GridContainer,
  ScrollContainer,
  SmallLightText,
} from "../../Global";
import { Avatar } from "@mui/material";
import logo from "../../assets/images/logo.png";

import {
  useGetAllChatUsersQuery,
  useGetReceiverChatListQuery,
  useGetSenderChatListQuery,
} from "../../api/endpoints/chatEndpoints";
import { BASE_URL } from "../../utils/constants";
import ShimmerCard from "../../components/ui/ShimmerCard";

function MessageList({
  selectedUser,
  setSelectedUser,
  selectedRoom,
  setSelectedRoom,
  campaignList,
  setTab,
}) {
  const email = useSelector((state) => state.auth.userData.email);
  const { id } = useParams();

  const {
    data: allUsers,
    isLoading: isAllUsersLoading,
    isSuccess: isAllUsersSuccess,
  } = useGetAllChatUsersQuery();

  const {
    data: senderChatList,
    isLoading: isSChatsLoading,
    isSuccess: isSChatsSuccess,
  } = useGetSenderChatListQuery(email);
  const {
    data: receiverChatList,
    isLoading: isRChatsLoading,
    isSuccess: isRChatsSuccess,
  } = useGetReceiverChatListQuery(email);

  const searchRef = useRef(null);
  const accessToken = useSelector((state) => state.auth.accessToken);

  const [imageURL, setImageURL] = useState([{ email: "", image: "" }]);
  const [usersList, setUsersList] = useState([]);
  const [newUsersList, setNewUsersList] = useState([]);
  const [newChat, setNewChat] = useState(false);

  const navigate = useNavigate();
  const role = useSelector((state) => state.auth.roles);
  const [searchText, setSearchText] = useState("");
  const [newChatSearchText, setNewChatSearchText] = useState("");
  const [scrollTop, scrollProps] = useScrollTop();

  useEffect(() => {
    if (id && allUsers) {
      const selected = allUsers?.data?.content.find(
        (obj) => obj?.userId === id
      );
      //if user doesnt exists in the message list append the user
      if (!usersList.find((obj) => obj?.userId === selected?.userId)) {
        setUsersList((curList) => [...curList, selected]);
      }
      async function setUser() {
        try {
          const res = await fetch(
            `${BASE_URL}/user/private/profile/pic?email=${selected.email}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            }
          );
          let imageObjectURL = "";
          if (res.ok) {
            const imageBlob = await res.blob();
            imageObjectURL = URL.createObjectURL(imageBlob);
          }
          setTab("DM");
          setSelectedUser({
            id: selected?.userId,
            userName: selected.email,
            firstName: selected.firstName,
            lastName: selected.lastName,
            image: imageObjectURL,
          });
        } catch (e) {
          console.log(e);
        }
      }
      setUser();
    }
  }, [allUsers]);

  const handleClick = (id, email, firstName, lastName) => {
    setTab("DM");
    navigate(`/${role[0].name}/chat/${id}`);
    console.log(email);
    let image = getImage(email);

    setSelectedUser({
      id: id,
      userName: email,
      firstName,
      lastName,
      image: image,
    });
  };

  const handleRoomClick = (id, title) => {
    setTab("CHATROOM");
    navigate(`/${role[0].name}/chat/room/${id}`);
    setSelectedRoom({ id: id, title: title });
  };

  function handleSearchChange(e) {
    setSearchText(e.target.value);
  }
  function handleNewChatSearchChange(e) {
    setNewChatSearchText(e.target.value);
  }

  useEffect(() => {
    async function handleChats() {
      try {
        if (isAllUsersSuccess && isSChatsSuccess && isRChatsSuccess) {
          console.log("cl", usersList);
          console.log("el", senderChatList.data.concat(receiverChatList.data));
          let emailList = senderChatList.data
            .map((obj) => obj.senderName)
            .concat(receiverChatList.data.map((obj) => obj.receiverName));
          let chatsList = allUsers?.data?.content.filter((user) =>
            emailList.includes(user.email)
          );

          chatsList = chatsList.map((obj) => {
            return {
              ...obj,
              latestChat: "",
              chatTime: "",
            };
          });
          setNewUsersList(allUsers?.data?.content);

          if (!usersList.find((obj) => obj?.userId === id)) {
            setUsersList((curChats) =>
              curChats ? [...curChats, ...chatsList] : chatsList
            );
            return;
          }
          setUsersList(chatsList);
        }
      } catch (e) {
        console.log(e);
      }
    }
    handleChats();
  }, [allUsers, isAllUsersSuccess, isSChatsSuccess, isRChatsSuccess]);

  //message list search Bar
  useEffect(() => {
    if (searchText) {
      let chatsList = allUsers?.data?.content.filter((user) =>
        usersList?.map((obj) => obj.email)?.includes(user.email)
      );

      const cd = chatsList.filter(
        (user) =>
          user.email.includes(searchText) || user.firstName.includes(searchText)
      );
      setUsersList(cd);
    }
  }, [searchText]);

  //new message list search bar

  useEffect(() => {
    if (newChatSearchText) {
      let chatsList = allUsers?.data?.content;

      const cd = chatsList?.filter(
        (user) =>
          user.email.includes(newChatSearchText) ||
          user.firstName.includes(newChatSearchText)
      );
      setNewUsersList(cd);
    }
  }, [newChatSearchText]);

  //get Profile pic
  useEffect(() => {
    let chatsList = allUsers?.data?.content.filter((user) =>
      usersList?.map((obj) => obj?.email)?.includes(user?.email)
    );
    if (chatsList) {
      chatsList.map((obj) => getProfilePic(obj.email));
    }

    async function getProfilePic(email) {
      try {
        const res = await fetch(
          `${BASE_URL}/user/private/profile/pic?email=${email}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );
        if (res.ok) {
          const imageBlob = await res.blob();
          const imageObjectURL = URL.createObjectURL(imageBlob);
          setImageURL((curState) => [
            ...curState,
            { email: email, image: imageObjectURL },
          ]);
        } else {
          setImageURL((curState) => [...curState, { email: email, image: "" }]);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [allUsers, usersList]);

  function getImage(email) {
    let image = imageURL.find((img) => img.email === email)?.image;
    return image;
  }

  if (isAllUsersLoading || isRChatsLoading || isSChatsLoading) {
    return <ShimmerCard />;
  } else {
    return (
      <MessageListContainer
        isSelected={selectedUser || selectedRoom}
        columns="1fr"
        rgap="0"
      >
        {newChat ? (
          <>
            <GridContainer columns="auto 1fr" gap="0" margin="8px 0">
              <BackArrow onClick={() => setNewChat(false)} />
              <MessageSearchField
                placeholder="Search new Users"
                value={newChatSearchText}
                onChange={handleNewChatSearchChange}
              ></MessageSearchField>
            </GridContainer>

            <ScrollContainer {...scrollProps} maxHeight="100%">
              <ShadowDiv
                style={{
                  boxShadow:
                    scrollTop > 0
                      ? "inset 0 12px 5px -5px rgb(0 0 0 / 0.4)"
                      : "none",
                }}
              ></ShadowDiv>

              {newUsersList.map((cht, i) => (
                <ChatCard
                  key={cht?.userId}
                  onClick={() =>
                    handleClick(
                      cht?.userId,
                      cht?.email,
                      cht?.firstName,
                      cht?.lastName
                    )
                  }
                  selected={selectedUser?.userName === cht?.email}
                >
                  <Avatar image={logo} />
                  <GridContainer justify="flex-start" rgap="4px">
                    <BoldText>{cht?.firstName + " " + cht?.lastName}</BoldText>
                    <SmallLightText>{cht?.email}</SmallLightText>
                  </GridContainer>
                  <SmallLightText></SmallLightText>
                </ChatCard>
              ))}
            </ScrollContainer>
          </>
        ) : (
          <>
            <MessageSearchField
              ref={searchRef}
              placeholder="Search Users/Campaign"
              value={searchText}
              onChange={handleSearchChange}
            ></MessageSearchField>

            <ScrollContainer {...scrollProps} maxHeight="100%">
              <ShadowDiv
                style={{
                  boxShadow:
                    scrollTop > 0
                      ? "inset 0 8px 5px -5px rgb(0 0 0 / 0.4)"
                      : "none",
                }}
              ></ShadowDiv>

              <NewChatContainer onClick={() => setNewChat(true)}>
                <GridContainer
                  style={{
                    width: "40px",
                    height: "40px",
                    background: "#586FF5",
                    borderRadius: "50%",
                  }}
                >
                  <AddIcon style={{ color: "#fff" }} />
                </GridContainer>
                <BoldText>Start New Chat</BoldText>
              </NewChatContainer>

              {campaignList?.map((camp) => (
                <ChatCard
                  key={camp.campaignId}
                  selected={selectedRoom === camp.campaignId}
                  onClick={() => handleRoomClick(camp.campaignId, camp.title)}
                >
                  <GridContainer
                    style={{
                      width: "40px",
                      height: "40px",
                      background: "#bbb",
                      borderRadius: "50%",
                    }}
                  >
                    <CampaignIcon style={{ color: "#fff" }} />
                  </GridContainer>
                  <div>
                    <BoldText>{camp.title}</BoldText>
                    <SmallLightText>{camp.description}</SmallLightText>
                  </div>
                </ChatCard>
              ))}

              {usersList?.map((cht, i) => (
                <ChatCard
                  key={cht?.userId}
                  onClick={() =>
                    handleClick(
                      cht?.userId,
                      cht?.email,
                      cht?.firstName,
                      cht?.lastName
                    )
                  }
                  selected={selectedUser?.userName === cht?.email}
                >
                  {getImage(cht?.email) === "" ? (
                    <Avatar />
                  ) : (
                    <ChatAvatar src={getImage(cht?.email)} />
                  )}

                  <GridContainer justify="flex-start" rgap="4px">
                    <BoldText>{cht?.firstName + " " + cht?.lastName}</BoldText>
                    <SmallLightText>{cht?.email}</SmallLightText>
                  </GridContainer>
                  <SmallLightText></SmallLightText>
                </ChatCard>
              ))}
            </ScrollContainer>
          </>
        )}
      </MessageListContainer>
    );
  }
}

export default MessageList;

//function to displayshadow on scroll
function useScrollTop() {
  const [scrollTop, setScrollTop] = useState(0);
  const onScroll = (event) => setScrollTop(event.target.scrollTop);
  return [scrollTop, { onScroll }];
}
