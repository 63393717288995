import { FaAngleLeft, FaTimes } from "react-icons/fa";
import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    transition: all 0.3s ease-in-out;
    background-color: #f9f9f9;
    overflow: hidden;
  }
  *{
    box-sizing: border-box;

  }
`;

//Containers

export const GridContainer = styled.div`
  display: grid;
  align-items: ${(props) => (props.align ? props.align : "center")};
  place-content: ${(props) => (props.place ? props.place : "center")};
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  margin: ${(props) => (props.margin ? props.margin : "0")};
  padding: ${(props) => (props.padding ? props.padding : "0")};

  grid-template-columns: ${(props) => props.columns};
  grid-template-rows: ${(props) => props.rows};
  gap: ${(props) => (props.gap ? props.gap : "1rem")};
  row-gap: ${(props) => (props.rgap ? props.rgap : "1rem")};
`;

export const ResponsiveGrid = styled(GridContainer)`
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr !important;
  }
`;

export const ScrollContainer = styled(GridContainer)`
  place-content: start;
  grid-template-columns: 1fr;
  row-gap: 4px;
  max-height: ${(props) => (props.maxHeight ? props.maxHeight : "300px")};
  overflow: auto;
`;

//Text Styles
export const ErrorText = styled.small`
  font-size: 14px;
  color: red;
  margin: 1rem 0;
  text-align: center;
`;
export const SuccessText = styled.small`
  font-size: 14px;
  color: green;
  margin: 1rem 0;
  text-align: center;
`;

export const BigHeading = styled.h1`
  font-size: 56px;
  color: #222;
  margin: 0;

  @media screen and (max-width: 768px) {
    font-size: 36px;
  }
`;

export const Heading = styled.h1`
  color: #222;
  font-weight: 500;
  text-align: ${(props) => (props.talign ? props.talign : "flex-start")};
  font-size: 28px;
  margin: ${(props) => (props.margin ? props.margin : "0")};

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
`;
export const Heading2 = styled.h2`
  color: #222;
  font-weight: 500;
  text-align: ${(props) => (props.talign ? props.talign : "flex-start")};
  font-size: 20px;
  margin: 0;
  @media screen and (max-width: 768px) {
    font-size: 16px;
    font-weight: 600;
  }
`;
export const BoldText = styled.p`
  color: #222;
  font-weight: 600;

  font-size: 14px;
  margin: 0;
`;
export const LightText = styled.p`
  color: #6c6c6c;
  font-weight: 400;
  font-size: 18px;
  margin: 0;
  text-align: ${(props) => props.talign};
  max-width: ${(props) => props.maxWidth};
`;
export const SmallLightText = styled(LightText)`
  color: #494949;
  font-size: 12px;
`;
export const AnchorText = styled.a`
  color: blue;
  font-weight: 400;
  font-size: 16px;
  text-decoration: none;
  margin: 0;
  text-align: ${(props) => props.talign};

  display: inline;
  &:hover {
    cursor: pointer;
    color: #0000ff99;
  }
`;

//Icons
export const CloseIcon = styled(FaTimes)`
  height: 24px;
  width: 24px;
  color: #222;

  &:hover {
    opacity: 0.8;
    cursor: pointer;
  }
`;

//BUttons
export const LinkButton = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 0.7rem 1rem;
  width: fit-content;
  color: #fff;
  background-color: #586ff5;
  border-radius: 4px;
  border: none;
  margin: 0;
  text-decoration: none;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

export const AvatarImage = styled.img`
  height: 48px;
  width: 48px;
  box-shadow: 0 0 10px 5px #00000022;
  border-radius: 50%;
  object-fit: cover;
`;

export const BackArrow = styled(FaAngleLeft)`
  border-radius: 50%;

  height: 1em;
  width: 1em;
  box-sizing: content-box;
  padding: 8px;
  &:hover {
    background-color: #eee;
    cursor: pointer;
  }
`;
