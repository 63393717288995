import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";

export default function CampaignTimeline({ data }) {
  return (
    <BarChart
      width={800}
      height={260}
      data={data}
      margin={{
        top: 20,
        right: 0,
        left: 0,
        bottom: 5,
      }}
    >
      {/* <CartesianGrid strokeDasharray="" /> */}
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="Days" fill="#8884d8" />
      <Bar dataKey="Creators" fill="#82ca9d" />
    </BarChart>
  );
}
