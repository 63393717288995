import styled from "styled-components";
import { GridContainer } from "../../Global";
//Dashboard
export const DashboardContainer = styled(GridContainer)`
  grid-template-columns: 1fr auto;
  align-items: flex-start;
  padding: 0.7rem;
  gap: 0.7rem;
  height: calc(100vh - 61px);
  overflow: auto;
  place-content: flex-start;
  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

export const CreatorCard = styled.div`
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 0;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  height: 600px;
  width: 300px;
  border-radius: 1rem;
  & p {
    max-width: 300px;
  }
  & > img {
    height: 200px;
    width: 100%;
    object-fit: contain;
  }
`;

export const BrandAnalyticsContainer = styled(GridContainer)`
  grid-template-columns: 3fr 1fr;
  align-items: flex-start;
  place-content: flex-start;

  height: calc(100vh - 93px);
  padding: 1rem;
  overflow: auto;
  place-content: flex-start;
  @media screen and (max-width: 1080px) {
    grid-template-columns: 1fr;
  }
`;

export const ToolTipContainer = styled(GridContainer)`
  grid-template-columns: auto 1fr;
  gap: 1rem;

  & > span {
    height: 24px;
    width: 24px;
  }
  & > p {
    margin: 0;
    color: #494949;
  }
`;

export const AnalyticsCard = styled.div`
  border-radius: 8px;
  /* box-shadow: 0 0 5px 5px #ccc; */
  border: 1px solid #ccc;
  padding: 1rem;
  display: grid;
`;
