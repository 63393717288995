import React, { useEffect, useState } from "react";
import {
  BigHeading,
  CloseIcon,
  GridContainer,
  Heading,
  Heading2,
  LightText,
} from "../../Global";
import {
  BorderedCard,
  DeleteButton,
  ProfileContainer,
  ProfileDashboard,
  UploadButton,
} from "../Influencer/User.elements";
import { BASE_URL } from "../../utils/constants";
import { useSelector } from "react-redux";
import { useDeleteInstagramMutation } from "../../api/endpoints/instagramEndpoints";

import { Avatar, Button, Grid, TextField } from "@mui/material";
import { useDeleteProfilePicMutation } from "../../api/endpoints/authEndpoints";

import NoData from "../../components/ui/NoData";
import { useNavigate } from "react-router-dom";
import {
  useAddFavCreatorMutation,
  useGetFavoriteCreatorsQuery,
} from "../../api/endpoints/exploreEndpoints";
import {
  useAddBrandMutation,
  useGetMyBrandsQuery,
} from "../../api/endpoints/brandOwnerEndpoints";
import ReactModal from "react-modal";
import CustomBtn from "../../components/ui/CustomBtn";
import { toast } from "react-toastify";
import { dummyBrands } from "../../utils/dummyData";
import BrandTile from "./helpers/BrandTile";
import CreatorTile from "./helpers/CreatorTile";
import CampaignCard from "../Influencer/Campaign/CamapignCard";
import {
  useLazyGetCampaignByUserIdQuery,
  useLazyGetCreatorsQuery,
} from "../../api/endpoints/campaignEndpoint";
import ShimmerCard from "../../components/ui/ShimmerCard";

const customStyle = {
  content: {
    minWidth: "220px",
    maxWidth: "500px",
    padding: "2rem",
    height: "min-content",
    margin: "auto",
    borderRadius: "2rem",
  },
  overlay: {
    background: "#00000066",
  },
};

function BrandownerProfile({ padding }) {
  const userData = useSelector((state) => state.auth?.userData);
  const accessToken = useSelector((state) => state.auth?.accessToken);

  const [deleteProfilePic, { isLoading: isDeleteProfilePicLoading }] =
    useDeleteProfilePicMutation();

  const {
    data: favCreators,
    isLoading: isFavCreatorLoading,
    isSuccess: isAllFavCreatorSuccess,
  } = useGetFavoriteCreatorsQuery(userData.userId);

  const [getCreators, creatorsResult] = useLazyGetCreatorsQuery();

  const [getBrandOwnerCampaigns, brandOwnerCampaignsResults] =
    useLazyGetCampaignByUserIdQuery();

  const { data: myBrands, isLoading: isMyBrandsLoading } = useGetMyBrandsQuery(
    userData.userId
  );

  const [addBrand, { isLoading: isAddBrandLoading }] = useAddBrandMutation();

  const [imageURL, setImageURL] = useState("");
  const [addBrandModal, setAddBrandModal] = useState(false);
  const [favorites, setFavorites] = useState([]);

  const [error, setError] = useState({
    field: "",
    message: "",
  });

  //setting favorites on component mount
  useEffect(() => {
    if (isAllFavCreatorSuccess) {
      setFavorites(favCreators?.data.map((obj) => obj.email));
    }
  }, [isAllFavCreatorSuccess]);

  //Unlinking Account

  async function getProfilePic() {
    try {
      const res = await fetch(
        `${BASE_URL}/user/private/profile/pic?email=${userData.email}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (res.status === 200) {
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);
        setImageURL(imageObjectURL);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async function handleImageUpload() {
    try {
      const email = userData.email; // Replace with the user's email
      const url = `${BASE_URL}/user/private/update/profile/pic`;

      const formData = new FormData();
      formData.append("file", uploadFile);
      formData.append("email", email);
      const response = await fetch(url, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        // Request was successful, handle the response here
        getProfilePic();
      } else {
        // Handle error responses here
        console.error("Image upload failed:", response.statusText);
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    }
  }

  async function handleProfilePicDelete() {
    try {
      const res = await deleteProfilePic(userData.email);
      if (res.data) {
        setImageURL("");
      }
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    getProfilePic();
  }, []);

  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);

  const [brandName, setBrandName] = useState("");
  const [brandDesc, setBrandDesc] = useState("");

  const [creators, setCreators] = useState();

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      setUploadFile(e.target.files[0]);
      let reader = new FileReader();
      reader.onload = function (ev) {
        setSelectedFile(ev.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  //redirect to explore brands
  const navigate = useNavigate();
  const exploreBrands = () => navigate("/brandowner/explore/creators");

  //Brands modal
  const openAddBrandModal = () => {
    setAddBrandModal(true);
  };
  const closeAddBrandModal = () => {
    setBrandName("");
    setBrandDesc("");
    setError({ field: "", message: "" });
    setAddBrandModal(false);
  };

  //adding brand
  const handleAddBrandModal = async () => {
    if (brandName === "") {
      callError("brandName", "Please Enter Brand Name");
      return;
    } else if (brandDesc === "") {
      callError("brandDesc", "Please Enter Description");
      return;
    }
    try {
      const res = await addBrand({
        userId: userData.userId,
        name: brandName,
        description: brandDesc,
        active: true,
      });

      if (res.data) {
        closeAddBrandModal();
      }
    } catch (e) {
      console.log();
    }
  };

  function callError(field, message) {
    setError({
      field: field,
      message: message,
    });
    toast.error(message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  useEffect(() => {
    setError({ field: "", message: "" });
  }, [brandDesc, brandName]);

  const [allCampaigns, setAllCampaigns] = useState([]);
  console.log(allCampaigns);
  useEffect(() => {
    async function gboc() {
      try {
        const res = await getBrandOwnerCampaigns(userData.userId);
        if (res.data) {
          setAllCampaigns(res.data.data);
        }
      } catch (e) {
        console.log(e);
      }
    }
    gboc();
  }, [brandOwnerCampaignsResults.isSuccess]);

  useEffect(() => {
    if (creatorsResult.data) {
      setCreators(creatorsResult.data.data.content);
    }
  }, [creatorsResult]);

  useEffect(() => {
    getCreators();
  }, []);

  console.log(favorites);

  if (
    isDeleteProfilePicLoading ||
    isFavCreatorLoading ||
    creatorsResult.isLoading ||
    brandOwnerCampaignsResults.isLoading ||
    isMyBrandsLoading
  ) {
    return <ShimmerCard />;
  }

  return (
    <ProfileDashboard padding={padding}>
      <ReactModal
        isOpen={addBrandModal}
        onRequestClose={closeAddBrandModal}
        style={customStyle}
      >
        <GridContainer columns="1fr auto" margin="0 0 1rem 0">
          <Heading>Add Your Brand</Heading>
          <CloseIcon onClick={closeAddBrandModal} />
        </GridContainer>
        <br></br>
        <GridContainer columns="1fr">
          <TextField
            label="Brand Name"
            value={brandName}
            onChange={(e) => setBrandName(e.target.value)}
            error={error.field === "brandName"}
            helperText={error.field === "brandName" ? error.message : ""}
          />

          <TextField
            multiline
            maxRows={10}
            label="Description"
            value={brandDesc}
            onChange={(e) => setBrandDesc(e.target.value)}
            error={error.field === "brandDesc"}
            helperText={error.field === "brandDesc" ? error.message : ""}
          />
          <Button
            onClick={handleAddBrandModal}
            style={{ background: "#586ff5" }}
            variant="contained"
          >
            Add Brand
          </Button>
        </GridContainer>
      </ReactModal>
      {/* <BigHeading talign="flex-start">My Profile</BigHeading> */}
      <ProfileContainer columns="2fr 3fr" gap="0.7rem">
        <BorderedCard>
          <Heading>Basic Account Details</Heading>
          {imageURL != "" ? (
            <>
              <img
                style={{
                  width: "150px",
                  height: "150px",
                  margin: "auto",
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
                src={imageURL}
                alt="Profile"
              ></img>
              <DeleteButton onClick={handleProfilePicDelete}>
                Delete Photo
              </DeleteButton>
            </>
          ) : (
            <>
              {!selectedFile ? (
                <>
                  <Avatar
                    style={{ width: "150px", height: "150px", margin: "auto" }}
                  />
                  <label for="upload">
                    <UploadButton
                      class="glyphicon glyphicon-folder-open"
                      aria-hidden="true"
                    >
                      Choose file
                    </UploadButton>

                    <input
                      type="file"
                      id="upload"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </label>
                </>
              ) : (
                <>
                  <img
                    src={selectedFile}
                    style={{
                      width: "150px",
                      height: "150px",
                      margin: "auto",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                    alt="Preview Image"
                  ></img>
                  {selectedFile.name}
                  <UploadButton onClick={handleImageUpload}>
                    Upload
                  </UploadButton>
                </>
              )}
            </>
          )}

          <GridContainer columns="1fr" rgap="4px">
            <Heading talign="center">
              {userData.firstName + " " + userData.lastName}
            </Heading>
            <LightText talign="center">{userData.email}</LightText>
            <LightText talign="center">{userData.phoneNumber}</LightText>
          </GridContainer>
          {/* <Heading2>Social Websites Linked</Heading2> */}
        </BorderedCard>
        <BorderedCard>
          <Heading>Your Favorite Content Creators</Heading>
          {favCreators?.data === 0 || !favCreators ? (
            <NoData
              message="You don't have any Favorite Creators"
              btnTitle="Explore Creators"
              btnFunction={exploreBrands}
            />
          ) : (
            <div>
              {favCreators?.data.map((fc) => (
                <CreatorTile
                  setFavorites={setFavorites}
                  favorites={favorites}
                  {...fc}
                />
              ))}
            </div>
          )}
        </BorderedCard>
      </ProfileContainer>
      <ProfileContainer columns="3fr 2fr" gap="0.7rem">
        <BorderedCard>
          {myBrands?.data === 0 || !myBrands ? (
            <NoData
              message=" You have not created any Brand "
              btnTitle="Create Brand"
              btnFunction={openAddBrandModal}
            />
          ) : (
            <GridContainer columns="1fr">
              <GridContainer columns="1fr auto">
                <Heading>Your Brands</Heading>
                <CustomBtn onClick={openAddBrandModal}>Add Brand</CustomBtn>
              </GridContainer>
              {myBrands?.data.map((brand) => (
                <BrandTile {...brand} />
              ))}
            </GridContainer>
          )}
        </BorderedCard>
        <BorderedCard>
          {allCampaigns.length === 0 ? (
            <NoData
              message=" You have not created Campaigns "
              btnTitle="Create Campaign"
              btnFunction={openAddBrandModal}
            />
          ) : (
            <GridContainer columns="1fr">
              <GridContainer columns="1fr auto">
                <Heading>Your Campaigns</Heading>
                <CustomBtn onClick={openAddBrandModal}>
                  Create Campaign
                </CustomBtn>
              </GridContainer>
              {allCampaigns.map((cc) => (
                <CampaignCard creators={creators} campaign={cc} />
              ))}
            </GridContainer>
          )}
        </BorderedCard>
      </ProfileContainer>
    </ProfileDashboard>
  );
}

export default BrandownerProfile;
