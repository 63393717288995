import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { CCategoryContainer } from "../Explore.elements";
import { BigHeading, GridContainer, Heading, LightText } from "../../../Global";
import { useGetCreatorsByCategoryQuery } from "../../../api/endpoints/exploreEndpoints";
import Shimmering from "../../../components/ui/ShimmerCard";
import NoData from "../../../components/ui/NoData";
import { useSelector } from "react-redux";
import BrandCard from "./BrandCard";

function BrandCategoryDetails() {
  const { category } = useParams();
  const navigate = useNavigate();
  const newCat = category.replace(/-/g, " ");
  const role = useSelector((state) => state.auth.roles[0]).name;

  const { data: creatorsByCategory, isLoading: isCreatorsLoading } =
    useGetCreatorsByCategoryQuery(newCat.toLowerCase(), false);

  const backToExplore = () => {
    navigate(`/${role}/explore/creators`);
  };

  //dummy brand details

  const dummyBrands = [
    {
      companyName: "Aquafina",
      description: "",
      categories: ["Luxury Brands", "Fast Fashion Brands", "Electronic Brands"],
    },
  ];

  if (isCreatorsLoading) {
    return <Shimmering />;
  } else {
    return (
      <CCategoryContainer>
        <BigHeading>{category.replace(/-/g, " ")}</BigHeading>
        <GridContainer columns="1fr">
          <LightText>{`${
            creatorsByCategory ? creatorsByCategory.data.userDTO.length : "0"
          } Brands available `}</LightText>
        </GridContainer>

        {creatorsByCategory?.data.userDTO.length === 0 ? (
          <NoData
            message={`There are No Content Creators under ${category.replace(
              /-/g,
              " "
            )}`}
            btnFunction={backToExplore}
            btnTitle="Back to Explore"
          />
        ) : (
          <GridContainer
            align="stretch"
            columns="repeat(auto-fill,minmax(300px,1fr))"
            padding="1rem"
          >
            {creatorsByCategory?.data.userDTO.map((obj) => (
              <BrandCard {...obj} />
            ))}
          </GridContainer>
        )}
      </CCategoryContainer>
    );
  }
}

export default BrandCategoryDetails;
