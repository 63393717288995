import React, {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  memo,
} from "react";
import {
  AttachIcon,
  ChatBodyContainer,
  ChatBodyHeader,
  ChatContent,
  ChatField,
  EllipsisHIcon,
  LocationIcon,
  MyChatBlock,
  OtherChatBlock,
  PhoneIcon,
  PictureIcon,
  VideoIcon,
  ChatAvatar,
  DateHeader,
} from "../Influencer/User.elements";
import {
  BackArrow,
  GridContainer,
  Heading2,
  SmallLightText,
} from "../../Global";
import Avatar from "../Influencer/helpers/Avatar";

import SockJS from "sockjs-client";
import { over } from "stompjs";
import { useSelector } from "react-redux";
import NoData from "../../components/ui/NoData";
import ShimmerCard from "../../components/ui/ShimmerCard";
import { useLazyGetChatMessagesQuery } from "../../api/endpoints/chatEndpoints";
import { format, parseISO, sub } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { ChatLoader } from "../../components/ui/components.elements";
import { styled } from "styled-components";

let stompClient = null;
let typingTimeout;

function ChatBody({ selectedUser, setSelectedUser }) {
  const email = useSelector((state) => state.auth.userData.email);
  const profileImage = useSelector((state) => state.auth.profileImage);

  const [getChatMessages, chatMessagesResults] = useLazyGetChatMessagesQuery();

  const [chatsByDate, setChatsByDate] = useState({});
  const [chatMounted, setChatMounted] = useState(false);

  const chatContentRef = useRef(null);

  const [userData, setUserData] = useState({
    userName: email,
    receiverName: selectedUser.userName,
    connected: false,
    messages: "",
  });
  const messagesEndRef = useRef(null);
  const handleBackClick = () => {
    setSelectedUser(null);
  };

  useEffect(() => {
    if (chatContentRef.current) {
      chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
    }
  }, [userData.connected]);

  useEffect(() => {
    if (messagesEndRef.current && !chatMounted) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
    return () => {
      setChatMounted(true);
    };
  }, []);

  function scrollToBottom() {
    if (messagesEndRef.current && !chatMounted) {
      messagesEndRef.current.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    }
  }

  //establish Websocket connection
  const connect = () => {
    try {
      let Sock = new SockJS(
        "https://api-gateway-dev.pacewisdom.in/user/public/ws"
      );
      stompClient = over(Sock);

      stompClient.connect({}, onConnected, onError);
    } catch (e) {
      console.log(e);
    }
  };
  const onConnected = () => {
    setUserData({ ...userData, connected: true });

    try {
      stompClient.subscribe(
        "/user/" + userData.userName + "/private",
        onPrivateMessage
      );

      userJoin();
      //before connecting set the has more flag as true
      if (hasMore[selectedUser.userName] === undefined) {
        setHasMore((curObj) => {
          return { ...curObj, [selectedUser.userName]: true };
        });
      }
    } catch (e) {
      console.log(e);
    }
  };
  const onError = (err) => {
    console.log(err);
  };

  const userJoin = () => {
    let chatMessage = {
      senderName: userData.userName,
      status: "JOIN",
    };
    stompClient.send("/app/message", {}, JSON.stringify(chatMessage));
  };

  //Get message from socket

  const onPrivateMessage = (payload) => {
    let payloadData = JSON.parse(payload.body);

    switch (payloadData.status) {
      case "MESSAGE":
        appendAndTransformChats(payloadData);
        break;
      case "TYPING":
        // Handle typing notifications
        const { typing } = payloadData;
        if (typing) {
          setIsTyping(true);
        } else {
          setIsTyping(false);
        }
        break;
      default:
        return;
    }
  };

  //send message into socket
  const sendPrivateValue = () => {
    if (userData.messages.length === 0) return;
    if (stompClient) {
      let chatMessage = {
        id: uuidv4(),
        senderName: userData.userName,
        receiverName: selectedUser.userName,
        messages: userData.messages,
        time: new Date().toISOString(),
        status: "MESSAGE",
      };

      stompClient.send("/app/private-message", {}, JSON.stringify(chatMessage));
      appendAndTransformChats(chatMessage);

      setUserData({ ...userData, messages: "" });
    }
  };

  //handle message field value
  const handleMessage = (event) => {
    const { value } = event.target;
    setUserData({ ...userData, messages: value });
  };

  //On enter send message
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      sendPrivateValue();
    }
  };

  //transform chats recived to object by date
  function transformChats(previousChats, newChatsByDate) {
    previousChats.forEach((message) => {
      // Extract the date from the message timestamp
      const messageDate = new Date(message.time).toDateString();

      // If the date doesn't exist in the chatsByDate object, create an array for it
      if (!newChatsByDate[messageDate]) {
        newChatsByDate[messageDate] = [];
      }

      // Add the message to the corresponding date's array
      newChatsByDate[messageDate].push(message);
    });
    setChatsByDate(newChatsByDate);
  }

  //to send and get message into chat by date format
  function appendAndTransformChats(newChat) {
    // Extract the date from the message timestamp
    const messageDate = new Date(newChat.time).toDateString();

    const updatedChatsByDate = { ...chatsByDate };
    // If the date doesn't exist in the chatsByDate object, create an array for it
    if (!updatedChatsByDate[messageDate]) {
      updatedChatsByDate[messageDate] = [];
    }

    //handle socket return same message twice
    if (updatedChatsByDate[messageDate].find((obj) => obj.id === newChat.id)) {
      console.log("repeated chat ignored");
      return;
    }

    // Add the new message to the corresponding date's array
    updatedChatsByDate[messageDate].push(newChat);

    // Set the state with the updated chatsByDate object
    setChatsByDate(updatedChatsByDate);
    scrollToBottom();
  }

  //Typing functionality
  const [isTyping, setIsTyping] = useState(false);

  const handleTyping = () => {
    clearTimeout(typingTimeout);

    typingTimeout = setTimeout(() => {
      sendTypingStopNotification();
    }, 2000);
    sendTypingNotification();
  };

  const sendTypingNotification = () => {
    const notification = {
      typing: true,
      sender: userData.userName,
      receiver: selectedUser.userName,
      status: "TYPING",
    };
    stompClient.send("/app/typing", {}, JSON.stringify(notification));
  };

  const sendTypingStopNotification = () => {
    const notification = {
      typing: false,
      sender: userData.userName,
      receiver: selectedUser.userName,
      status: "TYPING",
    };
    stompClient.send("/app/typing", {}, JSON.stringify(notification));
  };

  useEffect(() => {
    if (selectedUser) {
      connect();
    }
  }, [selectedUser.userName]);

  const [daysOfChats, setDaysofChats] = useState(10);

  //once chats recieved trans form it on component mount and user select
  const [hasMore, setHasMore] = useState({ [selectedUser.userName]: true });

  //populate the has more array on component mount

  const fetchNewChats = async (startDate, freshChat) => {
    if (freshChat) {
      if (!hasMore[selectedUser.userName]) return;
    }

    try {
      // Make an API call to fetch new chat messages
      const response = await getChatMessages({
        senderReceiver: `${email},${selectedUser.userName}`,
        receiverSender: `${selectedUser.userName},${email}`,
        startDate: startDate,
        endDate: format(new Date(), "yyyy-MM-dd"),
      });

      // Reset chatsByDate to an empty object before populating it with new messages
      let newChatsByDate = {};
      //populate the chats by date
      if (response.data) {
        transformChats(response.data.data, newChatsByDate);
        return response;
      }

      //check if new message exists

      let newDates = response.data?.data?.map((obj) =>
        new Date(obj.time).toDateString()
      );
      newDates = [...new Set(newDates)];
      let curDates = Object.keys(newChatsByDate);

      if (curDates.filter((x) => !newDates.includes(x)).length === 0) {
        setHasMore((curObj) => {
          return { ...curObj, [selectedUser.userName]: false };
        });
      } else {
        setHasMore((curObj) => {
          return { ...curObj, [selectedUser.userName]: true };
        });
      }
    } catch (error) {
      console.error("Error fetching new chats:", error);
    }
  };

  useEffect(() => {
    setChatMounted(false);
    fetchNewChats(
      format(sub(new Date(), { days: daysOfChats }), "yyyy-MM-dd"),
      false
    );
  }, [selectedUser.userName, userData.connected]);

  const handleScroll = async (e) => {
    // setScrollTop(e.target.scrollTop);
    const { scrollTop } = e.target;

    if (scrollTop === 0 && !chatMessagesResults.isFetching) {
      setDaysofChats((state) => state + 1);
      // User scrolled to the top; fetch old chats
      const res = await fetchNewChats(
        format(sub(new Date(), { days: daysOfChats }), "yyyy-MM-dd"),
        false
      );

      // Calculate the new scroll position
      if (res.data) {
        // const newScrollTop = scrollHeight - (scrollTop + clientHeight);
        // console.log(newScrollTop);
        // // Set the scroll position
        // e.target.scrollTop = newScrollTop;
      }
    }
  };

  useEffect(() => {
    return () => {
      clearTimeout(typingTimeout);
      sendTypingStopNotification(); // Stop typing when the component unmounts
    };
  }, []);

  if (selectedUser) {
    if (userData.connected) {
      return (
        <ChatBodyContainer isSelected={selectedUser} columns="1fr">
          <ChatBodyHeader isSelected={selectedUser}>
            <span>
              <BackArrow onClick={handleBackClick} />
            </span>

            <Avatar image={selectedUser.image}></Avatar>
            <GridContainer align="flex-start" justify="flex-start" rgap="4px">
              <Heading2>{`${selectedUser?.firstName} ${selectedUser?.lastName}`}</Heading2>
              <SmallLightText>{selectedUser?.userName}</SmallLightText>
            </GridContainer>
            {/* <GridContainer columns="auto auto auto">
              <VideoIcon />
              <PhoneIcon />
              <EllipsisHIcon />
            </GridContainer> */}
          </ChatBodyHeader>

          <ChatContent onScroll={handleScroll} ref={chatContentRef}>
            {chatMessagesResults.isFetching ? (
              <GridContainer columns="1fr" style={{ height: "20px" }}>
                <ChatLoader style={{ margin: "auto" }} />
              </GridContainer>
            ) : (
              ""
            )}

            {Object.keys(chatsByDate).map((obj) => (
              <>
                <DateHeader>{obj}</DateHeader>
                {chatsByDate[obj]?.map((ch) =>
                  ch.senderName === userData.userName ? (
                    <MyChat image={profileImage} {...ch} key={ch.id}></MyChat>
                  ) : (
                    <OtherChat
                      image={selectedUser.image}
                      {...ch}
                      key={ch.id}
                    ></OtherChat>
                  )
                )}
              </>
            ))}

            {isTyping && <div>Typing...</div>}

            <div ref={messagesEndRef}></div>
          </ChatContent>

          <ChatField>
            <input
              onKeyDown={handleKeypress}
              // onKeyUp={handleTyping}
              value={userData.messages}
              onChange={handleMessage}
              placeholder="Type a message..."
            ></input>

            <button onClick={sendPrivateValue}>Send</button>
          </ChatField>
        </ChatBodyContainer>
      );
    } else {
      return <ShimmerCard />;
    }
  } else {
    return <NoData message="Please select any chats" />;
  }
}

export default ChatBody;

const MyChat = memo(({ image, messages, time }) => {
  return (
    <GridContainer columns="1fr">
      <GridContainer
        columns="auto auto auto"
        justify="flex-end"
        align="flex-start"
        gap="8px"
      >
        <SmallLightText
          style={{ height: "100%", alignItems: "end", display: "flex" }}
        >
          {format(parseISO(time), "h:mm aaa")}
        </SmallLightText>
        <MyChatBlock>{messages}</MyChatBlock>
        <ChatAvatar src={image} />
      </GridContainer>
    </GridContainer>
  );
});
const OtherChat = memo(({ image, messages, time }) => {
  return (
    <GridContainer columns="1fr">
      <GridContainer
        columns="auto max-content auto"
        justify="flex-start"
        align="flex-start"
        gap="8px"
      >
        <ChatAvatar src={image} />
        <OtherChatBlock>{messages}</OtherChatBlock>
        {time && (
          <SmallLightText
            style={{ height: "100%", alignItems: "end", display: "flex" }}
          >
            {format(parseISO(time), "h:mm aaa")}
          </SmallLightText>
        )}
      </GridContainer>
    </GridContainer>
  );
});
