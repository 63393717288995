import { apiSlice } from "../apiSlice";

const campaignEndpoints = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getCampaignById: builder.query({
      query: (id) => `/user/private/campaign?campaignId=${id}`,
    }),

    //get campaign I am participating in
    getCreatorCampaigns: builder.query({
      query: (id) =>
        `/user/private/campaign/by/creator/id?page=0&size=2147483647&sort=id&order=DESCENDING&userId=${id}`,
      providesTags: ["CreatorCampaigns"],
    }),

    //get campaign created by user
    getCampaignByUserId: builder.query({
      query: (userId) => `/user/private/campaign/user?userId=${userId}`,
      providesTags: ["Campaigns"],
    }),
    getCreators: builder.query({
      query: () =>
        `/user/private/creators?roleId=5ac49983-a559-4b8b-8e13-ffabede8b26c`,
    }),
    updateCampaign: builder.mutation({
      query: (data) => ({
        url: `/user/private/campaign`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Campaigns", "CreatorCampaigns"],
    }),
    deleteCampaign: builder.mutation({
      query: (id) => ({
        url: `/user/private/deletecampaign?campaignId=${id}`,
        method: "PUT",
      }),
      invalidatesTags: ["Campaigns", "CreatorCampaigns"],
    }),
    addCampaign: builder.mutation({
      query: (data) => ({
        url: `/user/private/campaign`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Campaigns", "CreatorCampaigns"],
    }),
  }),
});

export const {
  useLazyGetCampaignByIdQuery,
  useLazyGetCreatorCampaignsQuery,
  useAddCampaignMutation,
  useUpdateCampaignMutation,
  useDeleteCampaignMutation,
  useLazyGetCreatorsQuery,
  useLazyGetCampaignByUserIdQuery,
  useGetCampaignByUserIdQuery,
} = campaignEndpoints;
