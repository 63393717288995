import React, { useEffect, useState } from "react";

import success from "../../assets/images/success_icon.svg";
import logo from "../../assets/images/logo.png";
import { ErrorText, VerificationStatusCard } from "./components.elements";
import { GridContainer, Heading2, LightText } from "../../Global";
import { useNavigate, useSearchParams } from "react-router-dom";
import ReactModal from "react-modal";
import PasswordField from "./PasswordField";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RedirectButton } from "../../pages/Authentication/Auth.elements";
import { FaArrowRight } from "react-icons/fa";

const customStyle = {
  content: {
    width: "300px",
    padding: "2rem",
    height: "min-content",
    margin: "auto",
    borderRadius: "1rem",
  },
  overlay: {
    background: "#00000066",
  },
};

function PasswordResetRedirect() {
  const navigate = useNavigate();
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [successModal, setSuccessModal] = useState(false);
  const [error, setError] = useState({ field: "", message: "" });
  const [searchParams] = useSearchParams();
  let token = searchParams.get("token");

  const handlePasswordReset = async () => {
    if (newPassword === "") {
      callError("newPassword", "Please Enter the new password.");
      return;
    } else if (confirmNewPassword === "") {
      callError("confirmNewPassword", "Please Enter the confirm new password.");
      return;
    } else if (newPassword !== confirmNewPassword) {
      callError("global", "Paswords don't match");
      return;
    }
    try {
      const response = await fetch(
        "https://api-gateway-dev.pacewisdom.in/user/public/verify/reset",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: token,
            newPassword: newPassword,
            confirmNewPassword: confirmNewPassword,
          }),
        }
      );

      if (response.status === 200) {
        setSuccessModal(true);
      }
      if (response.status === 401) {
        callError(
          "global",
          "Your authorization token is not valid.Please try again   "
        );
        return;
      }
      if (response.status === 400) {
        callError(
          "global",
          "New password must be different from old password."
        );
        return;
      }

      //   if(response.error.status)
    } catch (e) {
      console.log(e);
    }
  };

  function callError(field, message) {
    setError({
      field: field,
      message: message,
    });
    toast.error(message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  const backToLogin = () => {
    navigate("/");
  };
  useEffect(() => {
    setError({ message: "", field: "" });
  }, [newPassword, confirmNewPassword]);
  return (
    <VerificationStatusCard>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
      <ReactModal isOpen={successModal} style={customStyle}>
        <GridContainer columns="1fr">
          <img
            src={success}
            height="100px"
            style={{ margin: "auto" }}
            alt="Success Check mark"
          ></img>
          <Heading2>Password Updated</Heading2>
          <LightText talign="center">
            You can now Login with your new password
          </LightText>
          <RedirectButton onClick={backToLogin}>
            Back to Login <FaArrowRight />
          </RedirectButton>
        </GridContainer>
      </ReactModal>

      <img
        src={logo}
        height="80px"
        style={{ margin: "auto" }}
        alt="Success Check mark"
      ></img>
      <LightText talign="center">Pacescape</LightText>

      <h1 style={{ margin: "0" }}>Please enter your new Password!</h1>
      <GridContainer columns="1fr"></GridContainer>
      <PasswordField
        error={error.field === "newPassword"}
        errorMessage={error.message}
        value={newPassword}
        onChange={(e) => setNewPassword(e.target.value)}
        placeholder="******"
        title="New Password"
      ></PasswordField>
      <PasswordField
        error={error.field === "confirmNewPassword" || error.field === "all"}
        errorMessage={error.message}
        value={confirmNewPassword}
        onChange={(e) => setConfirmNewPassword(e.target.value)}
        placeholder="******"
        title="Confirm New Password"
      ></PasswordField>
      {error.field === "global" && <ErrorText>{error.message}</ErrorText>}

      <RedirectButton
        style={{ marginLeft: "auto", width: "100%" }}
        onClick={handlePasswordReset}
      >
        Submit
      </RedirectButton>
    </VerificationStatusCard>
  );
}

export default PasswordResetRedirect;
