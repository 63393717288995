import { apiSlice } from "../apiSlice";

const chatEndpoints = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllChatUsers: builder.query({
      query: () =>
        "/user/private/fetch/all/users?page=0&size=2147483647&sort=id&order=DESCENDING",
      providesTags: ["ChatUsers"],
    }),

    getSUserByName: builder.query({
      query: (data) =>
        `user/private/fetch/by/user-name/or/first-name?userName=${data.email}&firstName=${data.name}`,
    }),

    getSenderChatList: builder.query({
      query: (email) => `/user/private/get/all/sender?receiverName=${email}`,
      providesTags: ["SenderChatList"],
    }),
    getReceiverChatList: builder.query({
      query: (email) => `/user/private/get/all/receiver?senderName=${email}`,
      providesTags: ["RecieverChatList"],
    }),
    getChatMessages: builder.query({
      query: (data) =>
        `/user/private/get/all/chatMessage?sender=${data.senderReceiver}&receiver=${data.receiverSender}&startDate=${data.startDate}&endDate=${data.endDate}`,
      invalidatesTags: ["SenderChatList", "RecieverChatList"],
    }),
  }),
});

export const {
  useLazyGetSUserByNameQuery,
  useGetAllChatUsersQuery,
  useGetSenderChatListQuery,
  useGetReceiverChatListQuery,
  useLazyGetChatMessagesQuery,
} = chatEndpoints;
