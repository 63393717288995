import React from "react";
import notfound from "../../assets/images/notfound.svg";
import { NotFoundContainer } from "../../components/ui/components.elements";
import { useNavigate } from "react-router-dom";

function NotFound() {
  const navigate = useNavigate();
  return (
    <NotFoundContainer>
      <div>
        <img src={notfound} height="160px" alt="Not found Vector"></img>
        <p>The page you are requesting for doesn't exists.</p>
        <button onClick={() => navigate("/")}>Go to Dashboard</button>
      </div>
    </NotFoundContainer>
  );
}

export default NotFound;
