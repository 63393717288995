import React, { useEffect, useLayoutEffect, useReducer, useState } from "react";
import {
  GridContainer,
  Heading,
  LinkButton,
  ResponsiveGrid,
  SmallLightText,
} from "../../../Global";
import { AddIcon, CampaignContainer, CampaignBody } from "../User.elements";

//Components

import { useLazyGetTopCreatorsQuery } from "../../../api/endpoints/brandOwnerEndpoints";
import { useSelector } from "react-redux";

import {
  useAddCampaignMutation,
  useLazyGetCampaignByUserIdQuery,
  useLazyGetCreatorCampaignsQuery,
  useLazyGetCreatorsQuery,
} from "../../../api/endpoints/campaignEndpoint";

import { ToastContainer, toast } from "react-toastify";
import CampaignForm from "./CampaignForm";
import CampaignList from "./CampaignList";
import NoData from "../../../components/ui/NoData";
import ShimmerCard from "../../../components/ui/ShimmerCard";

function Campaign() {
  const myUserId = useSelector((state) => state.auth.userData.userId);
  const [getTopCreators, creatorData] = useLazyGetTopCreatorsQuery();
  const [addCampaign, addCampignResults] = useAddCampaignMutation();
  const [getCreators, creatorsResult] = useLazyGetCreatorsQuery();
  const [getBrandOwnerCampaigns, brandOwnerCampaignsResults] =
    useLazyGetCampaignByUserIdQuery();

  const [getCreatorCampaigns, creatorCampaignResult] =
    useLazyGetCreatorCampaignsQuery();

  const [error, setError] = useState({ field: "", message: "" });
  const [creators, setCreators] = useState();
  const [allCampaigns, setAllCampaigns] = useState([]);
  const [myCampaigns, setMyCampaigns] = useState([]);

  const isBrandOwner = useSelector((state) =>
    state.auth.roles.map((role) => role.name)
  ).includes("brandowner");
  const campaignCreatorId = useSelector((state) => state.auth.userData.userId);
  const [createCampaignModal, setCreateCampaignModal] = useState(false);

  function callSuccess(field, message) {
    toast.success(message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }
  function callError(field, message) {
    setError({
      field: field,
      message: message,
    });
    toast.error(message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  useLayoutEffect(() => {
    async function gcc() {
      try {
        const res = await getCreatorCampaigns(myUserId);
        if (res.data) {
          setMyCampaigns(res.data.data.content);
        }
      } catch (e) {
        console.log(e);
      }
    }
    async function gboc() {
      try {
        const res = await getBrandOwnerCampaigns(myUserId);
        if (res.data) {
          setAllCampaigns(res.data.data);
        }
      } catch (e) {
        console.log(e);
      }
    }

    gboc();

    gcc();
  }, []);

  useEffect(() => {
    if (creatorsResult.data) {
      setCreators(creatorsResult.data.data.content);
    }
  }, [creatorsResult]);

  const ACTIONS = {
    startDate: "handleStartDate",
    endDate: "handleEndDate",
    title: "handleTitle",
    description: "handleDescription",
    creatorsList: "handleCreatorsList",
    creators: "handlecreators",
    active: "handleActive",
    reset: "handleReset",
  };

  function reducer(state, action) {
    switch (action.type) {
      case ACTIONS.startDate:
        return { ...state, startDate: action.payload };
      case ACTIONS.endDate:
        return { ...state, endDate: action.payload };
      case ACTIONS.title:
        return { ...state, title: action.payload };
      case ACTIONS.description:
        return { ...state, description: action.payload };
      case ACTIONS.creators:
        return { ...state, creators: action.payload };
      case ACTIONS.creatorsList:
        return { ...state, creatorsList: action.payload };

      case ACTIONS.reset:
        return { ...initialState };
      default:
        return;
    }
  }

  const initialState = {
    startDate: "",
    endDate: "",
    title: "",
    campaignCreator: campaignCreatorId,
    description: "",
    creatorsList: [],
    active: true,
  };

  const [formData, dispatch] = useReducer(reducer, initialState);

  useLayoutEffect(() => {
    if (isBrandOwner) {
      getTopCreators();
    }
  }, []);

  function handleCloseModal() {
    setCreateCampaignModal(false);
  }

  useLayoutEffect(() => {
    getCreators();
  }, []);
  function openModal() {
    setCreateCampaignModal(true);
  }

  const [selectedCreator, setSelectedCreator] = useState([]);

  useEffect(() => {
    setError({ error: "", message: "" });
  }, [formData]);

  async function handleAddCampaign() {
    try {
      const res = await addCampaign(formData);
      if (res.data) {
        callSuccess("Success", "Campaign added successfully");
        setSelectedCreator([]);
        dispatch({ type: ACTIONS.reset });
        setCreateCampaignModal(false);
      }

      if (res.error.data.status === 400) {
        callError("CampaignModal", res.error.data.message);
      }
    } catch (e) {
      console.log(e);
    }
  }
  const [showActive, setShowActive] = useState(false);

  if (
    creatorCampaignResult.isLoading ||
    brandOwnerCampaignsResults.isLoading ||
    creatorData.isLoading ||
    creatorsResult.isLoading
  ) {
    return <ShimmerCard />;
  } else {
    return (
      <CampaignContainer>
        <CampaignForm
          formData={formData}
          dispatch={dispatch}
          ACTIONS={ACTIONS}
          closeModal={handleCloseModal}
          submitFunctionLoading={addCampignResults.isLoading}
          submitFunction={handleAddCampaign}
          submitButtonTitle="Add Campaign"
          modalState={createCampaignModal}
          creators={creators}
          selectedCreator={selectedCreator}
          setSelectedCreator={setSelectedCreator}
          error={error}
        />

        <GridContainer columns="1fr auto auto" justify="space-between">
          <Heading talign="flex-start"> Campaigns</Heading>
          <SmallLightText style={{ display: "flex", alignItems: "center" }}>
            <input
              type="checkbox"
              checked={showActive}
              onChange={(e) => setShowActive(e.target.checked)}
            ></input>
            Inactive campaigns
          </SmallLightText>

          <LinkButton onClick={openModal}>
            <AddIcon />
            Create Campaign
          </LinkButton>
        </GridContainer>

        {isBrandOwner ? (
          <CampaignBody>
            {allCampaigns?.length === 0 ? (
              <NoData
                message="You have not started any Campaigns."
                btnTitle="Start Campaign"
                btnFunction={openModal}
              />
            ) : (
              <GridContainer justify="flex-start" columns="1fr">
                <CampaignList
                  creators={creators}
                  campaigns={allCampaigns}
                  showActive={showActive}
                  openCampaignModal={openModal}
                />
              </GridContainer>
            )}
          </CampaignBody>
        ) : (
          <ResponsiveGrid
            columns="1fr 1fr"
            align="flex-start"
            place="flex-start"
          >
            <GridContainer columns="1fr" place="flex-start">
              <Heading talign="flex-start">My Campaigns</Heading>

              <CampaignBody>
                {allCampaigns?.length === 0 ? (
                  <NoData
                    message="You have not started any Campaigns."
                    btnTitle="Start Campaign"
                    btnFunction={openModal}
                  />
                ) : (
                  <GridContainer justify="flex-start" columns="1fr">
                    <CampaignList
                      creators={creators}
                      campaigns={allCampaigns}
                      showActive={showActive}
                      openCampaignModal={openModal}
                    />
                  </GridContainer>
                )}
              </CampaignBody>
            </GridContainer>

            <GridContainer columns="1fr" place="flex-start">
              <Heading talign="flex-start">Participated Campaigns</Heading>

              <CampaignBody>
                {myCampaigns?.length === 0 ? (
                  <NoData
                    message="You have not participated in any Campaigns."
                    btnTitle="Participate"
                    btnFunction={openModal}
                  />
                ) : (
                  <GridContainer justify="flex-start" columns="1fr">
                    <CampaignList
                      creators={creators}
                      campaigns={myCampaigns}
                      showActive={showActive}
                      openCampaignModal={openModal}
                    />
                  </GridContainer>
                )}
              </CampaignBody>
            </GridContainer>
          </ResponsiveGrid>
        )}
      </CampaignContainer>
    );
  }
}

export default Campaign;
