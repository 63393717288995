import React, { useEffect, useState } from "react";
import { LoginButton, LoginForm } from "./Auth.elements";
import TextInput from "../../components/ui/TextInput";
import { AnchorText, GridContainer, Heading2, LightText } from "../../Global";
import { useNavigate } from "react-router-dom";
import PasswordField from "../../components/ui/PasswordField";
import { useLoginMutation } from "../../api/endpoints/authEndpoints";
import { useDispatch } from "react-redux";
import { setCredentials } from "../../reduxSlices/authSlice";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../utils/constants";
import { ErrorText } from "../../components/ui/components.elements";
import ReactModal from "react-modal";
import logo from "../../assets/images/logo.png";

import CustomBtn from "../../components/ui/CustomBtn";

const customStyle = {
  content: {
    width: "400px",
    padding: "2rem",
    height: "min-content",
    margin: "auto",
    borderRadius: "1rem",
  },
  overlay: {
    background: "#00000066",
  },
};
function Login() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [forgotPassword, setForgotPassword] = useState(false);
  const [error, setError] = useState({
    field: "",
    message: "",
  });
  const navigate = useNavigate();

  const [login, { isLoading: isLoginLoading }] = useLoginMutation();

  useEffect(() => {
    setError({ field: "", message: "" });
  }, [password, email]);

  function callError(field, message) {
    setError({
      field: field,
      message: message,
    });
    toast.error(message, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  }

  const handleLogin = async () => {
    if (!email) {
      callError("email", "Please Enter your registered email");

      return;
    } else if (!password) {
      callError("password", "Please enter your password");

      return;
    }
    try {
      const res = await login({
        email: email,
        password: password,
      });

      if (res?.error?.status === 400) {
        callError("global", res.error?.data.message);
        return;
      }
      setIsLoading(true);
      const user = await fetch(
        `${BASE_URL}/user/private/user/basic/info?email=${email}`,
        {
          headers: { Authorization: `Bearer ${res.data?.access_token}` },
        }
      );
      const userData = await user.json();

      const profileImage = await fetch(
        `${BASE_URL}/user/private/profile/pic?email=${email}`,
        {
          headers: {
            Authorization: `Bearer ${res.data?.access_token}`,
          },
        }
      );
      const imageBlob = await profileImage.blob();
      const imageObjectURL = URL.createObjectURL(imageBlob);

      if (userData && imageObjectURL) {
        const ytlink = await fetch(
          `${BASE_URL}/user/private/youtube/linkstatus?userId=${userData.data.userDTO.userId}`,
          {
            headers: { Authorization: `Bearer ${res.data?.access_token}` },
          }
        );
        const ytLinkStatus = await ytlink.json();

        //Get Insta Token
        const instagramTokenReq = await fetch(
          `${BASE_URL}/user/private/instagram/get/user/authorization/status?userId=${userData?.data.userDTO.userId}`,
          {
            headers: { Authorization: `Bearer ${res.data?.access_token}` },
          }
        );
        const instaToken = await instagramTokenReq.json();
        if (res.data) {
          let roles = userData.data.roleDto;
          dispatch(
            setCredentials({
              profileImage: imageObjectURL,
              accessToken: res.data.access_token,
              refreshToken: res.data.refresh_token,
              userData: userData.data.userDTO,
              roles: roles,
              permissionData: userData.data.permissionDTO,
              youtubeLinked: ytLinkStatus,
              instaToken: instaToken.data,
            })
          );
          setIsLoading(false);
          localStorage.setItem("refreshToken", res.data.refresh_token);
          if (roles.map((rl) => rl.name).includes("brandowner")) {
            navigate("/brandowner/dashboard");
          } else {
            navigate("/creator/dashboard");
          }
        }
      }
      //Get Youtube Link status
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      callError("global", "Something went wrong");
    }
  };

  const handleForgotPassword = async () => {
    if (email === "") {
      callError(
        "global",
        "Please Enter your email to get password reset link."
      );
      return;
    }
    const pwReset = await fetch(
      `  ${BASE_URL}/user/public/verify/reset/password?email=${email}`,
      {
        method: "POST",
      }
    );
    await pwReset.json();

    setForgotPassword(true);
  };

  function closeModal() {
    setForgotPassword(false);
  }
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleLogin();
    }
  };
  return (
    <LoginForm onKeyDown={handleKeypress}>
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
        theme="light"
      />
      <ReactModal isOpen={forgotPassword} style={customStyle}>
        <GridContainer>
          <img src={logo} alt="Logo" style={{ margin: "auto" }}></img>
          <Heading2 talign="center">Check Your mail</Heading2>
          <LightText talign="center">
            Password Reset link sent to {email}.
          </LightText>
          <LoginButton onClick={closeModal}>Ok</LoginButton>
        </GridContainer>
      </ReactModal>

      <TextInput
        error={error.field === "email" || error.field === "all"}
        errorMessage={error.message}
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        title="Email"
        placeholder="JohnDoe@gmail.com"
      ></TextInput>
      <PasswordField
        error={error.field === "password" || error.field === "all"}
        errorMessage={error.message}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        placeholder="******"
        title="Password"
      ></PasswordField>

      <AnchorText onClick={handleForgotPassword}>Forgot Password?</AnchorText>

      {error.field === "global" && <ErrorText>{error.message}</ErrorText>}
      <CustomBtn
        size="large"
        onClick={handleLogin}
        loading={isLoginLoading || isLoading}
      >
        Login
      </CustomBtn>

      <LightText talign="center">
        New to Pacescape?&nbsp;
        <AnchorText onClick={() => navigate("/register")}>Register</AnchorText>
      </LightText>
    </LoginForm>
  );
}

export default Login;
