import React, { useState } from "react";
import { GridContainer } from "../../Global";
import {
  ErrorText,
  Input,
  Label,
  EyeInVisible,
  EyeVisible,
} from "./components.elements";
function PasswordField(props) {
  const [visible, setVisible] = useState(false);
  return (
    <GridContainer
      style={{ position: "relative" }}
      justify="flex-start"
      columns="1fr"
      rgap="4px"
    >
      <Label>{props.title} </Label>

      <div style={{ position: "absolute", right: "1.8rem", top: "2.2rem" }}>
        {visible ? (
          <EyeVisible error={props.error} onClick={() => setVisible(false)} />
        ) : (
          <EyeInVisible error={props.error} onClick={() => setVisible(true)} />
        )}
      </div>
      <Input type={visible ? "text" : "password"} {...props}></Input>
      {props.error && <ErrorText>{props.errorMessage}</ErrorText>}
    </GridContainer>
  );
}

export default PasswordField;
