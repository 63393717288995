import React, { useLayoutEffect } from "react";
import { DashboardContainer } from "./Brand.elements";
import { BigHeading, GridContainer, Heading, LinkButton } from "../../Global";
import { useLazyGetTopCreatorsQuery } from "../../api/endpoints/brandOwnerEndpoints";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import { FaEllipsisV } from "react-icons/fa";

import trend from "../../assets/images/trend.png";
import { DesktopOnly, MobileOnly, Table } from "../Influencer/User.elements";
import Activities from "../Influencer/helpers/Activities";
import ShimmerCard from "../../components/ui/ShimmerCard";
import ExploreCard from "../Explore/helpers/ExploreCard";
import { exploreCards } from "../../utils/constants";
import { ExploreCardsContainer } from "../Explore/Explore.elements";

import BrandownerProfile from "./BrandownerProfile";

function BrandDashboard() {
  const [getTopCreators, creatorData] = useLazyGetTopCreatorsQuery();

  useLayoutEffect(() => {
    getTopCreators();
  }, []);
  let barGraphData = [];
  let tableData = [];
  let activityData = [];

  if (creatorData) {
    barGraphData = creatorData.data?.data?.map((cd, i) => ({
      key: cd.userId,
      name: cd.channelTitle,
      Subscribers: cd.subscriberCount,
      Views: cd.views,
    }));
    tableData = creatorData.data?.data?.map((cd, i) => ({
      key: cd.userId,
      name: cd.user.firstName + " " + cd.user.lastName,
      channelName: cd.channelTitle,
      Subscribers: cd.subscriberCount,
      Views: cd.views,
      videoCount: cd.videoCount,
      status: cd.user.isActive,
    }));
    activityData = creatorData.data?.data?.map((cd, i) => ({
      key: cd.userId,
      name: [cd.user.firstName, cd.user.lastName].join(" "),
      image: cd.subscriberCount,
      desc: cd.channelDescription,
      email: cd.user.email,
    }));
  }

  if (creatorData.isLoading) {
    return <ShimmerCard />;
  } else {
    return (
      <DashboardContainer>
        <BrandownerProfile padding="0" />
        <DesktopOnly>
          <GridContainer columns="1fr">
            <Heading>
              Explore, <span style={{ color: "#556BEC" }}>Pacescape</span>
            </Heading>

            <ExploreCardsContainer>
              {exploreCards.map((obj) => (
                <ExploreCard {...obj} />
              ))}
            </ExploreCardsContainer>
          </GridContainer>
        </DesktopOnly>

        {/* <MobileOnly>
          <Example height={250} width={350} data={barGraphData} />
        </MobileOnly>
        <GridContainer rgap="2rem" justify="flex-start" columns="1fr">
          <GridContainer columns="auto 1fr" gap="2rem">
            <img
              src={trend}
              alt="Trending"
              height="30px"
              style={{ margin: "auto" }}
            ></img>
            <h1 style={{ margin: "0", fontWeight: "400" }}>Top Channels</h1>
          </GridContainer>

          <DesktopOnly>
            <GridContainer
              align="flex-start"
              justify="flex-start"
              columns="repeat(auto-fill,minmax(790px,1fr))"
            >
              <GridContainer columns="1fr">
                <Table>
                  <thead>
                    <tr>
                      <th>Channel Name</th>
                      <th>Creator Name</th>
                      <th>Views</th>
                      <th>Subscribers</th>
                      <th> Total Videos</th>
                      <th>Action</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((user, i) => (
                      <tr key={user.key}>
                        <td>{user.name}</td>
                        <td>{user.channelName}</td>
                        <td>{user.Views}</td>
                        <td>{user.Subscribers}</td>
                        <td>{user.videoCount}</td>
                        <td>
                          <LinkButton>
                            <a href={user.customURL}>Visit</a>
                          </LinkButton>
                        </td>
                        <td>
                          <FaEllipsisV />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </GridContainer>
              <Example height={400} width={600} data={barGraphData} />
            </GridContainer>
          </DesktopOnly>
        </GridContainer>
        <Activities data={activityData} /> */}
      </DashboardContainer>
    );
  }
}

const Example = ({ data, height, width }) => {
  return (
    <BarChart
      width={width}
      height={height}
      data={data}
      margin={{
        top: 20,
        right: 30,
        left: 20,
        bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="Subscribers" fill="#E2773D" />
      <Bar dataKey="Views" fill="#3564DD" />
    </BarChart>
  );
};

export default BrandDashboard;
