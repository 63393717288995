import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    refreshToken: null,
    accessToken: null,
    userData: null,
    roles: null,
    permissionData: null,
    instaToken: null,
    instaRefreshToken: null,
  },
  reducers: {
    setCredentials: (state, action) => {
      const {
        refreshToken,
        accessToken,
        userData,
        roles,
        permissionData,
        instaToken,
        youtubeLinked,
        instaRefreshToken,
        profileImage,
      } = action.payload;
      state.refreshToken = refreshToken;
      state.accessToken = accessToken;
      state.userData = userData;
      state.roles = roles;
      state.permissionData = permissionData;
      state.instaToken = instaToken;
      state.youtubeLinked = youtubeLinked;
      state.instaRefreshToken = instaRefreshToken;
      state.profileImage = profileImage;
    },
    logOut: (state, action) => {
      state.refreshToken = null;
      state.accessToken = null;
      state.userData = null;
      state.instaToken = null;
      state.youtubeLinked = null;
      state.roles = null;
    },
  },
});

export const { setCredentials, logOut } = authSlice.actions;

export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.refreshToken;
export const selectCurrentToken = (state) => state.auth.accessToken;
