import { setCredentials } from "../reduxSlices/authSlice";
import jwt from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";

import { BASE_URL } from "../utils/constants";

const lsrt = localStorage.getItem("refreshToken");
const useRefreshToken = () => {
  const dispatch = useDispatch();
  const existingData = useSelector((state) => state.auth);

  const refresh = async () => {
    const requestOptions = {
      method: "POST",
      credentials: "include",
      headers: { Authorization: `Bearer ${lsrt}` },
    };

    //Get new access token
    const res = await fetch(
      `${BASE_URL}/api/v1/auth/refresh-token`,
      requestOptions
    );
    const newTokens = await res.json();
    //get email from accesss token
    const email = jwt(newTokens?.access_token).sub;

    //Get User data using access token
    const user = await fetch(
      `${BASE_URL}/user/private/user/basic/info?email=${email}`,
      {
        headers: { Authorization: `Bearer ${newTokens.access_token}` },
      }
    );
    const userData = await user.json();

    //Get Profile picture
    const profileImage = await fetch(
      `${BASE_URL}/user/private/profile/pic?email=${email}`,
      {
        headers: {
          Authorization: `Bearer ${newTokens.access_token}`,
        },
      }
    );
    const imageBlob = await profileImage.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);

    //Get Insta Token
    if (
      userData.data.roleDto.find((obj) => obj.name === "brandowner") ===
      undefined
    ) {
      const instagramTokenReq = await fetch(
        `${BASE_URL}/user/private/instagram/get/user/authorization/status?userId=${userData?.data.userDTO.userId}`,
        {
          headers: { Authorization: `Bearer ${newTokens.access_token}` },
        }
      );
      var instaToken = await instagramTokenReq.json();

      //Get Youtube Link status
      const ytlink = await fetch(
        `${BASE_URL}/user/private/youtube/linkstatus?userId=${userData?.data.userDTO.userId}`,
        {
          headers: { Authorization: `Bearer ${newTokens.access_token}` },
        }
      );
      var ytLinkStatus = await ytlink.json();
    }
    if (newTokens && userData && imageObjectURL) {
      dispatch(
        setCredentials({
          ...existingData,
          profileImage: imageObjectURL,
          accessToken: newTokens.access_token,
          refreshToken: newTokens.refresh_token,
          userData: userData.data.userDTO,
          roles: userData.data.roleDto,
          permissionData: userData.data.permissionDTO,
          youtubeLinked: ytLinkStatus,
          instaToken: instaToken?.data,
        })
      );
    }
    localStorage.setItem("refreshToken", newTokens?.refresh_token);

    return newTokens?.access_token;
  };
  return refresh;
};

export default useRefreshToken;
