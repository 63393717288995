import React from "react";
import { useNavigate } from "react-router-dom";
import success from "../../assets/images/success_icon.svg";

import { IRWrapper, IRContainer, LoginButton } from "./Auth.elements";
import { FaArrowRight } from "react-icons/fa";

import { SmallLightText } from "../../Global";
import { useSelector } from "react-redux";

function YoutubeRedirect() {
  const navigate = useNavigate();

  return (
    <IRContainer>
      <IRWrapper>
        <img src={success} height="120px" alt="Success Check Mark"></img>
        <h2>Youtube Account Linked Successfully</h2>
        <SmallLightText>
          Your youtube account has been successfully linked with Pacescape
        </SmallLightText>
        <LoginButton onClick={() => navigate(`/creator/dashboard`)}>
          Go to Dashboard <FaArrowRight />
        </LoginButton>
      </IRWrapper>
    </IRContainer>
  );
}

export default YoutubeRedirect;
