import React from "react";
import { GridContainer, Heading2, LinkButton } from "../../Global";
import missingData from "../../assets/images/missing-records.svg";

function NoData({ message, btnFunction, btnTitle }) {
  return (
    <GridContainer style={{ minHeight: "400px", height: "100%" }}>
      <img
        src={missingData}
        width="200px"
        style={{ margin: "auto" }}
        alt="No Data"
      ></img>
      <Heading2>{message}</Heading2>
      {btnTitle && (
        <LinkButton style={{ margin: "auto" }} onClick={btnFunction}>
          {btnTitle}
        </LinkButton>
      )}
    </GridContainer>
  );
}

export default NoData;
