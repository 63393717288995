import React from "react";
import { GridContainer } from "../../../Global";
import CampaignCard from "./CamapignCard";
import LineHeading from "../../../components/ui/LineHeading";
import NoData from "../../../components/ui/NoData";

function CampaignList({ creators, campaigns, showActive, openCampaignModal }) {
  let activeCampaigns = [];
  let inActiveCampaigns = [];
  if (campaigns) {
    activeCampaigns = campaigns.filter((obj) => obj.active === true);
    inActiveCampaigns = campaigns.filter((obj) => obj.active === false);
    
    return (
      <GridContainer columns="1fr">
        {activeCampaigns.length === 0 ? (
          <NoData
            message="You have no active Campaigns"
            btnTitle="Start a Campaign"
            btnFunction={openCampaignModal}
          />
        ) : (
          <GridContainer columns="1fr">
            <LineHeading title="Active Campaigns"></LineHeading>
            {activeCampaigns.map((c) => (
              <CampaignCard
                creators={creators}
                campaign={c}
                key={c.campaignId}
              />
            ))}
          </GridContainer>
        )}

        {showActive && inActiveCampaigns.length !== 0 && (
          <GridContainer columns="1fr">
            <LineHeading title="Inactive Campaigns"></LineHeading>
            {inActiveCampaigns.map((c) => (
              <CampaignCard
                creators={creators}
                campaign={c}
                key={c.campaignId}
              />
            ))}
          </GridContainer>
        )}
      </GridContainer>
    );
  } else {
    return;
  }
}

export default CampaignList;
