import styled, { keyframes } from "styled-components";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import { GridContainer } from "../../Global";
export const Label = styled.label`
  color: #222;
  margin: 0;
  font-weight: 600;
  font-size: 18px;
`;

export const EyeVisible = styled(AiFillEye)`
  color: ${(props) => (props.error ? "red" : "#3564dd")};
  height: 24px;
  width: 24px;
  &:hover {
    cursor: pointer;
    color: #3564dd99;
  }
`;
export const EyeInVisible = styled(AiFillEyeInvisible)`
  color: ${(props) => (props.error ? "red" : "#3564dd")};
  height: 24px;
  width: 24px;
  &:hover {
    cursor: pointer;
    color: #3564dd99;
  }
`;
export const Input = styled((props) => <input {...props}></input>)`
  background-color: #eee;
  outline: none;
  border-radius: 2rem;
  border: ${(props) =>
    props.error ? "1px solid red" : "1px solid transparent"};
  padding: 1.2rem 1.8rem;
  min-width: ${(props) => (props.minwidth ? props.minwidth : "280px")};
  margin: 0;
  transition: all 0.2s ease-in-out;
  font-size: 18px;

  &::placeholder {
    font-size: 18px;
    color: #aaa;
  }
  &:focus {
    border: 1px solid #6c6c6c;
  }
  &:hover {
    cursor: ${(props) => (props.disabled ? "default" : "text")};
    border: ${(props) =>
      props.disabled ? "1px solid transparent" : "1px solid #6c6c6c"};
  }
`;

export const ErrorText = styled.p`
  color: red;
  margin: 0;
  font-size: 12px;
  margin-left: 1rem;
  margin-bottom: 8px;
`;

export const Select = styled.select`
  background-color: #ddd;
  outline: none;
  border-radius: 2rem;
  border: 1px solid transparent;
  border-right: 1.8rem solid transparent;
  padding: 1rem 1.2rem;
  position: relative;
  margin: 0;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  font-size: 18px;
  &::after {
    content: "▼";
    font-size: 1rem;
    top: 6px;
    right: 10px;
    position: absolute;
  }
  & > option {
    padding: 0.7rem 1rem !important;
  }
  &::placeholder {
    font-size: 18px;
  }

  &:hover {
    cursor: pointer;
    box-shadow: 0px 0px 12px -4px rgba(0, 0, 0, 0.9);
  }
`;

export const LogoLoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100svh;
  width: 100svw;
`;

export const VerificationStatusCard = styled(GridContainer)`
  position: absolute;
  row-gap: 1rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  border: 1px solid #ccc;
  width: fit-content;
  & > img {
    margin: auto;
  }
  & > button {
    border: none;
    background-color: #8b9bf8;
    color: white;
    padding: 0.7rem 1rem;
    border-radius: 2rem;
    transition: all 0.2s ease-in-out;
    margin: auto;
    width: fit-content;
    &:hover {
      background-color: #8b9bf8bb;
      cursor: pointer;
    }
  }
`;

export const NotFoundContainer = styled.div`
  position: relative;
  height: 100vh;
  width: 100vw;

  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
  }
  & > div > img {
    margin: auto;
  }
  & > div > p {
    text-align: center;
    color: #494949;
    font-size: 18px;
  }
  & > div > button {
    border: none;
    padding: 0.7rem 1rem;
    border-radius: 2rem;
    background-color: #36344a;
    color: #fff;
    &:hover {
      opacity: 0.9;
      cursor: pointer;
    }
  }
`;

export const rotate = keyframes`
100%{
    rotate:360deg;
  }
`;
export const Loader = styled.div`
  position: absolute;
  right: 1rem;
  height: 32px;
  width: 32px;
  background: transparent;
  border-radius: 50%;
  border: 4px solid #ddd;
  border-top-color: #333;
  animation: ${rotate} 1s infinite;
`;
export const ChatLoader = styled.div`
  height: 32px;
  width: 32px;
  background: transparent;
  border-radius: 50%;
  border: 4px solid #ddd;
  border-top-color: #333;
  animation: ${rotate} 1s infinite;
`;
export const SmallLoader = styled(Loader)`
  height: 18px;
  width: 18px;
  border: 2px solid #ddd;
  right: 4px;
`;
