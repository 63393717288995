import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import success from "../../assets/images/success_icon.svg";

import { IRWrapper, IRContainer, LoginButton } from "./Auth.elements";
import { FaArrowRight } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { setCredentials } from "../../reduxSlices/authSlice";
import { BASE_URL } from "../../utils/constants";
import { SmallLightText } from "../../Global";

function InstagramRedirect() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const existingCredentials = useSelector((state) => state.auth);
  const email = useSelector((state) => state.auth?.userData?.email);
  let code = searchParams.get("code");
  async function saveAccessToken() {
    try {
      const tokenReq = await fetch(
        `${BASE_URL}/user/public/instagram/access_token?code=${code}`,
        {
          method: "POST",
        }
      );
      const token = await tokenReq.json();
      if (token) {
        const refreshTokenReq = await fetch(
          `${BASE_URL}/user/public/instagram/long_lived_token?accessToken=${token.data.access_token}&email=${email}`
        );
        const refreshToken = await refreshTokenReq.json();
        if (refreshToken) {
          dispatch(
            setCredentials({
              ...existingCredentials,
              instaToken: refreshToken.data.access_token,
            })
          );
        } else {
          return;
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {
    saveAccessToken();
  }, []);

  return (
    <IRContainer>
      <IRWrapper>
        <img src={success} height="120px" alt="Success Check Mark"></img>
        <h2>Instagram Account Linked Successfully</h2>
        <SmallLightText>
          Your instagram account has been successfully linked with Pacescape
        </SmallLightText>
        <LoginButton onClick={() => navigate("/creator/accounts")}>
          Go to Dashboard <FaArrowRight />
        </LoginButton>
      </IRWrapper>
    </IRContainer>
  );
}

export default InstagramRedirect;
